import {
  FormControl,
  FormLabel,
  InputGroup,
  FormErrorMessage,
  Icon,
  Image,
  Input,
  InputProps,
  Text,
  Button,
} from '@chakra-ui/react';
import { AiFillCamera } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { FieldError } from 'react-hook-form';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from 'react';
import { IconType } from 'react-icons';
import cms from '../../apis/cms';

interface IProps extends InputProps {
  name: string;
  acceptedFileTypes: string;
  label?: string;
  error?: FieldError;
  isRequired?: boolean;
  icon?: IconType;
  colorIcon?: string;
  bg?: string;
  url?: string;
  onFilesChange?: React.ChangeEventHandler<HTMLInputElement>;
  idFileApi?: string;
}

const Upload: ForwardRefRenderFunction<HTMLInputElement, IProps> = (
  {
    name,
    label,
    url,
    idFileApi,
    acceptedFileTypes,
    error,
    colorIcon = 'gray.300',
    isRequired = false,
    w = 150,
    h = 150,
    icon: UploadIcon = AiFillCamera,
    onChange,
    mb,
    onFilesChange,
    bg = 'gray.100',
    ...rest
  },
  ref,
) => {
  const [preview, setPreview] = useState<string | null>(url || null);
  const [fileData, setFileData] = useState<File | null>(null);

  useEffect(() => {
    setPreview(url || null);
  }, [url]);

  const handlePreview = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) {
      setPreview(null);
      setFileData(null);
      return;
    }
    const previewUrl = URL.createObjectURL(file);
    setFileData(file);
    setPreview(previewUrl);
  };

  return (
    <FormControl h={h} isInvalid={!!error} isRequired={isRequired} mb={mb}>
      <FormLabel w={w} h={h} cursor="pointer" htmlFor={name}>
        {!!label && (
          <Text mb="6px" textAlign="center">
            {label}
          </Text>
        )}
        <InputGroup
          role="group"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="100%"
          h="100%"
          bg={bg}
          borderStyle="dotted"
          borderColor={error ? 'red.400' : 'gray.300'}
          borderRadius="6px"
          borderWidth={4}
          _hover={{
            borderColor: 'brand.500',
            '.Trash': { display: 'flex !important' },
          }}
        >
          {preview ? (
            <>
              {!fileData || fileData.type.includes('image') ? (
                <>
                  <Image src={preview} alt="preview" w="100%" h="100%" />
                  <Button
                    position="absolute"
                    className="Trash"
                    display="none"
                    transition="all 0.3s ease"
                    marginTop="-60%"
                    marginLeft="95%"
                    background="transparent"
                    _hover={{ bg: 'trasnparent' }}
                    onClick={async () => {
                      if (
                        window.confirm('Deseja realmente excluir essa imagem?')
                      ) {
                        if (idFileApi)
                          await cms.rest.delete<any>(`/upload/${idFileApi}`);

                        setFileData(null);
                        setPreview(null);
                      }
                    }}
                  >
                    <Icon as={FaTrash} w={5} h={5} color="red" />
                  </Button>
                </>
              ) : (
                <Text>{fileData?.name}</Text>
              )}
            </>
          ) : (
            <Icon
              as={UploadIcon}
              w={30}
              h={30}
              color={colorIcon}
              _groupHover={{
                color: 'brand.500',
              }}
            />
          )}
          <Input
            id={name}
            name={name}
            ref={ref}
            type="file"
            accept={acceptedFileTypes}
            {...rest}
            style={{ display: 'none' }}
            onChange={(...args) => {
              handlePreview(...args);
              if (onFilesChange) {
                onFilesChange(...args);
              }
              if (onChange) {
                onChange(...args);
              }
            }}
          />
        </InputGroup>
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormLabel>
    </FormControl>
  );
};

export default forwardRef(Upload);
