/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  VStack,
  Box,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../../components/Form/Input';
import cms from '../../../apis/cms';
import { MenuItemType } from '../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  subLpItemEdit?: string;
  lpItemToEdit?: MenuItemType;
  onCreated?: (menuItem: any) => void;
  onEdited?: (menuItem: any) => void;
}

const createFormData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});
const LpItemModal: React.FC<Props> = ({
  isOpen,
  onClose,
  subLpItemEdit,
  lpItemToEdit,
  onCreated,
  onEdited,
}) => {
  const toast = useToast();
  const { formState, handleSubmit, register, control, setValue, reset } =
    useForm({
      resolver: yupResolver(createFormData),
    });
  const { errors } = formState;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!lpItemToEdit) return;
    setValue('name', lpItemToEdit.name);
    setValue('reference_value', lpItemToEdit?.reference_value);
  }, [lpItemToEdit, setValue]);
  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      setLoading(true);
      let menuItemReturn;
      const newLp = {
        name: data.name,
        text: data.Text,
        lp_id: Number(subLpItemEdit),
      };

      if (lpItemToEdit) {
        if (lpItemToEdit.isChildren) {
          if (onEdited) {
            onEdited(menuItemReturn);
          }
          await cms.rest.put(`lp-items/${lpItemToEdit.id}`, {
            name: newLp.name,
            text: newLp.text,
          });
        } else {
          await cms.rest.put(`lp/${lpItemToEdit.id}`, newLp);
        }
      } else {
        await cms.rest.post('/lp-items', newLp);
      }

      if (!lpItemToEdit) {
        toast({
          title: 'Sucesso',
          description: 'Item do menu criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
        if (onCreated) {
          onCreated(menuItemReturn);
        }
      } else {
        if (onEdited) {
          onEdited(menuItemReturn);
        }
        toast({
          title: 'Sucesso',
          description: 'Item do menu editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
      onClose();
      reset();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      onClose={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      scrollBehavior="inside"
      isCentered
      size="x1."
    >
      <ModalOverlay color="#FFFFFF" />
      <ModalContent h="auto" bg="#FBFBFB" width="45rem">
        <ModalHeader fontFamily="Poppins" color="#469BFF">
          Novo Item de LP
        </ModalHeader>
        <ModalCloseButton />
        <Text fontFamily="Poppins" color="#469BFF" marginLeft="25px">
          Configurações
        </Text>
        <ModalBody>
          <VStack spacing={8}>
            <Box>
              <Input
                isRequired
                bgColor="gray.200"
                placeholder="Nome"
                marginLeft="-20px"
                error={errors.name}
                {...register('name')}
                backgroundColor="#FFFFFF"
                _placeholder={{ color: '#000000' }}
              />
              <Textarea
                isRequired
                bgColor="gray.200"
                placeholder="Texto"
                marginLeft="-20px"
                error={errors.name}
                {...register('Text')}
                backgroundColor="#FFFFFF"
                marginTop="6px"
                _placeholder={{ color: '#000000' }}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="brand"
              isLoading={loading}
              fontFamily="Poppins"
              lineHeight="21px"
              fontSize="14px"
              font-weight="300"
              onClick={handleSubmit(handleForm)}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default LpItemModal;
