import React, { FC } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import Configuration from './pages/configuration/Index';

const Config: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Configuration} />
    </Switch>
  );
};

export default Config;
