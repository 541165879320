import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import cms from './apis/cms';
import { AuthProvider } from './hooks/useAuth';
import Routes from './Routes';
import light from './styles/themes/light';

const App: React.FC = () => {
  return (
    <ApolloProvider client={cms.graphql}>
      <AuthProvider>
        <ChakraProvider theme={light}>
          <Routes />
        </ChakraProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
