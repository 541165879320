import React from 'react';
import { IconButton, Image, useTheme } from '@chakra-ui/react';
import { IoAddCircleOutline } from 'react-icons/io5';

interface Props {
  banner: any;
  index: number;
  disabled: boolean;
  onClick: (banner: any) => void;
}

const Banner: React.FC<Props> = ({ banner, onClick, disabled, index }) => {
  const theme = useTheme();

  return (
    <IconButton
      w="100%"
      h="100%"
      aria-label="add-banner"
      borderStyle="dotted"
      borderRadius="6px"
      borderWidth={4}
      disabled={disabled}
      icon={
        banner?.file?.url || banner?.reference_value ? (
          <Image src={banner?.file?.url || '/placeholder_product.jpg'} />
        ) : (
          <IoAddCircleOutline size={25} color={theme.colors.gray['300']} />
        )
      }
      onClick={() => onClick(banner ? { ...banner, position: index } : null)}
    />
  );
};

export default Banner;
