import React, { useEffect, useState } from 'react';
import { useToast, SimpleGrid } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Select, { ISelectValue } from '../../../components/Form/Select';
import auth from '../../../apis/auth';
import { featuresNamesTranslate } from '../../../utils/translates';

interface Props {
  options: Array<any>;
  title: string;
  selectValue: string;
}

const FeatureSelect: React.FC<Props> = ({ options, title, selectValue }) => {
  const toast = useToast();
  const { control } = useForm();
  const [valueSelected, setValueSelected] = useState({});

  useEffect(() => {
    setValueSelected(
      options
        .filter(option => option.id === selectValue)
        .map(affiliate => {
          return { label: affiliate.slug, value: affiliate.id };
        }),
    );
  }, [options, selectValue]);

  const handleSubmit = async (event: ISelectValue) => {
    try {
      const titleMessage =
        featuresNamesTranslate.find(
          (featureName: any) => featureName.slug.toUpperCase() === title,
        )?.translate || title;

      if (
        window.confirm(
          `Deseja atualizar o campo ${titleMessage} para ${event.label}?`,
        )
      ) {
        const dataRequest: any = options.filter(
          option => option.id === event.value,
        )[0];

        const { data }: any = await auth.post(`features/`, {
          name: title,
          affiliate_id: dataRequest.id,
          slug: title.toLowerCase(),
        });
        setValueSelected(
          options
            .filter(option => option.id === data?.affiliate_id)
            .map(affiliate => {
              return { label: affiliate.slug, value: affiliate.id };
            }),
        );

        toast({
          title: 'Sucesso!',
          description: 'Feature salva com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };

  return (
    <>
      <SimpleGrid minChildWidth="200px" spacing="8" w="100%" mt={3}>
        <Select
          backgroundColorIntensity="200"
          onChange={(event: any) => handleSubmit(event)}
          name="feature"
          placeholder={
            featuresNamesTranslate.find(
              (featureName: any) => featureName.slug.toUpperCase() === title,
            )?.translate || title
          }
          label=""
          options={options.map(affiliate => {
            return { label: affiliate.slug, value: affiliate.id };
          })}
          value={valueSelected}
          control={control}
        />
      </SimpleGrid>
    </>
  );
};

export default FeatureSelect;
