import React from 'react';
import {
  Flex,
  Box,
  Image,
  Text,
  HStack,
  Grid,
  GridItem,
  useTheme,
} from '@chakra-ui/react';
import { IoAddCircleOutline } from 'react-icons/io5';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const CheckeredBannerCarousel: React.FC<Props> = ({ data, phoneWidth }) => {
  const theme = useTheme();

  const grid = [
    {
      id: 0,
      colStart: 1,
      rowStart: 1,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 1,
      colStart: 2,
      rowStart: 1,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 2,
      colStart: 1,
      rowStart: 2,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 3,
      colStart: 2,
      rowStart: 2,
      colSpan: 1,
      rowSpan: 1,
    },
  ];

  return (
    <Box
      w={phoneWidth}
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      borderRadius="6px"
      transition="150ms ease"
      position="relative"
      p={4}
    >
      {data?.name ? (
        <>
          <Flex align="center">
            <Text fontWeight="bold" fontSize="lg" mr={2}>
              {data?.name}
            </Text>
            <Tooltips data={data} />
          </Flex>
          {!!data?.description && (
            <Text fontSize="xs" color="gray.400">
              {data.description}
            </Text>
          )}
        </>
      ) : (
        <Flex position="absolute" left={2} top={1}>
          <Tooltips data={data} />
        </Flex>
      )}

      <HStack spacing={0} overflow="auto" mt={1}>
        <Grid
          templateRows="repeat(2, 140px)"
          templateColumns="repeat(9, 140px)"
          justifyContent="center"
          mt={5}
          gap={1}
        >
          {grid.map((gridItem, index) => (
            <GridItem
              key={gridItem.id}
              colStart={gridItem.colStart}
              colSpan={gridItem.colSpan}
              rowSpan={gridItem.rowSpan}
              rowStart={gridItem.rowStart}
            >
              {data?.items[index]?.file?.url ||
              data?.items[index]?.reference_value ? (
                <Image
                  w="100%"
                  h="100%"
                  borderRadius="6px"
                  src={
                    data?.items[index]?.file?.url || '/placeholder_product.jpg'
                  }
                />
              ) : (
                <Flex
                  w="100%"
                  h="100%"
                  aria-label="add-banner"
                  borderStyle="dotted"
                  borderRadius="6px"
                  borderWidth={4}
                  align="center"
                  justify="center"
                >
                  <IoAddCircleOutline
                    size={25}
                    color={theme.colors.gray['300']}
                  />
                </Flex>
              )}
            </GridItem>
          ))}
        </Grid>
      </HStack>
    </Box>
  );
};

export default CheckeredBannerCarousel;
