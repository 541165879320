import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Skeleton,
  Image,
  Box,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { AiFillInfoCircle } from 'react-icons/ai';

interface Props {
  display: any;
}

const DisplayCard: React.FC<Props> = ({ display }) => {
  const [loading, setLoading] = useState(true);
  const [img, setImg] = useState<any | null>(null);

  useEffect(() => {
    setLoading(true);
    import(`../../../../assets/${display.slug}.png`)
      .then(image => {
        setImg(image);
        setLoading(false);
      })
      .catch(() => {
        // prevent from crashing
      });
  }, [display]);

  return (
    <Flex
      bg="white"
      w="40%"
      p={4}
      border="2px solid #F0F0F0"
      borderRadius="6px"
      flexDir="column"
      ml="10px"
      mt="10px"
    >
      <Flex mb={4} align="center">
        <Text color="gray.500" fontWeight="bold" fontSize="sm" mr={2}>
          {display?.name}
        </Text>

        <Tooltip label={display?.description}>
          <span>
            <Icon w={5} h={5} as={AiFillInfoCircle} color="brand.500" />
          </span>
        </Tooltip>
      </Flex>
      {loading ? (
        <Skeleton w="100%" h="150px" alignSelf="center" />
      ) : (
        <Droppable isDropDisabled droppableId={display?.id}>
          {droppableProvided => (
            <Box
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              <Draggable draggableId={display?.id} index={0}>
                {provided => (
                  <Image
                    borderRadius="6px"
                    draggable
                    src={img?.default}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  />
                )}
              </Draggable>

              {droppableProvided.placeholder}
            </Box>
          )}
        </Droppable>
      )}
    </Flex>
  );
};

export default DisplayCard;
