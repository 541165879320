// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function sanitizeString(data: any): any {
  const newObj = { ...data };

  Object.keys(newObj).forEach(key => {
    const value = newObj[key];

    if (typeof value !== 'string') return;

    if (value.length > 0) return;

    newObj[key] = null;
  });

  return newObj;
}
