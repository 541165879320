import { useCallback, useState } from 'react';

interface Return {
  debounce: (fn: (...args: any) => any, delay: number) => void;
  reset: () => void;
}

const useDebounce = (): Return => {
  const [time, setTime] = useState<null | NodeJS.Timeout>(null);

  const debounce = useCallback(
    (fn: (...args: any) => any, delay: number) => {
      if (time) {
        clearTimeout(time);
      }
      const handler = setTimeout(() => {
        fn();
      }, delay);

      setTime(handler);
    },
    [time],
  );

  const reset = useCallback(() => {
    if (time) {
      clearTimeout(time);
    }
  }, [time]);

  return { debounce, reset };
};

export default useDebounce;
