import { Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Datepicker from '../../../../components/Form/Datepicker';

interface Props extends FlexProps {
  data: any;
}

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Chart: React.FC<Props> = ({ ...rest }) => {
  const { control } = useForm();

  return (
    <Flex flexDir="column" align="center" justify="center" {...rest}>
      <Flex align="center" mb={8}>
        <Text mr={4}>Faturado no período de</Text>
        <Datepicker
          w="200px"
          dateFormat="MMMM d, yyyy"
          name="start"
          control={control}
          mr={4}
        />
        <Text>a</Text>
        <Datepicker
          ml={4}
          w="200px"
          dateFormat="MMMM d, yyyy"
          name="end"
          control={control}
        />
      </Flex>
      <ResponsiveContainer aspect={2.5} width="100%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="name" tick={{ fontSize: 12 }} />
          <YAxis tick={{ fontSize: 12 }} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#8884d8"
            activeDot={{ r: 6 }}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="uv"
            stroke="#82ca9d"
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Flex>
  );
};

export default Chart;
