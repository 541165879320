import {
  Flex,
  Button,
  IconButton,
  useToast,
  Link,
  Image,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, Link as ReachLink } from 'react-router-dom';
import Input from '../../../components/Form/Input';
import useAuth from '../../../hooks/useAuth';
import cms from '../../../apis/cms';

type FormData = {
  email: string;
  password: string;
};

const formSchema = yup.object().shape({
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  password: yup.string().required('Senha obrigatório'),
});

const Login: React.FC = () => {
  const toast = useToast();
  const { signIn } = useAuth();
  const history = useHistory();

  const [logo, setLogo] = useState('');

  useEffect(() => {
    async function getLogo() {
      try {
        const { data } = await cms.rest.get<any>('/config-images/image_login', {
          params: {
            withFile: true,
          },
        });
        setLogo(data?.file.url);
      } catch (err: any) {
        toast({
          title: 'Opss!!',
          description:
            err?.response?.data?.message ||
            'Ocorreu um erro ao carregas a telas, tente novamente mais tarde!',
          status: 'error',
          position: 'top-right',
        });
      }
    }
    getLogo();
  }, [toast]);

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { errors } = formState;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleForm: SubmitHandler<FormData> = async data => {
    const { email, password } = data;
    try {
      setLoading(true);
      await signIn({ email, password });
      history.push('/users');
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro com seu login',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Flex w="100vw" h="100vh">
      <Flex flex={1} h="100vh" bg="gray.600">
        {logo ? <Image src={logo} /> : ''}
      </Flex>
      <Flex
        as="form"
        h="100vh"
        w={500}
        justify="center"
        p={42}
        flexDir="column"
        onSubmit={handleSubmit(handleForm)}
      >
        <Input
          label="E-mail"
          mb={4}
          error={errors.email}
          {...register('email')}
        />
        <Input
          label="Senha"
          type={show ? 'text' : 'password'}
          error={errors.password}
          InputRightElement={
            <IconButton
              variant="ghost"
              colorScheme="transparent"
              aria-label="Esconder ou mostrar a senha"
              icon={show ? <HiEye /> : <HiEyeOff />}
              onClick={() => setShow(state => !state)}
            />
          }
          {...register('password')}
        />
        <Button
          type="submit"
          mt={10}
          colorScheme="brand"
          size="md"
          isLoading={loading}
        >
          Logar
        </Button>
        <Link
          textAlign="center"
          as={ReachLink}
          mt={5}
          to="/auth/forgot-password"
        >
          Esqueceu a senha?
        </Link>
      </Flex>
    </Flex>
  );
};

export default Login;
