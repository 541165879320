/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import {
  Flex,
  Heading,
  useToast,
  Accordion,
  useDisclosure,
  VStack,
  Skeleton,
  ExpandedIndex,
  Button,
  Text,
  AccordionItem,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Input,
  HStack,
} from '@chakra-ui/react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import cms from '../../../apis/cms';
import UpdateStopwatchModal from './components/updateModal';

interface StopwatchItemTypes {
  name: string;
  id: number;
  slug?: string;
  active: boolean;
  end_date: Date;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Stopwatch: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [activeAccordion, setActiveAccordion] = useState<ExpandedIndex>(-1);
  const [stopwatchToEdit, setStopwatchToEdit] = useState<StopwatchItemTypes>();
  const [stopwatchItems, setStopwatchItems] = useState<StopwatchItemTypes[]>(
    [],
  );

  const createFormData = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
  });

  const toast = useToast();
  const { handleSubmit, register } = useForm({
    resolver: yupResolver(createFormData),
  });

  async function getStopwatch() {
    const { data } = await cms.rest.get('/stopwatch');
    setStopwatchItems(data as StopwatchItemTypes[]);
  }

  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      const newStopwatch: Omit<StopwatchItemTypes, 'id'> = {
        name: data.name,
        slug: data.event,
        active: false,
        end_date: new Date(data.date),
      };
      const stopwatch = await cms.rest.post('/stopwatch', newStopwatch);
      toast({
        title: 'Sucesso',
        description: 'Cronômetro criado com sucesso',
        status: 'success',
        position: 'top-right',
      });
      setStopwatchItems([
        ...stopwatchItems,
        stopwatch.data as StopwatchItemTypes,
      ]);
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };

  async function handleDelete(id: number) {
    await cms.rest.delete(`/stopwatch/${id}`);
    const updatedList = stopwatchItems.filter(item => item.id !== id);
    setStopwatchItems(updatedList);
  }

  async function handleUpdateActive(stopWatch: StopwatchItemTypes) {
    cms.rest.put(`/stopwatch/${stopWatch.id}`, {
      active: !stopWatch.active,
    });

    const updatedStopwatch = stopwatchItems.map(item => {
      if (item.id === stopWatch.id) {
        item.active = !item.active;
      }

      return item;
    });

    setStopwatchItems(updatedStopwatch);
  }

  useEffect(() => {
    getStopwatch();
  }, []);
  return (
    <Flex
      flexDir="column"
      w="100%"
      p={8}
      overflow="auto"
      bg="#F9F9F9"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      <Heading mb="48px" color="#469BFF" fontFamily="Poppins">
        Cronômetros
      </Heading>
      {/* Removi o onDragEnd pois não é possível alterar a ordem dos itens e refletir no app ainda */}
      <DragDropContext onDragEnd={() => console.log('end')}>
        <Flex
          position="relative"
          flexDir="column"
          p={8}
          borderRadius={6}
          width="48rem"
          bg="#FFFFFF"
          boxShadow="lg"
        >
          <Droppable droppableId="root1" type="root1">
            {provided => (
              <Accordion
                allowToggle
                index={activeAccordion}
                onChange={setActiveAccordion}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {stopwatchItems.map((menuItem: StopwatchItemTypes) => (
                  <AccordionItem
                    p="7px"
                    borderColor="gray.300"
                    borderRadius="9px"
                    border="2px solid #F9F9F9"
                    ref={provided.innerRef}
                    //   {...provided.draggableProps}
                    //   {...provided.dragHandleProps}
                  >
                    <Flex>
                      <Text fontSize="30px" color="#469BFF" fontFamily="body">
                        Tela:
                      </Text>
                      <Text
                        fontSize="20px"
                        fontFamily="Poppins"
                        mt="7px"
                        ml="5px"
                      >
                        {menuItem.name}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text fontSize="25px" color="#469BFF" fontFamily="body">
                        Nome:
                      </Text>
                      <Text
                        fontSize="20px"
                        fontFamily="Poppins"
                        mt="7px"
                        ml="5px"
                      >
                        {menuItem.slug}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text fontSize="25px" color="#469BFF" fontFamily="body">
                        Data:
                      </Text>
                      <Text
                        fontSize="20px"
                        fontFamily="Poppins"
                        mt="7px"
                        ml="5px"
                      >
                        {new Date(menuItem.end_date).toLocaleDateString()}
                      </Text>
                      {menuItem.active ? (
                        <Button
                          ml="50px"
                          colorScheme="green"
                          onClick={() => handleUpdateActive(menuItem)}
                        >
                          Ativo
                        </Button>
                      ) : (
                        <Button
                          ml="50px"
                          colorScheme="red"
                          onClick={() => handleUpdateActive(menuItem)}
                        >
                          Inativo
                        </Button>
                      )}
                    </Flex>
                    <Button
                      ml="4"
                      aria-label="create_menu_item"
                      rounded="md"
                      textColor="#FFFFFF"
                      fontFamily="Poppins"
                      marginTop="4px"
                      bg="#469BFF"
                      width="78px"
                      borderRadius="0.375rem"
                      onClick={() => {
                        onOpen2();
                        setStopwatchToEdit(menuItem);
                      }}
                    >
                      Atualizar
                    </Button>
                    <Button
                      margin="0 5px"
                      aria-label="delete-menu-item"
                      rounded="md"
                      size="md"
                      bg="#FFEBF0"
                      textColor="red"
                      onClick={async () => {
                        await handleDelete(menuItem.id);
                      }}
                    >
                      Excluir
                    </Button>
                    <UpdateStopwatchModal
                      isOpen={isOpen2}
                      onClose={onClose2}
                      menuItem={stopwatchToEdit as StopwatchItemTypes}
                      getStopwatch={getStopwatch}
                    />
                  </AccordionItem>
                ))}
              </Accordion>
            )}
          </Droppable>
        </Flex>
      </DragDropContext>
      <Button
        ml="4"
        aria-label="create_menu_item"
        rounded="md"
        textColor="#FFFFFF"
        fontFamily="Poppins"
        marginTop="4px"
        bg="#469BFF"
        width="178px"
        borderRadius="0.375rem"
        onClick={onOpen}
      >
        Novo cronômetro
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
        size="x1."
      >
        <ModalOverlay />
        <ModalContent h="auto" bg="#FBFBFB" width="45rem">
          <ModalHeader fontFamily="Poppins" color="#469BFF">
            Novo Cronômetro
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={8}>
              <Box>
                <Input
                  isRequired
                  bgColor="gray.200"
                  placeholder="Nome da Tela"
                  marginLeft="-20px"
                  {...register('name')}
                  backgroundColor="#FFFFFF"
                  _placeholder={{ color: '#000000' }}
                />
                <Input
                  isRequired
                  bgColor="gray.200"
                  placeholder="Evento"
                  marginLeft="-20px"
                  {...register('event')}
                  backgroundColor="#FFFFFF"
                  _placeholder={{ color: '#000000' }}
                />
                <Input
                  isRequired
                  bgColor="gray.200"
                  placeholder="Descrição"
                  type="datetime-local"
                  marginLeft="-20px"
                  {...register('date')}
                  backgroundColor="#FFFFFF"
                  marginTop="6px"
                  _placeholder={{ color: '#000000' }}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <VStack spacing={4}>
              <Button
                colorScheme="brand"
                fontFamily="Poppins"
                lineHeight="21px"
                fontSize="14px"
                font-weight="300"
                onClick={handleSubmit(handleForm)}
              >
                Salvar
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Stopwatch;
