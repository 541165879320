import React from 'react';
import { Flex, VStack, HStack, Box, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBr from 'date-fns/locale/pt-BR';
import { Draggable } from 'react-beautiful-dnd';

interface Props {
  item: any;
  index: number;
  onClick: (item: any) => void;
}

const Item: React.FC<Props> = ({ item, onClick, index }) => {
  return (
    <Draggable draggableId={item?.id} index={index}>
      {provided => (
        <Flex
          draggable
          key={item?.id}
          w="100%"
          h="150px"
          bg="gray.200"
          borderRadius="6px"
          p={4}
          boxShadow="lg"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => {
            if (onClick) onClick(item);
          }}
        >
          <VStack spacing={6} alignItems="flex-start">
            <HStack spacing={8}>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Nome:
                </Text>
                <Text color="gray.700">{item?.name}</Text>
              </Box>

              <Box>
                <Text fontSize="xs" color="gray.500">
                  Redirecionamento:
                </Text>
                <Text color="gray.700">{item?.redirect_type}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Valor do Redirecionamento:
                </Text>
                <Text color="gray.700">{item?.redirect_value}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Facets:
                </Text>
                <Text color="gray.700">{item?.facets}</Text>
              </Box>
            </HStack>
            <HStack spacing={6}>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Início:
                </Text>
                <Text color="gray.700">
                  {format(
                    new Date(item?.schedule_start),
                    "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                    {
                      locale: ptBr,
                    },
                  )}
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Final:
                </Text>
                <Text color="gray.700">
                  {item?.schedule_end
                    ? format(
                        new Date(item?.schedule_end),
                        "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                        {
                          locale: ptBr,
                        },
                      )
                    : '-'}
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Flex>
      )}
    </Draggable>
  );
};

export default Item;
