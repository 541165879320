/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';
import {
  Flex,
  useToast,
  VStack,
  Button,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  SimpleGrid,
  ModalBody,
  Text,
  ModalFooter,
  Box,
  Input,
  ExpandedIndex,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import Select from '../../../../components/Form/Select';
import cms from '../../../../apis/cms';
import redirectTypes from '../../../../utils/redirectTypes';

interface EditableButtonItemTypes {
  name: string;
  id: number;
  slug?: string;
  active: boolean;
  redirect_type?: string;
  redirect_value?: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  menuItem: EditableButtonItemTypes;
  getEditableButton: () => Promise<void>;
}

const UpdateEditableButtonModal: React.FC<Props> = ({
  isOpen,
  onClose,
  menuItem,
  getEditableButton,
}) => {
  const [typeRedirect, setTypeRedirect] = useState('');
  const createFormData2 = yup.object().shape({});

  const toast = useToast();
  const { control, handleSubmit, register, reset } = useForm({
    resolver: yupResolver(createFormData2),
  });

  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      const updatedButton = {
        slug: String(data.slug2) !== '' ? data.slug2 : menuItem.slug,
        redirect_value:
          String(data.redirect_value2) !== ''
            ? data.redirect_value2
            : menuItem.redirect_value,
        redirect_type: typeRedirect ?? menuItem.redirect_type,
      };

      await cms.rest.patch(`/editable-button/${menuItem.id}`, updatedButton);
      onClose();
      reset();
      await getEditableButton();

      toast({
        title: 'Sucesso',
        description: 'Texto criado com sucesso',
        status: 'success',
        position: 'top-right',
      });
      onClose();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };

  return (
    <Flex
      flexDir="column"
      w="100%"
      p={8}
      overflow="auto"
      bg="#F9F9F9"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          reset();
        }}
        scrollBehavior="inside"
        isCentered
        size="x1."
      >
        <ModalOverlay />
        <ModalContent h="40rem" bg="#FBFBFB" width="45rem">
          <ModalHeader fontFamily="Poppins" color="#469BFF">
            Atualizar Botão
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={8}>
              <Box>
                <Input
                  bgColor="gray.200"
                  placeholder={menuItem?.slug}
                  marginLeft="-20px"
                  {...register('slug2')}
                  backgroundColor="#FFFFFF"
                  _placeholder={{ color: '#000000' }}
                />

                <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
                  <Select
                    name="redirect_type"
                    placeholder={menuItem?.redirect_type}
                    control={control}
                    label="Redirecionamento"
                    options={redirectTypes}
                    onChange={(e: any) => {
                      setTypeRedirect(e.value);
                    }}
                  />
                  <Box>
                    <Text> Valor de redirecionamento</Text>
                    <Input
                      label="Valor do Redirecionamento"
                      backgroundColor="#FFFFFF"
                      _placeholder={{ color: '#000000' }}
                      placeholder={menuItem?.redirect_value}
                      {...register('redirect_value2')}
                    />
                  </Box>
                </SimpleGrid>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <VStack spacing={4}>
              <Button
                colorScheme="brand"
                fontFamily="Poppins"
                lineHeight="21px"
                fontSize="14px"
                fontWeight="300"
                onClick={handleSubmit(handleForm)}
              >
                Salvar
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default UpdateEditableButtonModal;
