import React, { useContext, useState } from 'react';
import { useDisclosure, Grid, GridItem } from '@chakra-ui/react';
import BannerForm from './BannerForm';
import Banner from './Banner';
import context from '../context';

const Banners: React.FC = () => {
  const { pageGroup } = useContext(context);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [clickedBanner, setClickedBanner] = useState<any | undefined>(
    undefined,
  );

  return (
    <>
      <Grid
        h="350px"
        templateRows="repeat(2, 100px)"
        templateColumns="repeat(9, 100px)"
        justifyContent="center"
        mt={5}
        gap={1}
      >
        <GridItem colStart={1}>
          <Banner
            banner={pageGroup?.items[0]}
            index={0}
            disabled={false}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={1}>
          <Banner
            banner={pageGroup?.items[1]}
            index={1}
            disabled={pageGroup?.items?.length < 1}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem rowSpan={2} colStart={2} rowStart={1}>
          <Banner
            banner={pageGroup?.items[2]}
            index={2}
            disabled={pageGroup?.items?.length < 2}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={3} rowStart={1} colSpan={2}>
          <Banner
            banner={pageGroup?.items[3]}
            index={3}
            disabled={pageGroup?.items?.length < 3}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={3} rowStart={2}>
          <Banner
            banner={pageGroup?.items[4]}
            index={4}
            disabled={pageGroup?.items?.length < 4}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={4} rowStart={2}>
          <Banner
            banner={pageGroup?.items[5]}
            index={5}
            disabled={pageGroup?.items?.length < 5}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={5} rowStart={1} colSpan={2} rowSpan={2}>
          <Banner
            banner={pageGroup?.items[6]}
            index={6}
            disabled={pageGroup?.items?.length < 6}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={7} rowStart={1}>
          <Banner
            banner={pageGroup?.items[7]}
            index={7}
            disabled={pageGroup?.items?.length < 7}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={7} rowStart={2}>
          <Banner
            banner={pageGroup?.items[8]}
            index={8}
            disabled={pageGroup?.items?.length < 8}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={8} rowStart={1} rowSpan={2}>
          <Banner
            banner={pageGroup?.items[9]}
            index={9}
            disabled={pageGroup?.items?.length < 9}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={9} rowStart={1}>
          <Banner
            banner={pageGroup?.items[10]}
            index={10}
            disabled={pageGroup?.items?.length < 10}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={9} rowStart={2}>
          <Banner
            banner={pageGroup?.items[11]}
            index={11}
            disabled={pageGroup?.items?.length < 11}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
      </Grid>

      <BannerForm
        isOpen={isOpen}
        banner={clickedBanner}
        onCreated={onClose}
        onClose={() => {
          setClickedBanner(undefined);
          onClose();
        }}
        onDeleted={() => {
          setClickedBanner(undefined);
          onClose();
        }}
        onUpdated={() => {
          setClickedBanner(undefined);
          onClose();
        }}
      />
    </>
  );
};

export default Banners;
