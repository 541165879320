import { Flex, Button, useToast, IconButton, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import Input from '../../../components/Form/Input';
import useAuth from '../../../hooks/useAuth';

type FormData = {
  password: string;
  passwordConfirmation: string;
  email: string;
  token: string;
};

const formSchema = yup.object().shape({
  password: yup
    .string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve conter no mínimo 8 caracteres')
    .max(15, 'A senha deve conter no máximo 15 caracteres'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais')
    .required('Campo obrigatório'),
});

const ResetPassword: React.FC = () => {
  const toast = useToast();
  const { resetPassword } = useAuth();
  const history = useHistory();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { errors } = formState;
  const [show, setShow] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      Object.assign(data, {
        token: new URLSearchParams(window.location.search).get('token'),
        email: new URLSearchParams(window.location.search).get('email'),
      });
      await resetPassword(data);
      toast({
        title: 'Feito!',
        description: 'Senha redefinida!',
        status: 'success',
        position: 'top-right',
        duration: 5000,
      });
      history.push('/');
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro ao enviar o e-mail',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Flex w="100vw" h="100vh">
      <Flex flex={1} h="100vh" justify="center" align="center" bg="gray.600" />
      <Flex
        as="form"
        h="100vh"
        w={500}
        justify="center"
        p={42}
        flexDir="column"
        onSubmit={handleSubmit(handleForm)}
      >
        <Text fontSize="xl" textAlign="center" mb={3} fontWeight="semibold">
          Insira sua nova senha
        </Text>
        <Input
          label="Nova senha"
          type={show ? 'text' : 'password'}
          error={errors.password}
          InputRightElement={
            <IconButton
              variant="ghost"
              colorScheme="transparent"
              aria-label="Esconder ou mostrar a senha"
              icon={show ? <HiEye /> : <HiEyeOff />}
              onClick={() => setShow(state => !state)}
            />
          }
          {...register('password')}
        />
        <Input
          label="Repita a senha"
          type={showConfirmationPassword ? 'text' : 'password'}
          error={errors.passwordConfirmation}
          InputRightElement={
            <IconButton
              variant="ghost"
              colorScheme="transparent"
              aria-label="Esconder ou mostrar a senha"
              icon={showConfirmationPassword ? <HiEye /> : <HiEyeOff />}
              onClick={() => setShowConfirmationPassword(state => !state)}
            />
          }
          onClick={() => setShow(state => !state)}
          {...register('passwordConfirmation')}
        />

        <Button
          type="submit"
          mt={10}
          colorScheme="brand"
          size="md"
          isLoading={loading}
        >
          Enviar
        </Button>
      </Flex>
    </Flex>
  );
};

export default ResetPassword;
