import React from 'react';
import { Flex, Image, VStack, HStack, Box, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBr from 'date-fns/locale/pt-BR';
import { Draggable } from 'react-beautiful-dnd';

interface Props {
  banner: any;
  index: number;
  onClick: (banner: any) => void;
}

const Banner: React.FC<Props> = ({ banner, onClick, index }) => {
  return (
    <Draggable draggableId={banner?.id} index={index}>
      {provided => (
        <Flex
          draggable
          key={banner?.id}
          w="100%"
          h="150px"
          bg="gray.200"
          borderRadius="6px"
          p={4}
          boxShadow="lg"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => {
            if (onClick) onClick(banner);
          }}
        >
          <Image
            src={banner?.file?.url || '/placeholder_product.jpg'}
            h="100px"
            objectFit="contain"
            borderRadius="6px"
            mr={8}
          />
          <VStack spacing={6} alignItems="flex-start">
            <HStack spacing={8}>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Nome:
                </Text>
                <Text color="gray.700">{banner?.name}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Clicks:
                </Text>
                <Text color="gray.700">{banner?.clicks}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Redirecionamento:
                </Text>
                <Text color="gray.700">{banner?.redirect_type}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Valor do Redirecionamento:
                </Text>
                <Text color="gray.700">{banner?.redirect_value}</Text>
              </Box>
              {banner?.facets && (
                <Box>
                  <Text fontSize="xs" color="gray.500">
                    Facets:
                  </Text>
                  <Text color="gray.700">{banner?.facets}</Text>
                </Box>
              )}
            </HStack>
            <HStack spacing={6}>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Início:
                </Text>
                <Text color="gray.700">
                  {format(
                    new Date(banner?.schedule_start),
                    "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                    {
                      locale: ptBr,
                    },
                  )}
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Final:
                </Text>
                <Text color="gray.700">
                  {banner?.schedule_end
                    ? format(
                        new Date(banner?.schedule_end),
                        "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                        {
                          locale: ptBr,
                        },
                      )
                    : '-'}
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Flex>
      )}
    </Draggable>
  );
};

export default Banner;
