import React from 'react';
import { Flex, Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const Topic: React.FC<Props> = ({ data, phoneWidth }) => {
  return (
    <Box
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      transition="150ms ease"
      position="relative"
      w={phoneWidth}
      p={1}
      margin="5px 0 5px 0"
    >
      <>
        <Flex align="center">
          <Text fontWeight="bold" fontSize="lg" mr={2}>
            {data?.name}
          </Text>
          <Tooltips data={data} />
        </Flex>
        {!!data?.description && (
          <Text fontSize="xs" color="gray.400">
            {data.description}
          </Text>
        )}
      </>
      <Box p={5}>
        {data.items.length ? (
          <UnorderedList>
            {data.items.map((item: any) => (
              <ListItem>{item.name}</ListItem>
            ))}
          </UnorderedList>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default Topic;
