import React from 'react';
import { Flex, VStack, HStack, Box, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBr from 'date-fns/locale/pt-BR';
import { Draggable } from 'react-beautiful-dnd';

interface Props {
  product: any;
  index: number;
  onClick: (product: any) => void;
}

const Product: React.FC<Props> = ({ product, onClick, index }) => {
  return (
    <Draggable draggableId={product?.id} index={index}>
      {provided => (
        <Flex
          draggable
          key={product?.id}
          w="100%"
          h="80px"
          bg="gray.200"
          borderRadius="6px"
          p={4}
          boxShadow="lg"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => {
            if (onClick) onClick(product);
          }}
        >
          <VStack spacing={6} alignItems="flex-start">
            <HStack spacing={8}>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Nome:
                </Text>
                <Text color="gray.700">{product?.name}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Sku:
                </Text>
                <Text color="gray.700">{product?.reference_id}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Início:
                </Text>
                <Text color="gray.700">
                  {format(
                    new Date(product?.schedule_start),
                    "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                    {
                      locale: ptBr,
                    },
                  )}
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  Final:
                </Text>
                <Text color="gray.700">
                  {product?.schedule_end
                    ? format(
                        new Date(product?.schedule_end),
                        "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                        {
                          locale: ptBr,
                        },
                      )
                    : '-'}
                </Text>
              </Box>
            </HStack>
          </VStack>
        </Flex>
      )}
    </Draggable>
  );
};

export default Product;
