export const URL_HEADER1 =
  'https://ik.imagekit.io/gxhvijuyh/Shoulder/header1.png?updatedAt=1683559905470';
export const URL_HEADER2 =
  'https://ik.imagekit.io/gxhvijuyh/Shoulder/header2.png?updatedAt=1683559213649';
export const URL_HEADER3 =
  'https://ik.imagekit.io/gxhvijuyh/Shoulder/Captura%20de%20tela%20de%202023-07-27%2017-30-02.png?updatedAt=1690489843364';
export const URL_HEADER5 =
  'https://ik.imagekit.io/gxhvijuyh/Shoulder/LOGO_AMARELO_APP.png?updatedAt=1720193787495';
export const URL_HOME1 =
  'https://publicimages.brmalls.com.br/shoppings/AJNA-3OWN67.png';
export const URL_HOME2 =
  'https://blog.corebiz.ag/wp-content/uploads/2023/01/Atraves-do-CRO-Shoulder-tem-performance-51-melhor-na-ultima-Black-Friday-1200x900.webp';
export const URL_PROMO1 =
  'https://shoulder.vtexassets.com/arquivos/ids/2142592/231411900_0699_010-BLAZER-AMPLO-FAIXA.jpg?v=638211430695030000';
export const URL_PROMO2 =
  'https://shoulder.vtexassets.com/assets/vtex.file-manager-graphql/images/21256a28-e7f0-4b23-9d85-ec28a723275b___a521440134f27618f84275e1c8afa7a3.jpg';
export const URL_PROMO3 =
  'https://shoulder.vtexassets.com/arquivos/formbf-desktop-2.png';
export const URL_PROMO4 =
  'https://shoulder.vtexassets.com/arquivos/ids/2382524/011133750001_0079_030-CALCA-SARJA-MOM.jpg?v=638609708798370000';
