import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import {
  HStack,
  useToast,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Checkbox,
  Switch,
  Box,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import context from '../context';
import cms from '../../../apis/cms';
import Input from '../../Form/Input';
import Datepicker from '../../Form/Datepicker';
import Upload from '../../Form/Upload';
import Select from '../../Form/Select';
import redirectTypes from '../../../utils/redirectTypes';
import {
  CREATE_GROUP_ELEMENT,
  REMOVE_GROUP_ELEMENT,
  UPDATE_GROUP_ELEMENT,
} from '../../../graphQL/mutations';
import { GET_PAGES, GET_PAGE_DATA } from '../../../graphQL/queries';

interface FormData {
  reference_id?: string;
  name: string;
  countdown: boolean;
  schedule: boolean;
  schedule_start?: Date;
  schedule_end?: Date;
  facets?: string;
  reference_value?: string;
  file?: FileList;
  redirect_type: {
    label: string | number;
    value: string | number;
  };
  redirect_value:
    | string
    | {
        label: string | number;
        value: string | number;
      };
}

const formData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  schedule: yup.boolean(),
  countdown: yup.boolean(),
  redirect_type: yup
    .object()
    .shape({
      value: yup.string().required('Escolha uma forma de redirecionamento'),
      label: yup.string().required('Escolha uma forma de redirecionamento'),
    })
    .required('Escolha uma forma de redirecionamento'),
  redirect_value: yup
    .mixed()
    .test(
      'redirect_value',
      'Adicione um valor de redirecionamento',
      (value: any): any => {
        if (typeof value === 'string') {
          if (!value) {
            return false;
          }
          return true;
        }
        if (value?.value) {
          return true;
        }
        return false;
      },
    ),
  // file: yup.mixed().test('file', 'Insira uma imagem', (value: FileList) => {
  //   if (!value[0]) return false;
  //   return true;
  // }),
  schedule_start: yup.date().when('schedule', {
    is: true,
    then: yup.date().required('Data de início é obrigatória'),
  }),
  schedule_end: yup
    .date()
    .when(['schedule', 'schedule_start'], (schedule, schedule_start) => {
      if (!schedule) {
        return yup.date();
      }
      if (schedule_start) {
        return yup
          .date()
          .min(schedule_start, 'Data final deve ser superior a data inicial')
          .required('Data Final é obrigatória');
      }
      return yup.date().required('Data Final é obrigatória');
    }),
});

interface Props {
  onClose: () => void;
  onCreated?: () => void;
  onUpdated?: () => void;
  onDeleted?: () => void;
  isOpen: boolean;
  banner?: any;
}

const BannerForm: React.FC<Props> = ({
  onClose,
  isOpen,
  onCreated,
  banner,
  onUpdated,
  onDeleted,
}) => {
  const toast = useToast();

  const { pageGroup, element } = useContext(context);

  const {
    control,
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(formData),
  });

  const [createGroupElement] = useMutation(CREATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });
  const [updateGroupElement] = useMutation(UPDATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });
  const [removeGroupElement, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_GROUP_ELEMENT, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const { errors } = formState;

  const [schedule, setSchedule] = useState(false);
  const [withBorder, setWithBorder] = useState(false);
  const [imageOrVideo, setImageOrVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeRedirect, setTypeRedirect] = useState('');

  const pages = cms.graphql.readQuery({
    query: GET_PAGES,
  });

  useEffect(() => {
    if (!banner) return;
    setValue('reference_id', banner?.reference_id);
    setValue('name', banner?.name);
    setValue('countdown', banner?.countdown);
    setValue('facets', banner.facets);
    setValue('reference_value', banner?.reference_value);

    if (banner?.reference_value) {
      setImageOrVideo(true);
    } else {
      setImageOrVideo(false);
    }
    if (banner.withBorder) {
      setWithBorder(true);
    }
    setValue(
      'redirect_type',
      redirectTypes.find(type => type.value === banner?.redirect_type),
    );
    if (banner.redirect_type === 'app_screen') {
      setTypeRedirect('app_screen');
      const selectedValueSelect = pages.pages.find(
        (page: any) => page.slug === banner?.redirect_value,
      );
      setValue('redirect_value', {
        label: selectedValueSelect?.name,
        value: selectedValueSelect?.slug,
      });
    } else if (banner.redirect_type === 'query') {
      setTypeRedirect(banner.redirect_type);
      setValue('redirect_value', banner?.redirect_value);
    } else {
      setTypeRedirect('');
      setValue('redirect_value', banner?.redirect_value);
    }

    if (banner?.schedule_end) {
      setSchedule(true);
      setValue('schedule', true);
      setValue('schedule_start', new Date(banner?.schedule_start));
      setValue('schedule_end', new Date(banner?.schedule_end));
    }
  }, [banner, setValue]);

  useEffect(() => {
    if (removeError) {
      toast({
        title: 'Opss!!',
        description:
          removeError?.message || 'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, [removeError, toast]);

  const handleDelete = async () => {
    try {
      const confirmed = window.confirm(
        'Tem certeza que deseja excluir o banner?',
      );
      if (confirmed) {
        await removeGroupElement({
          variables: {
            id: banner?.id,
          },
        });
        if (onDeleted) onDeleted();
        reset();
        setSchedule(false);
        toast({
          title: 'Sucess!!',
          description: 'Banner deletado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch {
      // error
    }
  };

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);
      const { file, schedule: scheduleData, ...rest } = data;

      if (!imageOrVideo && !file?.length) {
        setError('file', {
          type: 'manual',
          message: 'Insira uma imagem',
        });
        return;
      }
      if (imageOrVideo && !rest.reference_value) {
        setError('reference_value', {
          type: 'manual',
          message: 'Insira uma URL',
        });
        return;
      }

      if (banner) {
        let uploadData: any = null;
        const variables: any = {
          updateGroupElementInput: {
            ...rest,
            id: banner?.id,
            redirect_type: rest.redirect_type.value,
            facets: rest?.facets,
            reference_value: rest?.reference_value,
            redirect_value:
              typeof data?.redirect_value === 'object'
                ? data?.redirect_value.value
                : data?.redirect_value,
            schedule_end: scheduleData ? rest.schedule_end : null,
            countdown: scheduleData ? rest.countdown : false,
            withBorder,
          },
        };
        if (file && file[0]) {
          const multipart = new FormData();
          multipart.append('file', file[0]);
          const { data: uploadDataFromApi } = await cms.rest.post<any>(
            '/upload',
            multipart,
          );
          uploadData = uploadDataFromApi;
        }
        if (uploadData) {
          variables.updateGroupElementInput.file_id = uploadData.id;
        }
        await updateGroupElement({
          variables,
        });
        if (onUpdated) onUpdated();
        reset();
        setSchedule(false);
        toast({
          title: 'Sucesso!',
          description: 'Banner editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else {
        let uploadDataCreate: any = null;
        if (file && file[0]) {
          const multipart = new FormData();
          multipart.append('file', file[0]);
          const { data: uploadData } = await cms.rest.post<any>(
            '/upload',
            multipart,
          );
          uploadDataCreate = uploadData;
        }
        await createGroupElement({
          variables: {
            createGroupElementInput: {
              ...rest,
              redirect_type: rest.redirect_type.value,
              facets: rest?.facets,
              reference_value: rest?.reference_value,
              redirect_value:
                typeof data?.redirect_value === 'object'
                  ? data?.redirect_value.value
                  : data?.redirect_value,
              page_group_id: pageGroup?.id,
              element_id: element?.id,
              file_id: uploadDataCreate?.id || null,
              withBorder,
            },
          },
        });
        if (onCreated) onCreated();
        reset();
        setSchedule(false);
        toast({
          title: 'Sucesso!',
          description: 'Banner criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      onClose={() => {
        reset();
        setSchedule(false);
        onClose();
      }}
      isOpen={isOpen}
      size="lg"
    >
      <DrawerOverlay zIndex={1_400} />
      <DrawerContent>
        <DrawerHeader>Novo Banner</DrawerHeader>
        <DrawerBody>
          <VStack as="form" spacing="8" onSubmit={handleSubmit(handleForm)}>
            <Flex>
              <FormControl
                display="flex"
                alignItems="center"
                marginRight="40px"
              >
                <FormLabel htmlFor="border" mb="0">
                  Habilitar borda
                </FormLabel>
                <Switch
                  size="lg"
                  id="border"
                  isChecked={withBorder}
                  onChange={() => {
                    setWithBorder(!withBorder);
                    console.log({ withBorder });
                  }}
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="schedule" mb="0">
                  Desejo cadastrar vídeo
                </FormLabel>
                <Switch
                  id="video_or_image"
                  size="lg"
                  colorScheme="brand"
                  isChecked={imageOrVideo}
                  onChange={() => setImageOrVideo(!imageOrVideo)}
                />
              </FormControl>
            </Flex>
            <Flex>
              <Box style={{ display: imageOrVideo ? 'inherit' : 'none' }}>
                <Input
                  label="Url do vídeo"
                  error={errors.reference_value}
                  {...register('reference_value')}
                />
              </Box>
              <Box style={{ display: imageOrVideo ? 'none' : 'inherit' }}>
                <Upload
                  label="Imagem"
                  acceptedFileTypes="image/*"
                  alignSelf="center"
                  error={errors.file}
                  url={banner?.file?.url}
                  {...register('file')}
                />
              </Box>
            </Flex>
            <Input label="Nome" error={errors.name} {...register('name')} />
            <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
              <Select
                name="redirect_type"
                control={control}
                label="Redirecionamento"
                error={errors.redirect_type}
                options={redirectTypes}
                onChange={(e: any) => {
                  setValue('redirect_value', '');
                  setTypeRedirect(e.value);
                }}
              />
              {typeRedirect !== 'app_screen' ? (
                <Input
                  label="Valor do Redirecionamento"
                  error={errors.redirect_value}
                  {...register('redirect_value')}
                />
              ) : (
                <Select
                  name="redirect_value"
                  control={control}
                  label="Valor do Redirecionamento"
                  error={errors.redirect_value}
                  options={pages.pages.map((page: any) => {
                    return {
                      label: page?.name,
                      value: page?.slug,
                    };
                  })}
                />
              )}
            </SimpleGrid>
            {typeRedirect === 'query' && (
              <Input
                label="Facets"
                w="100%"
                error={errors.facets}
                {...register('facets')}
              />
            )}
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="schedule" mb="0">
                Agendamento
              </FormLabel>
              <Switch
                id="schedule"
                size="lg"
                colorScheme="brand"
                isChecked={schedule}
                {...register('schedule')}
                onChange={() => setSchedule(state => !state)}
              />
            </FormControl>
            {schedule && (
              <>
                <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
                  <Datepicker
                    isRequired
                    control={control}
                    name="schedule_start"
                    label="Início"
                    showTimeSelect
                    error={errors.schedule_start}
                  />
                  <Datepicker
                    isRequired
                    control={control}
                    name="schedule_end"
                    label="Final"
                    showTimeSelect
                    error={errors.schedule_end}
                  />
                </SimpleGrid>
                <Checkbox
                  alignSelf="flex-start"
                  colorScheme="brand"
                  {...register('countdown')}
                >
                  Contagem Regressiva
                </Checkbox>
              </>
            )}
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={4}>
            {!!banner && (
              <Button
                isLoading={removeLoading || loading}
                colorScheme="red"
                onClick={handleDelete}
              >
                Excluir
              </Button>
            )}
            <Button
              isLoading={removeLoading || loading}
              colorScheme="brand"
              onClick={handleSubmit(handleForm)}
            >
              Salvar
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default BannerForm;
