import React from 'react';
import {
  Flex,
  Box,
  Button,
  OrderedList,
  ListItem,
  Text,
  Divider,
} from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const RedirectMenu: React.FC<Props> = ({ data, phoneWidth }) => {
  return (
    <Box
      w={phoneWidth}
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      transition="150ms ease"
      position="relative"
      p={1}
      margin="5px 0 5px 0"
    >
      <>
        <Flex align="center">
          <Text fontWeight="bold" fontSize="lg" mr={2}>
            {data?.name}
          </Text>
          <Tooltips data={data} />
        </Flex>
        {!!data?.description && (
          <Text fontSize="xs" color="gray.400">
            {data.description}
          </Text>
        )}
      </>
      <Flex justifyItems="center" ml={3}>
        <Box w="90%" border="1px" borderColor="gray.500" p={3}>
          <Text textAlign="center">{data.name}</Text>
          <Divider />
          {data.items.length ? (
            <>
              <OrderedList>
                {data.items
                  .filter((i: any) => i.type !== '1')
                  .map((item: any) => (
                    <ListItem color="gray.500">{item.name}</ListItem>
                  ))}
              </OrderedList>
              {data.items
                .filter((i: any) => i.type === '1')
                .map((item: any) => (
                  <Button
                    colorScheme="blackAlpha"
                    mt={2}
                    borderRadius="0px"
                    width="100%"
                  >
                    {item.name}
                  </Button>
                ))}
            </>
          ) : (
            ''
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default RedirectMenu;
