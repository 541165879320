import React from 'react';
import { Box, BoxProps, VStack, useTheme, Skeleton } from '@chakra-ui/react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import components from './components';

interface Props extends BoxProps {
  data: any[];
  loading?: boolean;
  onGroupClicked?: (group: any) => void;
}

const Phone: React.FC<Props> = ({ data, loading, onGroupClicked, ...rest }) => {
  const theme = useTheme();
  const phoneWidth = 375;

  const renderComponent = (slug: string, props: any): JSX.Element | null => {
    const Component = components[slug];
    if (!Component) return null;
    return <Component {...props} />;
  };

  return (
    <Box
      overflowY="auto"
      overflowX="hidden"
      w={phoneWidth}
      h="100%"
      bg="#FFF"
      borderRadius="52px"
      borderWidth="75px 10px"
      borderColor="black"
      boxShadow="md"
      css={{
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.colors.gray['500'],
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-track:hover': {
          background: theme.colors.gray['300'],
        },
      }}
      {...rest}
    >
      <Droppable droppableId="phone">
        {provided => (
          <Box
            minH="100%"
            align="flex-start"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {loading ? (
              <VStack spacing={4}>
                <Skeleton h="125px" w="100%" borderRadius="6px" />
                <Skeleton h="125px" w="100%" borderRadius="6px" />
                <Skeleton h="125px" w="100%" borderRadius="6px" />
                <Skeleton h="125px" w="100%" borderRadius="6px" />
                <Skeleton h="125px" w="100%" borderRadius="6px" />
                <Skeleton h="125px" w="100%" borderRadius="6px" />
              </VStack>
            ) : (
              <>
                {data.map((group, index) => (
                  <Draggable
                    draggableId={group.id}
                    index={index}
                    key={group.id}
                  >
                    {dragProvided => (
                      <Box
                        onClick={() => {
                          if (onGroupClicked) {
                            onGroupClicked(group);
                          }
                        }}
                        draggable
                        w="100%"
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                      >
                        {renderComponent(group?.display?.slug, {
                          data: group,
                          phoneWidth,
                        })}
                        {/* <Flex bg="blue" w="100%" h="100px">
                          {group.name}
                        </Flex> */}
                      </Box>
                    )}
                  </Draggable>
                ))}
              </>
            )}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default Phone;
