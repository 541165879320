import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ExpandedIndex,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BsCircleFill } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import redirectTypes from '../../../../../utils/redirectTypes';
import { MenuItemType } from '../../../types';

interface Props {
  item: MenuItemType;
  level: number;
  loading: boolean;
  renderContent?: boolean;
  index: number;
  setMenuItemEdit: any;
  setSubMenuItemEdit: (id: string) => any;
  onOpen: any;
  handleMenuItemDelete: (id: string) => Promise<void>;
}

const MenuItem: React.FC<Props> = ({
  item,
  level,
  renderContent,
  index,
  loading,
  setSubMenuItemEdit,
  setMenuItemEdit,
  onOpen,
  handleMenuItemDelete,
}) => {
  const [activeAccordion, setActiveAccordion] = useState<ExpandedIndex>(-1);
  return (
    <Draggable draggableId={item?.id} index={index}>
      {provided => (
        <AccordionItem
          p="7px"
          borderColor="gray.300"
          borderRadius="9px"
          border="2px solid #F9F9F9"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <h2>
            <AccordionButton bg="#F9F9F9" borderRadius="9px">
              <Box flex="1" textAlign="left">
                <Flex align="center">
                  {item?.file_left?.id && (
                    <Image src={item?.file_left?.url} boxSize="30px" />
                  )}
                  <Text
                    margin="0 10px 0 10px"
                    position="relative"
                    fontWeight="semibold"
                    fontSize="18px"
                  >
                    {item?.name}
                  </Text>
                  {item?.file_right?.id && (
                    <Image src={item?.file_right?.url} boxSize="30px" mr={2} />
                  )}
                  {item.active && (
                    <>
                      <BsCircleFill size="6px" color="#D9D9D9" />
                      <Button
                        bg="#1CBF71"
                        width="51px"
                        height="26px"
                        margin="0 0 0 10px"
                      >
                        <Text color="#FFFFFF">Ativo</Text>
                      </Button>
                    </>
                  )}
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {renderContent && (
              <>
                <Box mt={8}>
                  {item?.banner_file?.id && (
                    <Flex w="fit-content" flexDirection="column" mb={4}>
                      <Box
                        display="flex"
                        border="1px solid #068FDD"
                        borderRadius="14px"
                        width="40rem"
                        height="10rem"
                        bg="#F9FDFF"
                      >
                        <Box
                          boxSizing="border-box"
                          display="block"
                          alignItems="center"
                        >
                          {item?.reference_value && (
                            <Text
                              color="#469BFF"
                              fontFamily="Poppins"
                              marginTop="0.5rem"
                              marginLeft="0.5rem"
                            >
                              <b>Link vídeo: </b>
                              {item?.reference_value}
                            </Text>
                          )}
                          <Text
                            color="#469BFF"
                            fontFamily="Poppins"
                            marginTop="1rem"
                            marginLeft="0.5rem"
                          >
                            <b>Redirecionamento:</b>{' '}
                            {item.redirect_type &&
                              redirectTypes.find(
                                (type: any) =>
                                  type.value === item?.redirect_type,
                              )?.label}
                          </Text>
                          <Text
                            color="#469BFF"
                            fontFamily="Poppins"
                            marginTop="1rem"
                            marginLeft="0.5rem"
                          >
                            <b>Valor redirecionamento:</b>{' '}
                            {item.redirect_value && item?.redirect_value}
                          </Text>
                          <Text
                            color="#469BFF"
                            fontFamily="Poppins"
                            marginTop="1rem"
                            marginLeft="0.5rem"
                          >
                            <b>Valor redirecionamento Banner:</b>{' '}
                            {item?.banner_redirect_value}
                          </Text>
                        </Box>
                        <Image
                          src={item?.banner_file?.url}
                          boxSize="130px"
                          position="relative"
                          right="-37%"
                          marginTop="0.5rem"
                        />
                      </Box>
                    </Flex>
                  )}
                </Box>
                {item ? (
                  <Flex justify="end">
                    <Button
                      margin="0 5px"
                      aria-label="edit-menu-item"
                      rounded="md"
                      size="md"
                      bg="#F3F3F3"
                      textColor="#262626"
                      isLoading={loading}
                      onClick={() => {
                        onOpen();
                        setMenuItemEdit(item);
                      }}
                    >
                      Editar item
                    </Button>
                    <Button
                      margin="0 5px"
                      aria-label="delete-menu-item"
                      rounded="md"
                      size="md"
                      bg="#FFEBF0"
                      textColor="red"
                      isLoading={loading}
                      onClick={() => {
                        handleMenuItemDelete(item.id);
                      }}
                    >
                      Excluir
                    </Button>
                    {level <= 2 && (
                      <Button
                        margin="0 5px"
                        aria-label="create-sub-menu-item"
                        rounded="md"
                        icon={<FaPlus size={10} />}
                        size="md"
                        bg="#469BFF"
                        textColor="#FFFFFF"
                        isLoading={loading}
                        onClick={() => {
                          setSubMenuItemEdit(item.id);
                          setMenuItemEdit(undefined);
                          onOpen();
                        }}
                      >
                        Adicionar sub-menu
                      </Button>
                    )}
                  </Flex>
                ) : (
                  ''
                )}
                <Droppable droppableId={item?.id} type={`leaf-${item?.id}`}>
                  {droppableProvided => (
                    <Accordion
                      allowToggle
                      w="100%"
                      p={4}
                      index={activeAccordion}
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                      onChange={setActiveAccordion}
                    >
                      {item?.children?.map((child: any, i: number) => (
                        <MenuItem
                          key={child.id}
                          item={child}
                          level={level + 1}
                          index={i}
                          renderContent={i === activeAccordion}
                          handleMenuItemDelete={handleMenuItemDelete}
                          loading={loading}
                          onOpen={onOpen}
                          setMenuItemEdit={setMenuItemEdit}
                          setSubMenuItemEdit={setSubMenuItemEdit}
                        />
                      ))}
                      {droppableProvided.placeholder}
                    </Accordion>
                  )}
                </Droppable>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
      )}
    </Draggable>
  );
};

export default MenuItem;
