import React, { useEffect, useState } from 'react';
import { Flex, Box, VStack, Image, Divider, useToast } from '@chakra-ui/react';
import { ImMobile } from 'react-icons/im';
import { FaUserAlt } from 'react-icons/fa';
import { AiFillFileText, AiOutlineMenu } from 'react-icons/ai';
import { useQuery } from '@apollo/client';
import NavItem from './NavItem';
import { GET_PAGES } from '../../graphQL/queries';
import NavSkeleton from './NavSkeleton';
import NavGroup from './NavGroup';
import cms from '../../apis/cms';

const Aside: React.FC = () => {
  const { data: state, loading } = useQuery(GET_PAGES);
  const [logo, setLogo] = useState('');
  const toast = useToast();

  useEffect(() => {
    async function getLogo() {
      try {
        const { data } = await cms.rest.get<any>('/config-images/image_menu', {
          params: {
            withFile: true,
          },
        });
        setLogo(data?.file.url);
      } catch (err: any) {
        console.log(err);
      }
    }
    getLogo();
  }, [toast]);

  return (
    <Flex
      py="20px"
      h="100vh"
      as="aside"
      flexDir="column"
      w={225}
      bg="#F9F9F9"
      zIndex={20}
      boxShadow="2xl"
    >
      {logo ? (
        <Box px="20px" mb={6}>
          <Image src={logo} mb="16px" />
          <Divider />
        </Box>
      ) : (
        ''
      )}

      <VStack as="nav" spacing={0}>
        {loading ? (
          <NavSkeleton />
        ) : (
          <>
            {/* <NavItem
              icon={MdOutlineDashboard}
              title="Dashboard"
              to="/dashboard"
            /> */}
            <NavItem icon={FaUserAlt} title="Usuários" to="/users" />
            <NavGroup
              title="Telas Dinâmicas"
              icon={ImMobile}
              items={state?.pages?.map((page: any) => ({
                title: page?.name,
                to: `/screens/${page?.slug}`,
              }))}
            />
            {/* <NavItem icon={MdList} title="Categorias" to="/categories" /> */}
            <NavItem icon={FaUserAlt} title="Configurações" to="/configs" />
            <NavItem
              icon={AiOutlineMenu}
              title="Itens de Menu"
              to="/menu-items/menu"
            />
            <NavItem icon={AiOutlineMenu} title="LP" to="/lps" />
            {/* <NavItem icon={AiOutlineMenu} title="Cronômetro" to="/stopwatch" /> */}
            <NavItem
              icon={AiOutlineMenu}
              title="Textos Editáveis"
              to="/editable-text"
            />
            <NavItem
              icon={AiOutlineMenu}
              title="Botões Editáveis"
              to="/editable-button"
            />
            <NavItem
              icon={AiOutlineMenu}
              title="Perguntas Frequentes"
              to="/questions"
            />
            <NavItem icon={AiOutlineMenu} title="Formulários" to="/form" />
            <NavItem
              icon={AiOutlineMenu}
              title="Itens do Perfil"
              to="/menu-items/profile"
            />
            <NavItem
              icon={AiFillFileText}
              title="Páginas dinâmicas"
              to="/dynamic_pages"
            />
          </>
        )}
      </VStack>
    </Flex>
  );
};

export default Aside;
