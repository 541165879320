import React, { useContext, useEffect, useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import Input from '../../Form/Input';
import Select from '../../Form/Select';
import redirectTypes from '../../../utils/redirectTypes';
import {
  CREATE_GROUP_ELEMENT,
  UPDATE_GROUP_ELEMENT,
} from '../../../graphQL/mutations';
import { GET_PAGES, GET_PAGE_DATA } from '../../../graphQL/queries';
import context from '../context';
import cms from '../../../apis/cms';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: (item: any) => void;
  onEdited?: (item: any) => void;
  onDelete?: () => void;
  Item?: null | any;
}

interface FormData {
  name: string;
  redirect_value:
    | string
    | {
        label: string | number;
        value: string | number;
      };

  redirect_type: {
    label: string | number;
    value: string | number;
  };
  facets?: string;
}

const createFormData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  redirect_value: yup
    .mixed()
    .test(
      'redirect_value',
      'Adicione um valor de redirecionamento',
      (value: any): any => {
        if (typeof value === 'string') {
          if (!value) {
            return false;
          }
          return true;
        }
        if (value?.value) {
          return true;
        }
        return false;
      },
    ),
  redirect_type: yup
    .object()
    .shape({
      value: yup.string().required('Escolha uma forma de redirecionamento'),
      label: yup.string().required('Escolha uma forma de redirecionamento'),
    })
    .required('Escolha uma forma de redirecionamento'),
});

const ItemForm: React.FC<Props> = ({
  onClose,
  isOpen,
  Item,
  onCreated,
  onEdited,
}) => {
  const { formState, handleSubmit, register, setValue, reset, control } =
    useForm({
      resolver: yupResolver(createFormData),
    });

  const [createGroupElement] = useMutation(CREATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const [updateGroupElement] = useMutation(UPDATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const { pageGroup, element } = useContext(context);

  const toast = useToast();
  const { errors } = formState;
  const [loading, setLoading] = useState(false);

  const [typeRedirect, setTypeRedirect] = useState('');

  const pages = cms.graphql.readQuery({
    query: GET_PAGES,
  });

  useEffect(() => {
    if (!Item) return;
    setValue('name', Item.name);
    setValue('facets', Item.facets);
    setValue(
      'redirect_type',
      redirectTypes.find(type => type.value === Item?.redirect_type),
    );
    if (Item.redirect_type === 'app_screen') {
      setTypeRedirect('app_screen');
      const selectedValueSelect = pages.pages.find(
        (page: any) => page.slug === Item?.redirect_value,
      );
      setValue('redirect_value', {
        label: selectedValueSelect?.name,
        value: selectedValueSelect?.slug,
      });
    } else if (Item.redirect_type === 'query') {
      setTypeRedirect(Item.redirect_type);
      setValue('redirect_value', Item?.redirect_value);
    } else {
      setTypeRedirect('');
      setValue('redirect_value', Item?.redirect_value);
    }
  }, [Item, setValue]);

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      const { ...rest } = data;
      setLoading(true);
      if (!Item) {
        const groupNewElement = await createGroupElement({
          variables: {
            createGroupElementInput: {
              ...rest,
              name: data?.name,
              facets: data?.facets,
              page_group_id: pageGroup?.id,
              element_id: element?.id,
              redirect_value:
                typeof data?.redirect_value === 'object'
                  ? data?.redirect_value.value
                  : data?.redirect_value,
              redirect_type: data?.redirect_type.value,
            },
          },
        });

        if (onCreated) onCreated(groupNewElement?.data?.createGroupElement);
        toast({
          title: 'Sucesso',
          description: 'Item criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else {
        const groupNewElement = await updateGroupElement({
          variables: {
            updateGroupElementInput: {
              ...rest,
              id: Item?.id,
              name: data?.name,
              facets: data?.facets,
              page_group_id: pageGroup?.id,
              element_id: element?.id,
              redirect_value:
                typeof data?.redirect_value === 'object'
                  ? data?.redirect_value.value
                  : data?.redirect_value,
              redirect_type: data?.redirect_type.value,
            },
          },
        });
        if (onEdited) onEdited(groupNewElement?.data?.updateGroupElement);
        toast({
          title: 'Sucesso',
          description: 'Item editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
      reset();
      onClose();
    }
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent maxH="90vh" as="form" onSubmit={handleSubmit(handleForm)}>
        <ModalHeader>Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8}>
            <Input label="Nome" error={errors.name} {...register('name')} />
            <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
              <Select
                name="redirect_type"
                control={control}
                label="Redirecionamento"
                error={errors.redirect_type}
                options={redirectTypes}
                onChange={(e: any) => {
                  setValue('redirect_value', '');
                  setTypeRedirect(e.value);
                }}
              />
              {typeRedirect !== 'app_screen' ? (
                <Input
                  label="Valor do Redirecionamento"
                  error={errors.redirect_value}
                  {...register('redirect_value')}
                />
              ) : (
                <Select
                  name="redirect_value"
                  control={control}
                  label="Valor do Redirecionamento"
                  error={errors.redirect_value}
                  options={pages.pages.map((page: any) => {
                    return {
                      label: page?.name,
                      value: page?.slug,
                    };
                  })}
                />
              )}
            </SimpleGrid>
            {typeRedirect === 'query' && (
              <Input
                label="Facets"
                w="100%"
                error={errors.facets}
                {...register('facets')}
              />
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button onClick={onClose}>Fechar</Button>
            <Button colorScheme="brand" isLoading={loading} type="submit">
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ItemForm;
