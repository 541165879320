import React from 'react';
import { Flex, Box, Image, Text, HStack } from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const BannerCarousel: React.FC<Props> = ({ data, phoneWidth }) => {
  const padding = 4;
  const bannerWidth = phoneWidth - 2 * padding - 8;

  return (
    <Box
      position="relative"
      w={phoneWidth - 8}
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      borderRadius="6px"
      transition="150ms ease"
      p={`${padding}px`}
    >
      {data?.name ? (
        <>
          <Flex align="center" px={4}>
            <Text fontWeight="bold" fontSize="lg" mr={2}>
              {data?.name}
            </Text>
            <Tooltips data={data} />
          </Flex>
          {!!data?.description && (
            <Text fontSize="xs" color="gray.400" px={4}>
              {data.description}
            </Text>
          )}
        </>
      ) : (
        <Flex position="absolute" left={2} top={1}>
          <Tooltips data={data} />
        </Flex>
      )}
      <HStack overflow="auto" mt={4}>
        {data?.items?.map((banner: any) => (
          <Flex
            flexDir="column"
            align="center"
            w={bannerWidth}
            flex="none"
            key={banner?.id}
          >
            <Image
              w="100%"
              h="160px"
              borderRadius="6px"
              mb={2}
              src="/placeholder_product.jpg"
            />
            <Text fontSize="xs" color="gray.500">
              {banner?.name}
            </Text>
          </Flex>
        ))}
        <Flex flexDir="column" align="center" w={bannerWidth} flex="none">
          <Image
            w="100%"
            h="160px"
            borderRadius="6px"
            borderWidth="2px"
            borderStyle="dashed"
            mb={2}
          />
          <Text fontSize="xs" color="gray.500">
            + Adicionar
          </Text>
        </Flex>
      </HStack>
    </Box>
  );
};

export default BannerCarousel;
