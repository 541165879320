import React, { useContext, useState } from 'react';
import { useDisclosure, Grid, GridItem, Box } from '@chakra-ui/react';
import BannerForm from './BannerForm';
import Banner from './Banner';
import context from '../context';

const Banners: React.FC = () => {
  const { pageGroup } = useContext(context);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [clickedBanner, setClickedBanner] = useState<any | undefined>(
    undefined,
  );

  return (
    <>
      <Box h="70vh" display="block">
        <Grid
          templateRows="repeat(10, 75px)"
          templateColumns="repeat(2, 120px)"
          justifyContent="center"
          mt={1}
          gap={1}
          h="100%"
          overflowY="auto"
          overscrollY="auto"
        >
          <GridItem colStart={1}>
            <Banner
              banner={pageGroup?.items[0]}
              index={0}
              disabled={false}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={1}>
            <Banner
              banner={pageGroup?.items[1]}
              index={1}
              disabled={pageGroup?.items?.length < 1}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem rowSpan={2} colStart={2} rowStart={1}>
            <Banner
              banner={pageGroup?.items[2]}
              index={2}
              disabled={pageGroup?.items?.length < 2}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem rowSpan={2} colStart={1} rowStart={3}>
            <Banner
              banner={pageGroup?.items[3]}
              index={3}
              disabled={pageGroup?.items?.length < 3}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={2} rowSpan={1} rowStart={3}>
            <Banner
              banner={pageGroup?.items[4]}
              index={4}
              disabled={pageGroup?.items?.length < 4}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={2} rowStart={4} rowSpan={1}>
            <Banner
              banner={pageGroup?.items[5]}
              index={5}
              disabled={pageGroup?.items?.length < 5}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={1} rowSpan={1} rowStart={5}>
            <Banner
              banner={pageGroup?.items[6]}
              index={6}
              disabled={pageGroup?.items?.length < 6}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={1}>
            <Banner
              banner={pageGroup?.items[7]}
              index={7}
              disabled={pageGroup?.items?.length < 7}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={2} rowSpan={2} rowStart={5}>
            <Banner
              banner={pageGroup?.items[8]}
              index={8}
              disabled={pageGroup?.items?.length < 8}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={1} rowStart={7} rowSpan={2}>
            <Banner
              banner={pageGroup?.items[9]}
              index={9}
              disabled={pageGroup?.items?.length < 9}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={2} rowStart={7}>
            <Banner
              banner={pageGroup?.items[10]}
              index={10}
              disabled={pageGroup?.items?.length < 10}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={2} rowStart={8}>
            <Banner
              banner={pageGroup?.items[11]}
              index={11}
              disabled={pageGroup?.items?.length < 11}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={1} rowStart={9}>
            <Banner
              banner={pageGroup?.items[12]}
              index={12}
              disabled={pageGroup?.items?.length < 12}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={1} rowStart={10}>
            <Banner
              banner={pageGroup?.items[13]}
              index={13}
              disabled={pageGroup?.items?.length < 13}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
          <GridItem colStart={2} rowStart={9} rowSpan={2}>
            <Banner
              banner={pageGroup?.items[14]}
              index={14}
              disabled={pageGroup?.items?.length < 14}
              onClick={banner => {
                onOpen();
                setClickedBanner(banner);
              }}
            />
          </GridItem>
        </Grid>
        <BannerForm
          isOpen={isOpen}
          banner={clickedBanner}
          onCreated={onClose}
          onClose={() => {
            setClickedBanner(undefined);
            onClose();
          }}
          onDeleted={() => {
            setClickedBanner(undefined);
            onClose();
          }}
          onUpdated={() => {
            setClickedBanner(undefined);
            onClose();
          }}
        />
      </Box>
    </>
  );
};

export default Banners;
