import React, { FC } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import ListMenuItem from './pages/List';

const MenuItems: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:location`} component={ListMenuItem} />
    </Switch>
  );
};

export default MenuItems;
