import React, { useEffect, useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import {
  IconButton,
  Text,
  Box,
  Tr,
  Td,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { REMOVE_PAGE } from '../../../../graphQL/mutations';
import { GET_PAGES } from '../../../../graphQL/queries';
import { constants } from '../../../../utils/constants';

interface Props {
  page: any;
  onDelete?: (pageId: any) => void;
  onEditClick?: (page: any) => void;
}

const Item: React.FC<Props> = ({ page, onDelete, onEditClick }) => {
  const toast = useToast();

  const [removePage, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_PAGE, {
      refetchQueries: [GET_PAGES, 'GetPages'],
    });

  const handlePageDelete = async (pageId: string) => {
    if (page.slug === constants.HOME_SLUG) {
      toast({
        title: 'Opss!!',
        description: 'Não é possível excluir a página Home',
        status: 'error',
        position: 'top-right',
      });
    } else {
      try {
        if (window.confirm('Deseja realmente excluir essa página!')) {
          await removePage({
            variables: {
              id: pageId,
            },
          });
          toast({
            title: 'Sucesso!',
            description: 'Página excluída com sucesso',
            status: 'success',
            position: 'top-right',
          });
          if (onDelete) {
            onDelete(pageId);
          }
        }
      } catch (err: any) {
        toast({
          title: 'Opss!!',
          description:
            err?.response?.data?.message ||
            err?.message ||
            'Tivemos um erro, tente novamente mais tarde',
          status: 'error',
          position: 'top-right',
        });
      }
    }
  };

  useEffect(() => {
    if (removeError) {
      toast({
        title: 'Opss!!',
        description:
          removeError?.message || 'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, [removeError, toast]);

  return (
    <Box
      border="1px solid #FFFFFF"
      borderRadius="14px"
      bg="#FFFFFF"
      width="90%"
      height="61px"
      margin="10px"
    >
      <Tr display="flex">
        <Td>
          <Box>
            <Text position="relative">{page?.name}</Text>
          </Box>
        </Td>
        <Td alignItems="self-start">
          <Box>
            <Text position="absolute" right="55%">
              {page?.slug}
            </Text>
          </Box>
        </Td>
        <Td>
          <HStack spacing={3}>
            <IconButton
              color="#CECECE"
              aria-label="edit-page"
              position="absolute"
              right="23%"
              rounded="md"
              icon={<FaPen size={17} />}
              size="xs"
              colorScheme="#FFFFFF"
              isLoading={removeLoading}
              onClick={() => {
                if (onEditClick) {
                  onEditClick(page);
                }
              }}
            />
            {page.slug !== 'home' && (
              <IconButton
                aria-label="delete-page"
                rounded="md"
                color="#FF6565"
                icon={<FaTrash size={17} />}
                size="xs"
                colorScheme="#FFFFFF"
                position="absolute"
                right="20%"
                isLoading={removeLoading}
                onClick={() => {
                  handlePageDelete(page?.id);
                }}
              />
            )}
          </HStack>
        </Td>
      </Tr>
    </Box>
  );
};

export default Item;
