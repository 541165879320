import React from 'react';
import { Flex, Box, SimpleGrid, Heading } from '@chakra-ui/react';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { HiOutlineTicket } from 'react-icons/hi';
import { AiOutlineShoppingCart, AiOutlineUser } from 'react-icons/ai';
import Card from './Card';
import Chart from './Chart';

const Main: React.FC = () => {
  return (
    <Box p={8} w="100%" overflow="auto">
      <Heading mb="24px">Dashboard</Heading>
      <Flex
        flexDir="column"
        bg="gray.100"
        p={8}
        w="100%"
        borderRadius={6}
        boxShadow="lg"
      >
        <SimpleGrid minChildWidth="150px" spacing={4} w="100%">
          <Card
            active
            title="Faturado"
            value="R$ 0,00"
            icon={FaRegMoneyBillAlt}
          />
          <Card title="Ticket Médio" value="R$ 0,00" icon={HiOutlineTicket} />
          <Card
            title="Total de Vendas"
            value="R$ 0,00"
            icon={AiOutlineShoppingCart}
          />
          <Card title="Usuários ativos" value="1" icon={AiOutlineUser} />
        </SimpleGrid>
        <Chart mt={16} data={[]} />
      </Flex>
    </Box>
  );
};

export default Main;
