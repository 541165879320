import React from 'react';
import { Flex, Box, Image, Text, HStack } from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const VtexCollectionPhone: React.FC<Props> = ({ data, phoneWidth }) => {
  return (
    <Box
      w={phoneWidth}
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      borderRadius="6px"
      transition="150ms ease"
      position="relative"
      p={4}
    >
      {data?.name ? (
        <>
          <Flex align="center">
            <Text fontWeight="bold" fontSize="lg" mr={2}>
              {data?.name}
            </Text>
            <Tooltips data={data} />
          </Flex>
          {!!data?.description && (
            <Text fontSize="xs" color="gray.400">
              {data.description}
            </Text>
          )}
        </>
      ) : (
        <Flex position="absolute" left={2} top={1}>
          <Tooltips data={data} />
        </Flex>
      )}

      <HStack spacing={4} overflow="auto" mt={4}>
        {data?.products.length ? (
          data?.products?.map((item: any) => {
            const installments = [
              ...item?.items[0]?.sellers[0]?.commertialOffer?.Installments,
            ];
            const [higherInstallment] = installments.sort(
              (first: any, second: any) =>
                second.NumberOfInstallments - first.NumberOfInstallments,
            );
            const image = item?.items[0]?.images[0]?.imageUrl;
            const listPrice =
              item?.items[0]?.sellers[0]?.commertialOffer?.ListPrice;
            const price = item?.items[0]?.sellers[0]?.commertialOffer?.Price;
            const discount = Math.floor((1 - price / listPrice) * 100);

            return (
              <Flex
                p={4}
                w={170}
                key={item?.productId}
                borderColor="gray.300"
                borderWidth="1px"
                flexDir="column"
                borderRadius="6px"
                flex="none"
              >
                <Flex>
                  <Flex
                    ml="auto"
                    bg="brand.500"
                    p={1}
                    borderRadius="6px"
                    mb={4}
                  >
                    {discount && (
                      <Text fontSize="xs" color="white">
                        - {discount}% OFF
                      </Text>
                    )}
                  </Flex>
                </Flex>
                {image.substring(image.lastIndexOf('.') + 1, image.length) !==
                'mp4' ? (
                  <Image
                    src={image || '/placeholder_product.jpg'}
                    w="70px"
                    h="70px"
                    alignSelf="center"
                    mb={4}
                  />
                ) : (
                  <iframe
                    title={data?.name}
                    src={image || '/placeholder_product.jpg'}
                    allowFullScreen
                  />
                )}
                <Text fontSize="sm" mb={6}>
                  {item.productName}
                </Text>
                <Box marginTop="auto">
                  {listPrice && (
                    <Text
                      textDecor="line-through"
                      fontSize="xs"
                      color="gray.400"
                    >
                      {Number(listPrice).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Text>
                  )}
                  <Text fontSize="sm" fontWeight="bold">
                    {Number(price).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Text>
                  <Text fontSize="xs">
                    ou {higherInstallment?.NumberOfInstallments}x de{' '}
                    {Number(higherInstallment?.Value).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Text>
                </Box>
              </Flex>
            );
          })
        ) : (
          <Flex w="100%" justify="center" mt={2} mb={2} textAlign="center">
            <Text color="red" w="70%">
              Não foi encontrado nenhum item cadastrado nessa coleção
            </Text>
          </Flex>
        )}
      </HStack>
    </Box>
  );
};

export default VtexCollectionPhone;
