import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth?: number;
}

const SingleBanner: React.FC<Props> = ({ data }) => {
  return (
    <Box
      w="100%"
      position="relative"
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      borderRadius="6px"
      transition="150ms ease"
    >
      {data?.name ? (
        <>
          <Flex align="center" px={4}>
            <Text fontWeight="bold" fontSize="lg" mr={2}>
              {data?.name}
            </Text>
            <Tooltips data={data} />
          </Flex>
          {!!data?.description && (
            <Text fontSize="xs" color="gray.400" px={4}>
              {data.description}
            </Text>
          )}
        </>
      ) : (
        <Flex position="absolute" left={2} top={1}>
          <Tooltips data={data} />
        </Flex>
      )}

      <Box mt={4}>
        {data?.items ? (
          <Image
            w="100%"
            h="150px"
            px={`${data.padding}px`}
            src={data?.items[0]?.file?.url || '/placeholder_product.jpg'}
          />
        ) : (
          <Flex
            w="100%"
            h="150px"
            align="center"
            justify="center"
            borderWidth="3px"
            borderStyle="dashed"
            borderRadius="6px"
          >
            <Text color="gray.500">+ Adicionar</Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SingleBanner;
