import axios from 'axios';
import { ENV } from '../constants';
import refreshToken from '../utils/refreshToken';

const auth = axios.create({
  baseURL: ENV.REACT_APP_AUTH_HOST || 'https://api.shoulder.com.br',
  // baseURL: ENV.REACT_APP_AUTH_HOST || 'http://localhost',
});

auth.interceptors.response.use(
  response => response,
  async error => {
    if (
      error?.response?.status === 401 &&
      error?.response?.config?.url !== 'auth/login' &&
      error?.response?.config?.url !== 'auth/refresh-token'
    ) {
      try {
        const newAccessToken = await refreshToken();

        // eslint-disable-next-line no-param-reassign
        error.config.headers.authorization = `Bearer ${newAccessToken}`;

        return auth.request(error.config);
      } catch (e) {
        localStorage.clear();
        delete auth.defaults.headers.common.authorization;
        window.location.href = '/auth';
      }
    }
    return Promise.reject(error);
  },
);

export default auth;
