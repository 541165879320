import React from 'react';
import { Flex, Box, Image, Text, HStack, Button } from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const ProductCarouselQuery: React.FC<Props> = ({ data }) => {
  return (
    <Box
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      borderRadius="6px"
      transition="150ms ease"
      position="relative"
      p={4}
    >
      <Flex align="center" mb={3}>
        <Text fontWeight="bold" fontSize="lg" mr={2}>
          Carrossel Produtos por Queries
        </Text>
        <Tooltips data={data} />
      </Flex>
      <Flex justifyContent="space-between" mb={2} mr={1} ml={1}>
        <Button
          disabled
          mr={1}
          bg="gray.400"
          border="1px"
          borderColor="gray.5S00"
        >
          ATÉ R$ 150
        </Button>
        <Button disabled border="1px" mr={1} borderColor="gray.400">
          ATÉ R$ 250
        </Button>
        <Button disabled border="1px" borderColor="gray.500">
          ATÉ R$ 350
        </Button>
      </Flex>
      <Flex>
        <Flex
          p={4}
          mr={4}
          w={170}
          borderColor="gray.300"
          borderWidth="1px"
          flexDir="column"
          borderRadius="6px"
          flex="none"
        >
          <Flex>
            <Flex ml="auto" bg="brand.500" p={1} borderRadius="6px" mb={4}>
              <Text fontSize="xs" color="white">
                - x% OFF
              </Text>
            </Flex>
          </Flex>

          <Image
            src="/placeholder_product.jpg"
            w="70px"
            h="70px"
            alignSelf="center"
            mb={4}
          />

          <Text fontSize="sm" mb={6}>
            Título do produto
          </Text>
          <Box marginTop="auto">
            <Text textDecor="line-through" fontSize="xs" color="gray.400">
              {Number('0.00').toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              {Number('0.00').toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
            <Text fontSize="xs">
              ou Xx de{' '}
              {Number('0.00').toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
          </Box>
        </Flex>
        <Flex
          p={4}
          w={170}
          borderColor="gray.300"
          borderWidth="1px"
          flexDir="column"
          borderRadius="6px"
          flex="none"
        >
          <Flex>
            <Flex ml="auto" bg="brand.500" p={1} borderRadius="6px" mb={4}>
              <Text fontSize="xs" color="white">
                - x% OFF
              </Text>
            </Flex>
          </Flex>

          <Image
            src="/placeholder_product.jpg"
            w="70px"
            h="70px"
            alignSelf="center"
            mb={4}
          />

          <Text fontSize="sm" mb={6}>
            Título do produto
          </Text>
          <Box marginTop="auto">
            <Text textDecor="line-through" fontSize="xs" color="gray.400">
              {Number('0.00').toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              {Number('0.00').toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
            <Text fontSize="xs">
              ou Xx de{' '}
              {Number('0.00').toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProductCarouselQuery;
