import React, { useContext, useEffect, useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  FormLabel,
  FormControl,
  ModalBody,
  useToast,
  VStack,
  Switch,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import Input from '../../Form/Input';
import {
  CREATE_GROUP_ELEMENT,
  UPDATE_GROUP_ELEMENT,
} from '../../../graphQL/mutations';
import { GET_PAGE_DATA } from '../../../graphQL/queries';
import context from '../context';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: (item: any) => void;
  onEdited?: (item: any) => void;
  onDelete?: () => void;
  Item?: null | any;
}

interface FormData {
  name: string;
  redirect_value?: string;
  type?: boolean;
}

const createFormData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});

const ItemForm: React.FC<Props> = ({
  onClose,
  isOpen,
  Item,
  onCreated,
  onEdited,
}) => {
  const { formState, handleSubmit, register, setValue, reset } = useForm({
    resolver: yupResolver(createFormData),
  });

  const [createGroupElement] = useMutation(CREATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const [updateGroupElement] = useMutation(UPDATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const { pageGroup, element } = useContext(context);

  const toast = useToast();
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [isDestaque, setIsDestaque] = useState(false);

  useEffect(() => {
    if (!Item) return;
    setValue('name', Item.name);
    setValue('redirect_value', Item.redirect_value);
    setIsDestaque(Item?.type === '1');
  }, [Item, setValue]);
  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      const { ...rest } = data;
      setLoading(true);
      if (!Item) {
        const body: any = {};
        body.name = data.name;
        body.page_group_id = pageGroup?.id;
        body.element_id = element?.id;

        if (data.type) {
          body.redirect_value = data?.redirect_value;
          body.type = '1';
        } else {
          body.type = '0';
        }

        const groupNewElement = await createGroupElement({
          variables: {
            createGroupElementInput: body,
          },
        });

        if (onCreated) onCreated(groupNewElement?.data?.createGroupElement);

        toast({
          title: 'Sucesso',
          description: 'Item criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else {
        const body: any = {};
        body.id = Item.id;
        body.name = data.name;
        body.page_group_id = pageGroup?.id;
        body.element_id = element?.id;

        if (data?.type) {
          body.redirect_value = data?.redirect_value;
          body.type = '1';
        } else {
          body.type = '0';
        }

        const groupNewElement = await updateGroupElement({
          variables: {
            updateGroupElementInput: body,
          },
        });
        if (onEdited) onEdited(groupNewElement?.data?.updateGroupElement);
        toast({
          title: 'Sucesso',
          description: 'Item editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      reset();
      onClose();
      setLoading(false);
    }
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent maxH="90vh" as="form" onSubmit={handleSubmit(handleForm)}>
        <ModalHeader>Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8}>
            <Input
              isRequired
              label="Titulo"
              error={errors.name}
              {...register('name')}
            />
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="type" mb="0">
                Destaque
              </FormLabel>
              <Switch
                id="type"
                size="lg"
                colorScheme="brand"
                isChecked={isDestaque}
                {...register('type')}
                onChange={() => setIsDestaque(state => !state)}
              />
            </FormControl>
            {isDestaque && (
              <Input
                label="Link"
                error={errors.redirect_value}
                {...register('redirect_value')}
              />
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button onClick={onClose}>Fechar</Button>
            <Button colorScheme="brand" isLoading={loading} type="submit">
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ItemForm;
