/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import {
  Flex,
  Heading,
  useToast,
  Accordion,
  useDisclosure,
  VStack,
  Skeleton,
  ExpandedIndex,
  Button,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import MenuItemModal from '../../components/MenuItemModal';
import Restricted from '../../../../components/Restricted';
import cms from '../../../../apis/cms';
import { HotsiteType, MenuItemType } from '../../types';
import MenuItem from './components/MenuItem';
import HotsiteModal from '../../components/HotsiteModal';
import HotsiteItem from './components/HotsiteItem';

const ListMenuItem: React.FC = () => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [hotsites, setHotsites] = useState<any[]>([]);
  const [subMenuItemEdit, setSubMenuItemEdit] = useState('');
  const [menuItemEdit, setMenuItemEdit] = useState<MenuItemType>();
  const [hotsiteItemEdit, setHotsiteItemEdit] = useState<MenuItemType>();
  const [activeAccordion, setActiveAccordion] = useState<ExpandedIndex>(-1);
  const [activeAccordion2, setActiveAccordion2] = useState<ExpandedIndex>(-1);

  const { location } = useParams() as any;

  async function getMenuItems() {
    try {
      setMenuItemEdit(undefined);
      setLoading(true);
      const { data } = await cms.rest.get<any>('/menu-items', {
        params: {
          location: location ?? 'menu',
          levelTree: 3,
          withFileRight: true,
          withFileLeft: true,
          withFileBanner: true,
        },
      });
      setMenuItems(data);

      const { data: hotsiteData } = await cms.rest.get<any>('/hotsites');
      setHotsites(hotsiteData);
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleMenuItemDelete(id: string) {
    if (window.confirm('Deseja realmente excluir este item de menu?')) {
      try {
        setLoading(true);
        await cms.rest.delete<any>(`/menu-items/${id}`);
        getMenuItems();

        toast({
          title: 'Sucesso',
          description: 'Item do menu excluido com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } catch (err: any) {
        toast({
          title: 'Opss!!',
          description:
            err?.response?.data?.message ||
            'Tivemos um erro, não foi possível excluir o item de menu',
          status: 'error',
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    }
  }

  async function handleHotsiteItemDelete(id: string) {
    if (window.confirm('Deseja realmente excluir este hotsite?')) {
      try {
        setLoading(true);
        await cms.rest.delete<any>(`/hotsites/${id}`);
        getMenuItems();

        toast({
          title: 'Sucesso',
          description: 'Hotsite excluido com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } catch (err: any) {
        toast({
          title: 'Opss!!',
          description:
            err?.response?.data?.message ||
            'Tivemos um erro, não foi possível excluir o hotsiteu',
          status: 'error',
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    }
  }

  const handleNestedPositioning = (posData: {
    menuItems: any[];
    menuItem_id: string;
    oldPos: number;
    newPos: number;
  }): any[] => {
    const { menuItems, menuItem_id, oldPos, newPos } = posData;

    if (menuItems.length <= 0) return [];

    const newmenuItems = [...menuItems];

    if (newmenuItems[oldPos]?.id === menuItem_id) {
      const [element] = newmenuItems.splice(oldPos, 1);
      newmenuItems.splice(newPos, 0, element);
      return newmenuItems;
    }

    return menuItems.map((menuItem: any) => ({
      ...menuItem,
      children: handleNestedPositioning({
        menuItems: menuItem?.children,
        menuItem_id,
        oldPos,
        newPos,
      }),
    }));
  };

  const onDragEnd = async (result: any) => {
    try {
      if (!result?.destination || !result?.source) return;
      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex, droppableId },
        draggableId,
      } = result;

      let newMenuItems = [...menuItems];

      if (droppableId === 'root1') {
        const [element] = newMenuItems.splice(sourceIndex, 1);
        newMenuItems.splice(destinationIndex, 0, element);
      } else {
        newMenuItems = newMenuItems.map(menuItem => ({
          ...menuItem,
          children: handleNestedPositioning({
            menuItem_id: draggableId,
            menuItems: menuItem.children,
            newPos: destinationIndex,
            oldPos: sourceIndex,
          }),
        }));
      }

      setMenuItems(newMenuItems);
      await cms.rest.patch<any>(`/menu-items/${draggableId}`, {
        position: destinationIndex,
      });
    } catch (error: any) {
      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex, droppableId },
        draggableId,
      } = result;

      let newMenuItems = [...menuItems];

      if (droppableId === 'root1') {
        const [element] = newMenuItems.splice(destinationIndex, 1);
        newMenuItems.splice(sourceIndex, 0, element);
      } else {
        newMenuItems = newMenuItems.map(menuItem => ({
          ...menuItem,
          children: handleNestedPositioning({
            menuItem_id: draggableId,
            menuItems: menuItem.children,
            newPos: sourceIndex,
            oldPos: destinationIndex,
          }),
        }));
      }
      setMenuItems(menuItems);
      toast({
        title: 'Opss!!',
        description:
          error?.message || 'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };
  useEffect(() => {
    getMenuItems();
  }, [location]);
  return (
    <Flex
      flexDir="column"
      w="100%"
      p={8}
      overflow="auto"
      bg="#F9F9F9"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      <MenuItemModal
        subMenuItemEdit={subMenuItemEdit}
        isOpen={isOpen}
        location={location}
        onClose={() => {
          onClose();
          setMenuItemEdit(undefined);
          setSubMenuItemEdit('');
        }}
        menuItemToEdit={menuItemEdit}
        // FIXME: Fazendo requisição novamente, pois necessitava retornar o novo valor para os childrens o que gerou muita complexidade para o humilde programador
        onCreated={() => getMenuItems()}
        onEdited={() => getMenuItems()}
        // onEdited={menuItem => {
        //   setMenuItems(state => {
        //     return state.map(stateMenuItem => {
        //       if (stateMenuItem.id !== menuItem.id) {
        //         return stateMenuItem;
        //       }
        //       return menuItem;
        //     });
        //   });
        // }}
        // onCreated={menuItem => {
        //   setMenuItems(state => [...state, menuItem]);
        // }}
      />
      <HotsiteModal
        isOpen={isOpen2}
        location={location}
        onClose={() => {
          onClose2();
          getMenuItems();
          setHotsiteItemEdit(undefined);
        }}
        menuItemToEdit={hotsiteItemEdit}
        onCreated={() => getMenuItems()}
        onEdited={() => getMenuItems()}
      />
      <Heading mb="48px" color="#469BFF" fontFamily="Poppins">
        Itens de Menu
      </Heading>
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex
          position="relative"
          flexDir="column"
          p={8}
          borderRadius={6}
          width="48rem"
          bg="#FFFFFF"
          boxShadow="lg"
        >
          <Droppable droppableId="root1" type="root1">
            {provided => (
              <Accordion
                allowToggle
                index={activeAccordion}
                onChange={setActiveAccordion}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {menuItems.map((menuItem: MenuItemType, index: number) => (
                  <MenuItem
                    key={menuItem?.id}
                    item={menuItem}
                    level={1}
                    renderContent={index === activeAccordion}
                    loading={loading}
                    // eslint-disable-next-line react/jsx-no-bind
                    handleMenuItemDelete={handleMenuItemDelete}
                    onOpen={onOpen}
                    setMenuItemEdit={setMenuItemEdit}
                    setSubMenuItemEdit={setSubMenuItemEdit}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </Accordion>
            )}
          </Droppable>
          {loading && (
            <VStack spacing={4}>
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
            </VStack>
          )}
        </Flex>
        <Restricted allowedPermissions={['create_user']}>
          <Button
            ml="4"
            aria-label="create_menu_item"
            rounded="md"
            textColor="#FFFFFF"
            fontFamily="Poppins"
            marginTop="4px"
            bg="#469BFF"
            width="178px"
            onClick={() => {
              setMenuItemEdit(undefined);
              onOpen();
            }}
          >
            Novo item de menu
          </Button>
        </Restricted>
      </DragDropContext>
      {/* <DragDropContext onDragEnd={onDragEnd}>
        <Flex
          marginTop="30px"
          position="relative"
          flexDir="column"
          p={8}
          borderRadius={6}
          width="48rem"
          bg="#FFFFFF"
          boxShadow="lg"
        >
          <Droppable droppableId="root1" type="root1">
            {provided => (
              <Accordion
                allowToggle
                index={activeAccordion2}
                onChange={setActiveAccordion2}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {hotsites.map((hotsiteItem: HotsiteType, index: number) => (
                  // eslint-disable-next-line react/jsx-no-undef
                  // <Text>oi</Text>
                  <HotsiteItem
                    key={hotsiteItem?.id}
                    item={hotsiteItem}
                    level={1}
                    renderContent={index === activeAccordion}
                    loading={loading}
                    // eslint-disable-next-line react/jsx-no-bind
                    handleMenuItemDelete={handleHotsiteItemDelete}
                    onOpen={onOpen2}
                    setMenuItemEdit={setHotsiteItemEdit}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </Accordion>
            )}
          </Droppable>
          {loading && (
            <VStack spacing={4}>
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
              <Skeleton w="100%" h="75px" borderRadius="6px" />
            </VStack>
          )}
        </Flex>
        <Restricted allowedPermissions={['create_user']}>
          <Button
            ml="4"
            aria-label="create_menu_item"
            rounded="md"
            textColor="#FFFFFF"
            fontFamily="Poppins"
            marginTop="4px"
            bg="#469BFF"
            width="178px"
            onClick={() => {
              setMenuItemEdit(undefined);
              onOpen2();
            }}
          >
            Novo Hotsite
          </Button>
        </Restricted>
      </DragDropContext> */}
    </Flex>
  );
};

export default ListMenuItem;
