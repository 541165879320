import { FC } from 'react';
import useAuth from '../../hooks/useAuth';

interface IProps {
  allowedPermissions: string[];
}

const Restricted: FC<IProps> = ({ children, allowedPermissions }) => {
  const { permissions } = useAuth();

  const userIsAllowed = allowedPermissions.some(permission =>
    permissions.includes(permission),
  );

  if (!userIsAllowed) return null;

  return <>{children}</>;
};

export default Restricted;
