type Env = {
  REACT_APP_AUTH_HOST: string;
  REACT_APP_CMS_GRAPHQL_HOST: string;
  REACT_APP_CMS_HOST: string;
};

declare global {
  interface Window {
    env: Env;
  }
}

const mainEnviroment =
  process.env.NODE_ENV === 'development' ? process.env : window.env;

export const ENV: Env = {
  REACT_APP_AUTH_HOST:
    mainEnviroment.REACT_APP_AUTH_HOST || 'https://api.shoulder.com.br',
  REACT_APP_CMS_GRAPHQL_HOST:
    mainEnviroment.REACT_APP_CMS_GRAPHQL_HOST ||
    'https://hmlcmsshoulder.corebiz.ag/graphql',
  REACT_APP_CMS_HOST:
    mainEnviroment.REACT_APP_CMS_HOST || 'https://hmlcmsshoulder.corebiz.ag',
};
