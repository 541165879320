import React, { useEffect, useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import {
  IconButton,
  Text,
  Box,
  Tr,
  Td,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { REMOVE_GROUP_ELEMENT } from '../../../graphQL/mutations';
import { GET_PAGE_DATA } from '../../../graphQL/queries';

interface Props {
  item: any;
  onDelete?: (itemId: any) => void;
  onEditClick?: (item: any) => void;
}

const Link: React.FC<Props> = ({ item, onDelete, onEditClick }) => {
  const toast = useToast();

  const [removeGroupElement, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_GROUP_ELEMENT, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const handleItemDelete = async (itemId: string) => {
    try {
      if (window.confirm('Deseja realmente excluir esse item!')) {
        await removeGroupElement({
          variables: {
            id: itemId,
          },
        });
        toast({
          title: 'Sucesso!',
          description: 'Página excluída com sucesso',
          status: 'success',
          position: 'top-right',
        });
        if (onDelete) {
          onDelete(itemId);
        }
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    if (removeError) {
      toast({
        title: 'Opss!!',
        description:
          removeError?.message || 'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, []);

  return (
    <Tr>
      <Td>
        <Box>
          <Text>{item?.name}</Text>
        </Box>
      </Td>
      <Td>
        <Box>
          <Text>{item?.type === '1' && item?.redirect_value}</Text>
        </Box>
      </Td>
      <Td>
        <HStack spacing={3}>
          <IconButton
            aria-label="edit-page"
            rounded="md"
            icon={<FaPen size={10} />}
            size="xs"
            colorScheme="green"
            isLoading={removeLoading}
            onClick={() => {
              if (onEditClick) {
                onEditClick(item);
              }
            }}
          />
          <IconButton
            aria-label="delete-page"
            rounded="md"
            icon={<FaTrash size={10} />}
            size="xs"
            colorScheme="red"
            isLoading={removeLoading}
            onClick={() => {
              handleItemDelete(item?.id);
            }}
          />
        </HStack>
      </Td>
    </Tr>
  );
};

export default Link;
