import React, { FC } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import ListMenuItem from './pages';

const EditableText: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={ListMenuItem} />
    </Switch>
  );
};

export default EditableText;
