import React, { useEffect, useContext, useState } from 'react';
import {
  Flex,
  AspectRatio,
  useDisclosure,
  Tooltip,
  IconButton,
  useToast,
  Image,
} from '@chakra-ui/react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { AiFillEdit } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import ItemForm from './ItemForm';
import context from '../context';
import { REMOVE_GROUP_ELEMENT } from '../../../graphQL/mutations';
import { GET_PAGE_DATA } from '../../../graphQL/queries';

const List: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [items, setItems] = useState<any[]>([]);
  const [itemToEdit, setItemToEdit] = useState<any | null>(null);
  const { pageGroup, page } = useContext(context);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [removeGroupElement] = useMutation(REMOVE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  async function deleteVideo(id: string) {
    setLoading(true);
    try {
      // eslint-disable-next-line no-alert
      if (window.confirm('Deseja realmente excluir esse item!')) {
        await removeGroupElement({
          variables: {
            id,
          },
        });
        toast({
          title: 'Sucesso!',
          description: 'Vídeo excluído com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setItems(pageGroup?.items);
  }, [pageGroup?.items]);
  return (
    <Flex flexDir="column" w="100%" p={8} overflow="auto">
      <ItemForm
        isOpen={isOpen}
        Item={itemToEdit}
        onClose={() => {
          onClose();
          setItemToEdit(null);
        }}
        onCreated={(item: any) => {
          setItems(state => [...state, item]);
        }}
        onEdited={(item: any) => {
          setItems((state: any) => {
            return state.map((stateItem: any) => {
              if (stateItem.id !== item.id) {
                return stateItem;
              }
              return item;
            });
          });
        }}
      />
      {items?.length ? (
        items.map((item: any) => (
          <>
            <AspectRatio maxW="150px" ratio={4 / 3} key={item.id}>
              <Image src="/default-thumbnail.png" />
            </AspectRatio>
            <Flex key={item.id}>
              <Tooltip label="Alterar Vídeo">
                <IconButton
                  rounded="md"
                  m={1}
                  aria-label="edit-video"
                  icon={<AiFillEdit size="22px" />}
                  size="sm"
                  colorScheme="green"
                  isLoading={loading}
                  onClick={() => {
                    setItemToEdit(item);
                    onOpen();
                  }}
                />
              </Tooltip>
              <Tooltip label="Excluir Vídeo">
                <IconButton
                  m={1}
                  aria-label="delete-video"
                  rounded="md"
                  icon={<FaTrash size={10} />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => deleteVideo(item?.id)}
                />
              </Tooltip>
            </Flex>
          </>
        ))
      ) : (
        <IconButton
          variant="ghost"
          aria-label="add-video"
          borderStyle="dotted"
          borderWidth={4}
          borderRadius={8}
          h="150px"
          w="100%"
          alignItems="center"
          justifyContent="center"
          onClick={onOpen}
          icon={<IoAddCircleOutline size={40} color="gray.300" />}
        />
      )}
    </Flex>
  );
};

export default List;
