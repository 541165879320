import { FC } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import List from './pages/List';

const Screen: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={List} />
    </Switch>
  );
};

export default Screen;
