import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

interface Props {
  data: any;
  phoneWidth: number;
}

const Description: React.FC<Props> = ({ data }) => {
  return (
    <Box
      borderRadius="15px"
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      transition="150ms ease"
      position="relative"
      p={3}
      margin="5px 0 5px 0"
    >
      <>
        <Flex direction="column" textAlign="center">
          <Text fontWeight="bold" fontSize="md" color="blackAlpha.800">
            {data.name}
          </Text>
          <Text fontWeight="black" fontSize="md" color="blackAlpha.700" s>
            {data.description}
          </Text>
        </Flex>
      </>
    </Box>
  );
};

export default Description;
