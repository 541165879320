import React from 'react';
import BannerCarouselBody from './BannerCarouselBody';
import UniqueBannerBody from './UniqueBannerBody';
import MosaicBannerCarousel from './MosaicBannerCarousel';
import MosaicBannerCarouselVertical from './MosaicBannerCarouselVertical';
import Description from './Description';
import Colapse from './Colapse';
import Focus from './Focus';
import RedirectMenu from './RedirectMenu';
import MediaYoutube from './MediaYoutube';
import Topic from './Topic';
import Card from './Card';
import ProductTable from './ProductTable';
import InfiniteScroll from './InfiniteScroll';
import UniqueProduct from './UniqueProduct';
import ProductCarouselQuery from './ProductCarouselQuery';
import VtexCollection from './VtexCollection';
import ProductsCarrossel from './ProductsCarrossel';
import StopwatchBody from './StopwatchBanner';
import LocationBody from './LocationBanner';
import CheckeredBannerCarousel from './CheckeredBannerCarousel';

const components: { [key: string]: React.FC } = {
  'carrossel-de-banner-pequeno': BannerCarouselBody,
  'carrossel-de-banner-medio': BannerCarouselBody,
  'carrossel-de-banner': BannerCarouselBody,
  'banner-unico': UniqueBannerBody,
  'carrossel-de-icones': BannerCarouselBody,
  'colecao-vtex': VtexCollection,
  'carrossel-de-produtos': ProductsCarrossel,
  'carrossel-de-produtos-query': ProductCarouselQuery,
  'carrossel-de-banner-mosaico': MosaicBannerCarousel,
  'carrossel-de-banner-mosaico-vertical': MosaicBannerCarouselVertical,
  'carrossel-de-banner-quadriculado': CheckeredBannerCarousel,
  description: Description,
  focus: Focus,
  colapse: Colapse,
  'redirect-menu': RedirectMenu,
  'media-youtube': MediaYoutube,
  topic: Topic,
  card: Card,
  'banner-unico-countdown': UniqueBannerBody,
  'table-products': ProductTable,
  'infinite-scroll': InfiniteScroll,
  'unique-product': UniqueProduct,
  'banner-cronometro': StopwatchBody,
  'banner-localizacao': LocationBody,
};

export default components;
