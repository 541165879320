import React, { useEffect, useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  VStack,
  SimpleGrid,
  IconButton,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import Select from '../Form/Select';
import auth from '../../apis/auth';
import Input from '../Form/Input';
import isValidPassword from '../../utils/isValidPassword';
import isValidCpf from '../../utils/isValidCpf';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: (user: any) => void;
  onEdited?: (user: any) => void;
  userToEdit?: null | any;
}

interface FormData {
  name?: string;
  email: string;
  password?: string;
  cpf: string;
  passwordConfirmation?: string;
  roles: any[];
}

const updateformData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  email: yup
    .string()
    .email('Deve ser um E-mail válido')
    .required('E-mail é obrigatório'),
  cpf: yup
    .string()
    .test('cpf', 'CPF é invalido', cpf => {
      if (!cpf) {
        return true;
      }
      return isValidCpf(cpf || '');
    })
    .nullable(true),
  roles: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .required('Escolha pelo menos um cargo'),
});

const createFormData = yup.object().shape({
  email: yup
    .string()
    .email('Deve ser um E-mail válido')
    .required('E-mail é obrigatório'),
  password: yup
    .string()
    .test('password', 'Senha é obrigatório', value => {
      if (typeof value === 'undefined') return true;
      if (typeof value !== 'string' || !value) return false;
      return true;
    })
    .test(
      'password',
      'Senha deve ter mais de 8 caracteres, pelo menos uma letra minúscula e maiúscula e caracter especial',
      value => {
        if (typeof value === 'undefined') return true;
        if (typeof value !== 'string' || !value) return false;
        return isValidPassword(value);
      },
    ),
  cpf: yup
    .string()
    .test('cpf', 'CPF é invalido', cpf => {
      if (!cpf) {
        return true;
      }
      return isValidCpf(cpf || '');
    })
    .nullable(true),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais')
    .required('Campo obrigatório'),
  roles: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .required('Escolha pelo menos um cargo'),
});

const UserModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCreated,
  onEdited,
  userToEdit,
}) => {
  const toast = useToast();
  const { formState, handleSubmit, register, control, setValue, reset } =
    useForm({
      resolver: yupResolver(userToEdit ? updateformData : createFormData),
    });

  const { errors } = formState;

  const [rolesLoading, setRolesLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);

  useEffect(() => {
    if (!userToEdit) return;
    const userRoles = userToEdit?.roles.map((userRole: any) =>
      roles.find(role => role.slug === userRole),
    );

    setValue('name', userToEdit.name);
    setValue('email', userToEdit.email);
    setValue('cpf', userToEdit.cpf);
    setValue(
      'roles',
      userRoles.map((role: any) => ({
        value: role.id,
        label: role.slug,
      })) || [],
    );
  }, [userToEdit, setValue, roles]);

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);
      const { roles: userRoles, ...userData } = data;

      const dataBody: any = {
        ...userData,
      };
      if (userToEdit) {
        if (userData.cpf !== null) {
          dataBody.cpf = userData.cpf;
        }

        const { data: user } = await auth.patch<any>(
          `users/${userToEdit.id}`,
          dataBody,
        );

        if (userRoles) {
          Promise.all(
            userRoles.map(async role => {
              await auth.post(`acl/assign/roles/${role.value}/user/${user.id}`);
            }),
          );
        }

        if (userRoles.length === 0 && userToEdit?.roles.length > 0) {
          const rolesToRemove = roles.find(
            role => role.name.toLowerCase() === userToEdit?.roles[0],
          );

          Promise.all(
            rolesToRemove.map(async (roleToRemove: any) => {
              await auth.delete(
                `acl/assign/roles/${roleToRemove.id}/user/${user.id}`,
              );
            }),
          );
        }

        if (userToEdit?.roles.length > 0) {
          const unassignedRoles = userToEdit?.roles
            ?.filter((oldRole: any) => {
              return userRoles.find(newRole => oldRole !== newRole.label);
            })
            .map((oldRole: any) => {
              return roles.find(role => role.slug === oldRole);
            });

          Promise.all(
            unassignedRoles.map(async (unassignedRole: any) => {
              await auth.delete(
                `acl/assign/roles/${unassignedRole.id}/user/${user.id}`,
              );
            }),
          );
        }

        if (onEdited) {
          onEdited({
            ...user,
            roles: userRoles.map(userRole => userRole.label),
          });
        }

        toast({
          title: 'Sucesso',
          description: 'Usuário editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else {
        const { data: user } = await auth.post<any>('users', {
          ...userData,
          cpf: userData.cpf.length ? userData.cpf : null,
        });

        Promise.all(
          userRoles.map(async role => {
            await auth.post(`acl/assign/roles/${role.value}/user/${user.id}`);
          }),
        );

        if (onCreated) {
          onCreated({
            ...user,
            roles: userRoles.map(userRole => userRole.label),
          });
        }

        toast({
          title: 'Sucesso',
          description: 'Usuário criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }

      reset();
      onClose();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    async function getRoles() {
      try {
        setRolesLoading(true);
        const { data } = await auth.get<any>('acl/roles');
        setRoles(data);
      } catch (err: any) {
        toast({
          title: 'Opss!!',
          description:
            err?.response?.data?.message ||
            'Tivemos um erro, tente novamente mais tarde',
          status: 'error',
          position: 'top-right',
        });
      } finally {
        setRolesLoading(false);
      }
    }
    getRoles();
  }, [toast]);

  return (
    <Modal
      onClose={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        bgColor="#FBFBFB"
        maxH="90vh"
        as="form"
        onSubmit={handleSubmit(handleForm)}
      >
        <ModalHeader
          color="#469BFF"
          fontFamily="Poppins"
          width="100%"
          font-size="20px"
          line-height="30px"
          height="30px"
        >
          Novo usuário
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8}>
            <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
              <Input
                placeholder="Nome"
                variant="outline"
                _placeholder={{ color: '#000000' }}
                error={errors.name}
                {...register('name')}
              />
              <Input
                placeholder="CPF"
                _placeholder={{ color: '#000000' }}
                error={errors.cpf}
                {...register('cpf')}
              />
              <Input
                isRequired
                placeholder="E-mail"
                _placeholder={{ color: '#000000' }}
                error={errors.email}
                {...register('email')}
              />
              <Select
                isRequired
                name="roles"
                control={control}
                placeholder="Cargos"
                error={errors.roles}
                isMulti
                options={roles.map((role: any) => ({
                  label: role.slug,
                  value: role.id,
                }))}
              />
              {!userToEdit && (
                <>
                  <Input
                    isRequired
                    type="password"
                    placeholder="Senha"
                    _placeholder={{ color: '#000000' }}
                    error={errors.password}
                    {...register('password')}
                  />
                  <Input
                    placeholder="Confirme sua senha"
                    _placeholder={{ color: '#000000' }}
                    type="password"
                    error={errors.passwordConfirmation}
                    InputRightElement={
                      <IconButton
                        variant="ghost"
                        colorScheme="transparent"
                        aria-label="Esconder ou mostrar a senha"
                      />
                    }
                    {...register('passwordConfirmation')}
                  />
                </>
              )}
            </SimpleGrid>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              onClick={onClose}
              backgroundColor="#FBFBFB"
              textColor="#068FDD"
              fontFamily="Poppins"
              border="2px solid #068FDD"
              _placeholder={{ color: '#000000' }}
            >
              Fechar
            </Button>
            <Button
              backgroundColor="#068FDD"
              fontFamily="Poppins"
              fontSize="normal"
              font-size="14px"
              isLoading={loading || rolesLoading}
              type="submit"
              textColor="#FFFFFF"
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserModal;
