import React, { useEffect, useState } from 'react';
import {
  Flex,
  Table,
  Heading,
  Thead,
  Tr,
  Th,
  Tbody,
  useToast,
  Spinner,
  Checkbox,
  useDisclosure,
  Button,
  Box,
} from '@chakra-ui/react';
import auth from '../../../../apis/auth';
import Item from './Item';
import Pagination from '../../../../components/Pagination';
import Input from '../../../../components/Form/Input';
import useDebounce from '../../../../hooks/useDebounce';
import UserModal from '../../../../components/UserModal';
import Restricted from '../../../../components/Restricted';

const List: React.FC = () => {
  const toast = useToast();
  const { debounce } = useDebounce();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [userToEdit, setUserToEdit] = useState<any | null>(null);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [withRole, setWithRole] = useState(false);

  const per_page = 7;

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        const { data } = await auth.get<any>('users', {
          params: {
            page,
            per_page,
            email,
            cpf,
            only_users_with_roles: withRole,
            include_acl: true,
          },
        });
        setUsers(data.data);
        setTotal(data.data.length);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getUsers();
  }, [toast, withRole, page, per_page, email, cpf]);

  return (
    <Flex
      flexDir="column"
      w="100%"
      p={8}
      overflow="auto"
      backgroundColor="#F9F9F9"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      <Heading mb="48px" color="#469BFF" fontFamily="Poppins">
        Usuários
      </Heading>
      <Flex margin="0 0 40px 0">
        <Input
          name="email"
          bgColor="gray.200"
          placeholder="Filtrar e-mail"
          width="199px"
          borderRadius="8px"
          backgroundColor="#FFFFFF"
          _placeholder={{ color: '#000000' }}
          onChange={e => {
            setLoading(true);
            debounce(() => {
              setEmail(e.target.value);
              setPage(1);
            }, 250);
          }}
        />
        <Input
          name="cpf"
          bgColor="gray.200"
          placeholder="Filtrar CPF"
          width="199px"
          marginLeft="4"
          borderRadius="8px"
          backgroundColor="#FFFFFF"
          _placeholder={{ color: '#000000' }}
          onChange={e => {
            setLoading(true);
            debounce(() => {
              setEmail(e.target.value);
              setPage(1);
            }, 250);
          }}
        />
        <Restricted allowedPermissions={['create_user']}>
          <Button
            ml="4"
            aria-label="create_user"
            line-height="21px"
            textAlign="center"
            bf="#469BFF"
            colorScheme="brand"
            fontFamily="Poppins"
            fontWeight="300"
            onClick={() => onOpen()}
          >
            Novo usuário
          </Button>
        </Restricted>
      </Flex>
      <Flex position="relative" flexDir="column" bg="#F9F9F9" p={8}>
        <Flex align="center" ml={4} mb={6}>
          <Checkbox
            mr={2}
            checked={withRole}
            onChange={() => {
              setPage(1);
              setWithRole(state => !state);
            }}
          >
            Somente Usuários com Cargo
          </Checkbox>
        </Flex>
        <Table variant="simple" colorScheme="blackAlpha">
          <Box border="1px solid #068FDD" borderRadius="14px" width="100%">
            <Thead
              border="1px solid #068FDD"
              borderRadius="14px"
              bg="#F9FDFF"
              display="flex"
              boxSizing="border-box"
              flex="none"
              width="100%"
              overflow="hidden"
            >
              <Tr>
                <Th
                  textColor="#469BFF"
                  position="relative"
                  fontFamily="Poppins"
                >
                  E-mail
                </Th>
                <Th
                  textColor="#469BFF"
                  position="relative"
                  top="9%"
                  right="-78%"
                  fontFamily="Poppins"
                >
                  CPF
                </Th>
                <Th
                  textColor="#469BFF"
                  position="relative"
                  top="9%"
                  right="-108%"
                  fontFamily="Poppins"
                >
                  Cargo
                </Th>
                <Th
                  textColor="#469BFF"
                  position="relative"
                  top="9%"
                  right="-174%"
                  fontFamily="Poppins"
                >
                  Ações
                </Th>
              </Tr>
            </Thead>
          </Box>
          <Tbody>
            {users.map(user => (
              <Item
                user={user}
                key={user.id}
                onDelete={id => {
                  setUsers(state => state.filter(oldUser => oldUser.id !== id));
                }}
                onEditClick={(data: any) => {
                  setUserToEdit(data);
                  onOpen();
                }}
              />
            ))}
          </Tbody>
        </Table>
        <Pagination
          ml="auto"
          mt={6}
          disabled={loading}
          total={total}
          current={page}
          perPage={per_page}
          onPageChange={p => {
            setPage(p);
          }}
        />
        {loading && (
          <Flex
            position="absolute"
            align="center"
            justify="center"
            top="50%"
            left="50%"
          >
            <Spinner color="brand.500" size="xl" />
          </Flex>
        )}
        <UserModal
          isOpen={isOpen}
          userToEdit={userToEdit}
          onClose={() => {
            onClose();
            setUserToEdit(null);
          }}
          onCreated={user => {
            setUsers(state => [...state, user]);
          }}
          onEdited={user => {
            setUsers(state => {
              return state.map(stateUser => {
                if (stateUser.id !== user.id) return stateUser;
                return user;
              });
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default List;
