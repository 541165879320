import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  VStack,
  useToast,
  IconButton,
  useTheme,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useMutation } from '@apollo/client';
import BannerForm from './BannerForm';
import Banner from './Banner';
import { UPDATE_GROUP_ELEMENT_POSITION } from '../../../graphQL/mutations';
import context from '../context';
import { GET_PAGE_DATA } from '../../../graphQL/queries';

const Banners: React.FC = () => {
  const { pageGroup } = useContext(context);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [updateGroupElementPosition, { loading, error }] = useMutation(
    UPDATE_GROUP_ELEMENT_POSITION,
    {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    },
  );

  const [clickedBanner, setClickedBanner] = useState<any | undefined>(
    undefined,
  );

  const theme = useTheme();

  useEffect(() => {
    if (error) {
      toast({
        title: 'Opss!!',
        description:
          error?.message || 'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, [error, toast]);

  const onDragEnd = async (result: DropResult) => {
    if (
      !result?.destination ||
      !result?.source ||
      result.destination === result.source
    )
      return;
    const { destination, draggableId } = result;
    await updateGroupElementPosition({
      variables: {
        updateGroupElementInput: {
          id: draggableId,
          position: destination.index,
        },
      },
    });
  };

  return (
    <>
      <VStack
        spacing={8}
        maxHeight="70vh"
        overflow="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.colors.gray['500'],
            borderRadius: '24px',
          },
          '::-webkit-scrollbar-track:hover': {
            background: theme.colors.gray['300'],
          },
        }}
      >
        {loading ? (
          <>
            <Skeleton h="150px" w="100%" />
            <Skeleton h="150px" w="100%" />
            <Skeleton h="150px" w="100%" />
            <Skeleton h="150px" w="100%" />
          </>
        ) : (
          <>
            {pageGroup?.items?.length < 1 && (
              <IconButton
                variant="ghost"
                aria-label="add-banner"
                borderStyle="dotted"
                borderWidth={4}
                borderRadius={8}
                h="150px"
                w="100%"
                alignItems="center"
                justifyContent="center"
                onClick={onOpen}
                icon={
                  <IoAddCircleOutline
                    size={40}
                    color={theme.colors.gray['300']}
                  />
                }
              />
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="banners">
                {provided => (
                  <VStack
                    spacing={8}
                    w="100%"
                    flexDirection="column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {pageGroup?.items?.map((banner: any, index: number) => (
                      <Banner
                        key={banner?.id}
                        banner={banner}
                        index={index}
                        onClick={(b: any) => {
                          setClickedBanner(b);
                          onOpen();
                        }}
                      />
                    ))}
                    {provided.placeholder}
                  </VStack>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </VStack>
      <BannerForm
        isOpen={isOpen}
        banner={clickedBanner}
        onCreated={onClose}
        onClose={() => {
          setClickedBanner(undefined);
          onClose();
        }}
        onDeleted={() => {
          setClickedBanner(undefined);
          onClose();
        }}
        onUpdated={() => {
          setClickedBanner(undefined);
          onClose();
        }}
      />
    </>
  );
};

export default Banners;
