const redirectTypes = [
  {
    label: 'Produto',
    value: 'product',
  },
  {
    label: 'Categoria',
    value: 'category',
  },
  {
    label: 'Coleção',
    value: 'collection',
  },
  {
    label: 'Coleção Look',
    value: 'collection-look',
  },
  {
    label: 'URL',
    value: 'url',
  },
  {
    label: 'Linx',
    value: 'linx-shelf',
  },
  {
    label: 'Tela dinâmica',
    value: 'app_screen',
  },
  {
    label: 'Webview',
    value: 'webview',
  },
  {
    label: 'Query Vtex',
    value: 'query',
  },
  {
    label: 'Screen on app',
    value: 'screen_on_app',
  },
];

export default redirectTypes;
