import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

interface Props {
  icon: IconType;
  title: string;
  value: string;
  active?: boolean;
}

const Card: React.FC<Props> = ({ icon, title, value, active }) => {
  return (
    <Button
      justifyContent="flex-start"
      py={12}
      borderWidth={1}
      borderColor="gray.300"
      colorScheme={active ? 'brand' : 'gray'}
    >
      <Icon as={icon} w={25} h={25} mr={6} />
      <Flex flexDir="column" align="flex-start">
        <Text mb={1}>{value}</Text>
        <Text fontSize="xs" fontWeight="normal">
          {title}
        </Text>
      </Flex>
    </Button>
  );
};

export default Card;
