import React, { useState, ChangeEvent } from 'react';
import { Button, useToast, Flex } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Upload from '../../../components/Form/Upload';
import cms from '../../../apis/cms';
import { confImagesNamesTranslate } from '../../../utils/translates';

interface FormData {
  file: FileList;
}

interface Props {
  title: string;
  nameImage: string;
  url?: string;
}

const ConfigImage: React.FC<Props> = ({ title, nameImage, url }) => {
  const toast = useToast();
  const { register, handleSubmit } = useForm({});
  const [loading, setLoading] = useState(false);
  const [edit, setEedit] = useState(false);

  function checkImageValue(event: ChangeEvent<HTMLInputElement>) {
    if (event.target?.files?.[0]) {
      setEedit(true);
    } else {
      setEedit(false);
    }
  }

  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      setLoading(true);
      if (data[nameImage][0]) {
        const multipart = new FormData();
        multipart.append('file', data[nameImage][0]);
        const { data: uploadDataFromApi } = await cms.rest.post<any>(
          '/upload',
          multipart,
        );

        await cms.rest.patch<any>(`/config-images/${nameImage}`, {
          file_id: uploadDataFromApi.id,
        });

        toast({
          title: 'Sucesso!',
          description: 'Imagem salva com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else {
        throw new Error('Imagem não encontrada');
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      flexDir="column"
      mt={edit ? 10 : 5}
      padding="10px 0"
      marginRight="60px"
    >
      <Upload
        label={
          confImagesNamesTranslate.find(
            (confImageLabelTranslate: any) =>
              confImageLabelTranslate.slug === title,
          )?.translate || title
        }
        acceptedFileTypes="image/*"
        alignSelf="center"
        url={url}
        {...register(nameImage)}
        onFilesChange={(event: ChangeEvent<HTMLInputElement>) =>
          checkImageValue(event)
        }
      />
      {edit ? (
        <Button
          mt={10}
          isLoading={loading}
          w="90%"
          colorScheme="brand"
          onClick={handleSubmit(handleForm)}
        >
          Salvar
        </Button>
      ) : (
        ''
      )}
    </Flex>
  );
};

export default ConfigImage;
