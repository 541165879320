import React, { useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import {
  IconButton,
  Text,
  Box,
  Tr,
  Td,
  HStack,
  Tag,
  useToast,
} from '@chakra-ui/react';
import auth from '../../../../apis/auth';
import Restricted from '../../../../components/Restricted';

interface Props {
  user: any;
  onDelete?: (userId: any) => void;
  onEditClick?: (user: any) => void;
}

const Item: React.FC<Props> = ({ user, onDelete, onEditClick }) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleUserDelete = async (userId: string) => {
    try {
      setLoading(true);
      if (!window.confirm('Tem certeza que deseja excluir este usuário?')) {
        return;
      }
      await auth.delete(`users/${userId}`);
      if (onDelete) {
        onDelete(userId);
      }
      toast({
        title: 'Sucesso!',
        description: 'Usuário deletado com sucesso!',
        status: 'success',
        position: 'top-right',
      });
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Ocorreu um erro ao carregas a telas, tente novamente mais tarde!',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      border="1px solid #FFFFFF"
      borderRadius="14px"
      bg="#FFFFFF"
      width="100%"
      height="61px"
      margin="10px"
    >
      <Tr bg="#FFFFFF" display="flex" height="40px" marginTop="10px">
        <Td>
          <Box>
            <Text position="relative" right="20px">
              {user?.email}
            </Text>
            {!!user?.name && (
              <Text fontSize="sm" color="gray.400">
                {user?.name}
              </Text>
            )}
          </Box>
        </Td>
        <Td>
          <Box>
            <Text position="absolute" right="53%">
              {user.cpf
                ? user.cpf.replace(
                    /(^\d{3})(\d{3})(\d{3})(\d{2}$)/,
                    '$1.$2.$3-$4',
                  )
                : '-'}
            </Text>
          </Box>
        </Td>
        <Td>
          <HStack position="absolute" right="40.5%">
            {user?.roles?.length > 0 ? (
              user?.roles?.map((role: any) => (
                <Tag variant="solid" colorScheme="blue" key={role} size="sm">
                  {role}
                </Tag>
              ))
            ) : (
              <Tag variant="solid" colorScheme="blue" size="sm">
                App
              </Tag>
            )}
          </HStack>
        </Td>
        <Td>
          <HStack spacing={3}>
            <Restricted allowedPermissions={['update_user']}>
              <IconButton
                color="#CECECE"
                aria-label="edit-user"
                rounded="md"
                icon={<FaPen size={17} />}
                size="xs"
                colorScheme="#FFFFFF"
                position="absolute"
                right="10%"
                isLoading={loading}
                onClick={() => {
                  if (onEditClick) {
                    onEditClick(user);
                  }
                }}
              />
            </Restricted>
            <Restricted allowedPermissions={['delete_user']}>
              <IconButton
                color="#FF6565"
                aria-label="delete-user"
                rounded="md"
                icon={<FaTrash size={17} />}
                size="xs"
                colorScheme="#FFFFFF"
                position="absolute"
                right="6.25%"
                isLoading={loading}
                onClick={() => {
                  handleUserDelete(user.id);
                }}
              />
            </Restricted>
          </HStack>
        </Td>
      </Tr>
    </Box>
  );
};

export default Item;
