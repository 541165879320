import { ComponentType, FC } from 'react';
import { RouteProps, Route as ReactDOMRoute, Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Dashboard from '../layouts/Dashboard';

interface ExtendedRouteProps extends RouteProps {
  isPrivate?: boolean;
  isDashboard?: boolean;
  component: ComponentType;
}

const Route: FC<ExtendedRouteProps> = ({
  isPrivate = false,
  isDashboard,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  const render = () => {
    if (isPrivate === !!user) {
      if (isDashboard) {
        return (
          <Dashboard>
            <Component />
          </Dashboard>
        );
      }

      return <Component />;
    }

    return <Redirect to={{ pathname: isPrivate ? '/' : 'users' }} />;
  };

  return <ReactDOMRoute render={render} {...rest} />;
};

export default Route;
