import React from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import Product from './Product';
import Tooltips from '../Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const UniqueProduct: React.FC<Props> = ({ data }) => {
  return (
    <Box
      _hover={{ bg: 'gray.400' }}
      backgroundColor="gray.100"
      borderRadius="6px"
      transition="150ms ease"
      position="relative"
      p={4}
    >
      {data?.name ? (
        <>
          <Flex align="center">
            <Text fontWeight="bold" fontSize="lg" mr={2}>
              {data?.name}
            </Text>
            <Tooltips data={data} />
          </Flex>
          {!!data?.description && (
            <Text fontSize="xs" color="gray.400">
              {data.description}
            </Text>
          )}
        </>
      ) : (
        <Flex position="absolute" left={2} top={1}>
          <Tooltips data={data} />
        </Flex>
      )}

      {data?.product?.items.find(
        (item: any) => item?.itemId === data?.reference_id,
      ) ? (
        <Product
          product={data?.product.items.find(
            (item: any) => item?.itemId === data?.reference_id,
          )}
          data={data}
        />
      ) : (
        <Flex w="100%" justify="center" mt={2} mb={2} textAlign="center">
          <Text color="red" w="70%">
            Não foi encontrado nenhum item com esse ID
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default UniqueProduct;
