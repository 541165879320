/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ExpandedIndex,
  Flex,
  Image,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BsCircleFill } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import cms from '../../../../../apis/cms';
import redirectTypes from '../../../../../utils/redirectTypes';
import { MenuItemType } from '../../../types';

interface Children {
  id: string;
  name: string;
  text: string;
  isChildren?: boolean;
}

interface LpItemTypes {
  name: string;
  id: number;
  description?: string;
  isChildren?: boolean;
  text?: string;
  children?: Children[];
}
interface Props {
  item: LpItemTypes;
  level: number;
  loading: boolean;
  renderContent?: boolean;
  index: number;
  setMenuItemEdit: any;
  setSubMenuItemEdit: (id: string) => any;
  onOpen: any;
  handleMenuItemDelete: (id: string) => Promise<void>;
}

const getLpItems = async (id: number) => {
  const { data } = await cms.rest.get(`/lp-items/lp-id/${id}`);

  return data as Children[];
};

const deleteItemLp = async (id: string) => {
  await cms.rest.delete(`/lp-items/${id}`);
  location.reload();
};

const LPItem: React.FC<Props> = ({
  item,
  level,
  renderContent,
  index,
  loading,
  setSubMenuItemEdit,
  setMenuItemEdit,
  onOpen,
  handleMenuItemDelete,
}) => {
  const [activeAccordion, setActiveAccordion] = useState<ExpandedIndex>(-1);
  const [children, setChildren] = useState<Children[]>([]);
  const a: number[] = [1, 2, 3];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getChildren = async () => {
      const children_lp = (await getLpItems(item.id)) as Children[];

      for (const item of children_lp) {
        item.isChildren = true;
      }
      setChildren([...children_lp]);
    };
    getChildren();

    item.children = children;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Draggable draggableId={String(item?.id)} index={index}>
      {provided => (
        <AccordionItem
          p="7px"
          borderColor="gray.300"
          borderRadius="9px"
          border="2px solid #F9F9F9"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <h2>
            <AccordionButton bg="#F9F9F9" borderRadius="9px">
              <Box flex="1" textAlign="left">
                <Flex align="center">
                  <Text
                    margin="0 10px 0 10px"
                    position="relative"
                    fontWeight="semibold"
                    fontSize="18px"
                  >
                    {item?.name}
                  </Text>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {renderContent && (
              <>
                {item ? (
                  <Flex justify="end">
                    {!item.isChildren ? (
                      <Button
                        margin="0 5px"
                        aria-label="edit-menu-item"
                        rounded="md"
                        size="md"
                        bg="#F3F3F3"
                        textColor="#262626"
                        isLoading={loading}
                        onClick={() => {
                          onOpen();
                          setSubMenuItemEdit(String(item.id));
                          setMenuItemEdit(item);
                        }}
                      >
                        Editar item
                      </Button>
                    ) : (
                      <Button
                        margin="0 5px"
                        aria-label="edit-menu-item"
                        rounded="md"
                        size="md"
                        bg="#F3F3F3"
                        textColor="#262626"
                        isLoading={loading}
                        onClick={async () => {
                          onOpen();
                          setMenuItemEdit(item);
                        }}
                      >
                        Editar item
                      </Button>
                    )}
                    {!item.isChildren ? (
                      <Button
                        margin="0 5px"
                        aria-label="delete-menu-item"
                        rounded="md"
                        size="md"
                        bg="#FFEBF0"
                        textColor="red"
                        isLoading={loading}
                        onClick={() => {
                          handleMenuItemDelete(String(item.id));
                        }}
                      >
                        Excluir
                      </Button>
                    ) : (
                      <Button
                        margin="0 5px"
                        aria-label="delete-menu-item"
                        rounded="md"
                        size="md"
                        bg="#FFEBF0"
                        textColor="red"
                        isLoading={loading}
                        onClick={() => {
                          deleteItemLp(String(item.id));
                        }}
                      >
                        Excluir
                      </Button>
                    )}
                    {!item.isChildren && (
                      <Button
                        margin="0 5px"
                        aria-label="create-sub-menu-item"
                        rounded="md"
                        icon={<FaPlus size={10} />}
                        size="md"
                        bg="#469BFF"
                        textColor="#FFFFFF"
                        isLoading={loading}
                        onClick={async () => {
                          setSubMenuItemEdit(String(item.id));
                          setMenuItemEdit(undefined);
                          // const items = await getLpItems(item.id);
                          onOpen();
                        }}
                      >
                        Adicionar sub-lp
                      </Button>
                    )}
                  </Flex>
                ) : (
                  ''
                )}
                <Droppable
                  droppableId={String(item?.id)}
                  type={`leaf-${item?.id}`}
                >
                  {droppableProvided => (
                    <Accordion
                      allowToggle
                      w="100%"
                      p={4}
                      index={activeAccordion}
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                      onChange={setActiveAccordion}
                    >
                      {children?.map((child: any, i: number) => {
                        if (!item.isChildren) {
                          return (
                            <>
                              <LPItem
                                key={child.id}
                                item={child}
                                level={level + 1}
                                index={i}
                                renderContent={i === activeAccordion}
                                handleMenuItemDelete={handleMenuItemDelete}
                                loading={loading}
                                onOpen={onOpen}
                                setMenuItemEdit={setMenuItemEdit}
                                setSubMenuItemEdit={setSubMenuItemEdit}
                              />
                            </>
                          );
                        }

                        return 1;
                      })}
                      <Text
                        isReadOnly
                        overflow="hidden"
                        height="100%"
                        whiteSpace="pre-line"
                      >
                        {item.isChildren
                          ? item.text
                              ?.split(/IMG\((https:\/\/[^)]+)\)/g)
                              .map(text => {
                                if (text.includes('https')) {
                                  if (
                                    item.text?.split(
                                      /IMG\((https:\/\/[^)]+)\)/g,
                                    ).length &&
                                    item.text?.split(
                                      /IMG\((https:\/\/[^)]+)\)/g,
                                    ).length > 1
                                  ) {
                                    return <Image src={text} />;
                                  }
                                }
                                return <Text>{text}</Text>;
                              })
                          : ''}
                        {/* {droppableProvided.placeholder} */}
                      </Text>
                    </Accordion>
                  )}
                </Droppable>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
      )}
    </Draggable>
  );
};

export default LPItem;
