import React, { useEffect, useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import Input from '../../../../components/Form/Input';
import { CREATE_PAGE, UPDATE_PAGE } from '../../../../graphQL/mutations';
import { GET_PAGES } from '../../../../graphQL/queries';
import { constants } from '../../../../utils/constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: (page: any) => void;
  onEdited?: (page: any) => void;
  PageToEdit?: null | any;
}

interface FormData {
  name: string;
}

const createFormData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});

const DynamicPageModal: React.FC<Props> = ({ isOpen, onClose, PageToEdit }) => {
  const { formState, handleSubmit, register, setValue, reset } = useForm({
    resolver: yupResolver(createFormData),
  });

  const toast = useToast();
  const { errors } = formState;
  const [loading, setLoading] = useState(false);

  const [createPage] = useMutation(CREATE_PAGE, {
    refetchQueries: [GET_PAGES, 'GetPages'],
  });

  const [updatePage] = useMutation(UPDATE_PAGE, {
    refetchQueries: [GET_PAGES, 'GetPages'],
  });

  useEffect(() => {
    if (!PageToEdit) return;
    setValue('name', PageToEdit.name);
  }, [PageToEdit, setValue]);

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);
      if (!PageToEdit) {
        await createPage({
          variables: {
            createPageInput: {
              name: data?.name,
            },
          },
        });

        toast({
          title: 'Sucesso',
          description: 'Página criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else if (PageToEdit?.slug === constants.HOME_SLUG) {
        toast({
          title: 'Opss!!',
          description: 'Não é possível editar a página home',
          status: 'error',
          position: 'top-right',
        });
      } else {
        await updatePage({
          variables: {
            updatePageInput: {
              id: PageToEdit?.id,
              name: data?.name,
            },
          },
        });

        toast({
          title: 'Sucesso',
          description: 'Página editada com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
      reset();
      onClose();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent maxH="90vh" as="form" onSubmit={handleSubmit(handleForm)}>
        <ModalHeader>Página dinâmica</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8}>
            <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
              <Input
                isRequired
                label="Nome"
                error={errors.name}
                {...register('name')}
              />
            </SimpleGrid>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button onClick={onClose}>Fechar</Button>
            <Button colorScheme="brand" isLoading={loading} type="submit">
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DynamicPageModal;
