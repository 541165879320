import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Switch,
  Checkbox,
  HStack,
  Button,
  ModalFooter,
  useToast,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import context from '../context';
import Datepicker from '../../Form/Datepicker';
import Input from '../../Form/Input';
import {
  CREATE_PAGE_GROUP,
  REMOVE_PAGE_GROUP,
  UPDATE_PAGE_GROUP,
} from '../../../graphQL/mutations';
import { GET_PAGE_DATA } from '../../../graphQL/queries';
import Textarea from '../../Form/Textarea';
import sanitizeString from '../../../utils/sanitizeString';

interface Props {
  onCreated?: (data: any) => void;
}
interface FormData {
  name: string;
  description?: string;
  countdown: boolean;
  schedule: boolean;
  schedule_start?: Date;
  schedule_end?: Date;
  logoColor?: string;
}

const formData = yup.object().shape({
  name: yup.string().nullable(),
  description: yup.string().nullable(),
  schedule: yup.boolean(),
  countdown: yup.boolean(),
  schedule_start: yup.date().when('schedule', {
    is: true,
    then: yup.date().required('Data de início é obrigatória'),
  }),
  schedule_end: yup
    .date()
    .when(['schedule', 'schedule_start'], (schedule, schedule_start) => {
      if (!schedule) {
        return yup.date();
      }
      if (schedule_start) {
        return yup
          .date()
          .min(schedule_start, 'Data final deve ser superior a data inicial')
          .required('Data Final é obrigatória');
      }
      return yup.date().required('Data Final é obrigatória');
    }),
});

const Form: React.FC<Props> = ({ onCreated }) => {
  const toast = useToast();

  const { pageGroup, newPageGroupDTO, onPageGroupCreated, onClose, display } =
    React.useContext(context);

  const { formState, handleSubmit, register, control, setValue } = useForm({
    resolver: yupResolver(formData),
  });

  const [createPageGroup, { loading: createLoading, error: createError }] =
    useMutation(CREATE_PAGE_GROUP, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const [updatePageGroup, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_PAGE_GROUP, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const [removePageGroup, { loading: deleteLoading, error: deleteError }] =
    useMutation(REMOVE_PAGE_GROUP, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const [schedule, setSchedule] = useState(false);

  const { errors } = formState;

  useEffect(() => {
    if (createError || updateError || deleteError) {
      toast({
        title: 'Opss!!',
        description:
          createError?.message ||
          updateError?.message ||
          deleteError?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, [createError, updateError, toast, deleteError]);

  useEffect(() => {
    if (!pageGroup) return;
    if (pageGroup?.schedule_end) {
      setSchedule(true);
      setValue('schedule', true);
      setValue('schedule_start', new Date(pageGroup.schedule_start));
      setValue('schedule_end', new Date(pageGroup.schedule_end));
      setValue('countdown', pageGroup.countdown);
    }
    setValue('logoColor', pageGroup?.logoColor);
    setValue('description', pageGroup?.description);
    setValue('name', pageGroup.name);
  }, [pageGroup, setValue]);

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      const sanitizedObj = sanitizeString(data);

      const { schedule: scheduleData, ...rest } = sanitizedObj;

      if (pageGroup) {
        await updatePageGroup({
          variables: {
            updatePageGroupInput: {
              ...rest,
              schedule_end: scheduleData ? rest.schedule_end : null,
              countdown: scheduleData ? rest.countdown : false,
              id: pageGroup?.id,
            },
          },
        });
        toast({
          title: 'Sucesso!',
          description: 'Grupo editado com sucesso!!',
          status: 'success',
          position: 'top-right',
        });
      } else {
        const apiData = await createPageGroup({
          variables: {
            createPageGroupInput: {
              ...rest,
              ...newPageGroupDTO,
              element_display_id: display?.id,
            },
          },
        });
        if (onCreated) onCreated(apiData);
        if (onPageGroupCreated) onPageGroupCreated(apiData);
        toast({
          title: 'Sucesso!',
          description: 'Grupo criado com sucesso!!',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch {
      // nada
    }
  };

  const handleDelete = async () => {
    try {
      const confirmed = window.confirm(
        'Tem certeza que deseja excluir o grupo?',
      );
      if (confirmed) {
        await removePageGroup({
          variables: {
            id: pageGroup?.id,
          },
        });
        toast({
          title: 'Sucess!!',
          description: 'Grupo deletado com sucesso',
          status: 'success',
          position: 'top-right',
        });
        if (onClose) onClose();
      }
    } catch {
      // error
    }
  };

  return (
    <>
      <VStack as="form" spacing="8" onSubmit={handleSubmit(handleForm)}>
        <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
          <Input label="Título" error={errors?.name} {...register('name')} />
          <Input
            label="Cor do logotipo"
            error={errors?.logoColor}
            {...register('logoColor')}
          />
        </SimpleGrid>
        <Textarea
          label="Subtítulo"
          error={errors?.description}
          {...register('description')}
        />
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="schedule" mb="0">
            Agendamento
          </FormLabel>
          <Switch
            id="schedule"
            size="lg"
            colorScheme="brand"
            isChecked={schedule}
            {...register('schedule')}
            onChange={() => setSchedule(state => !state)}
          />
        </FormControl>
        {schedule && (
          <>
            <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
              <Datepicker
                isRequired
                control={control}
                name="schedule_start"
                label="Início"
                showTimeSelect
                error={errors?.schedule_start}
              />
              <Datepicker
                isRequired
                control={control}
                name="schedule_end"
                label="Final"
                showTimeSelect
                error={errors?.schedule_end}
              />
            </SimpleGrid>
            <Checkbox
              alignSelf="flex-start"
              colorScheme="brand"
              {...register('countdown')}
            >
              Contagem Regressiva
            </Checkbox>
          </>
        )}
      </VStack>

      <ModalFooter>
        <HStack spacing={4}>
          {!!pageGroup && (
            <Button
              onClick={handleDelete}
              colorScheme="red"
              isLoading={deleteLoading}
            >
              Excluir
            </Button>
          )}
          <Button onClick={onClose}>Fechar</Button>
          <Button
            colorScheme="brand"
            isLoading={createLoading || updateLoading}
            onClick={handleSubmit(handleForm)}
          >
            Salvar
          </Button>
        </HStack>
      </ModalFooter>
    </>
  );
};

export default Form;
