import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const RedirectMenu: React.FC<Props> = ({ data }) => {
  const horizontalType = data.type ?? 1;
  return (
    <Box
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      transition="150ms ease"
      position="relative"
      p={1}
      margin="5px 0 5px 0"
    >
      <>
        <Flex align="center">
          <Text fontWeight="bold" fontSize="lg" mr={2}>
            {data?.name}
          </Text>
          <Tooltips data={data} />
        </Flex>
        {!!data?.description && (
          <Text fontSize="xs" color="gray.400">
            {data.description}
          </Text>
        )}
      </>
      <Flex
        justify={horizontalType !== '0' ? 'left' : 'center'}
        direction={horizontalType !== '0' ? 'column' : 'row'}
        p={4}
      >
        {data.items ? (
          data.items.map((item: any) => (
            <Text
              href={item.redirect_value}
              textDecoration="underline"
              mr={horizontalType === '0' ? 2 : 0}
              ml={horizontalType === '0' ? 2 : 0}
            >
              {item.name}
            </Text>
          ))
        ) : (
          <Text>Sem itens cadastrados</Text>
        )}
      </Flex>
    </Box>
  );
};

export default RedirectMenu;
