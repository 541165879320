import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import cms from '../../apis/cms';
import { GET_PAGE_DATA } from '../../graphQL/queries';
import Context from './context';
import components from './components';

interface Props {
  isOpen: boolean;
  pageGroupId?: string;
  displayId?: string;
  pageSlug?: string;
  onClose: () => void;
  onPageGroupCreated?: (data: any) => void;
  newPageGroupDTO?: {
    position: number;
    page_id: string;
  };
}

const DisplayModal: React.FC<Props> = ({
  isOpen,
  onClose,
  newPageGroupDTO,
  displayId,
  pageGroupId,
  pageSlug,
  onPageGroupCreated,
}) => {
  const pageData = cms.graphql.readQuery({
    query: GET_PAGE_DATA,
    variables: {
      slug: pageSlug,
    },
  });

  let display: any;
  let element: any;
  const pageGroup = pageData?.page?.groups?.find(
    (group: any) => group?.id === pageGroupId,
  );

  pageData?.elements?.forEach((e: any) => {
    e?.displays?.forEach((displayElement: any) => {
      if (displayElement?.id === displayId) {
        element = e;
        display = displayElement;
      }
    });
  });

  const renderComponent = (slug: string): JSX.Element | null => {
    const Component = components[slug];
    if (!Component) return null;
    return <Component />;
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="5xl">
      <ModalOverlay />
      <ModalContent maxH="90vh">
        <ModalHeader>{display?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Context.Provider
            value={{
              isOpen,
              page: pageData?.page,
              display,
              pageGroup,
              element,
              onClose,
              onPageGroupCreated,
              newPageGroupDTO,
            }}
          >
            {renderComponent(display?.slug)}
          </Context.Provider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DisplayModal;
