import { FC } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

const Authentication: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Login} />
      <Route
        exact
        path={`${path}/forgot-password`}
        component={ForgotPassword}
      />
      <Route path={`${path}/reset-password`} component={ResetPassword} />
    </Switch>
  );
};

export default Authentication;
