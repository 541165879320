import { FC } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Dashboard from '../modules/Dashboard';
import Authentication from '../modules/Authentication';
import Users from '../modules/Users';
import Config from '../modules/Config';
import MenuItems from '../modules/MenuItem';
import DynamicPage from '../modules/DynamicPage';
import Route from './Route';
import Screen from '../modules/Screen';
import LpItems from '../modules/Lps';
import QuestionItems from '../modules/questions';
import FormItems from '../modules/Form';
import Stopwatch from '../modules/stopwatch';
import EditableText from '../modules/EditableText';
import EditableButton from '../modules/EditableButton';

const Routes: FC = () => (
  <Router>
    <Route exact path="/" component={() => <Redirect to="/auth" />} />
    <Switch>
      <Route path="/auth" component={Authentication} />
      <Route
        exact
        path="/dashboard"
        component={Dashboard}
        isDashboard
        isPrivate
      />
      <Route path="/screens" component={Screen} isDashboard isPrivate />
      <Route path="/users" component={Users} isDashboard isPrivate />
      <Route path="/configs" component={Config} isDashboard isPrivate />
      <Route path="/stopwatch" component={Stopwatch} isDashboard isPrivate />
      <Route
        path="/editable-text"
        component={EditableText}
        isDashboard
        isPrivate
      />
      <Route
        path="/editable-button"
        component={EditableButton}
        isDashboard
        isPrivate
      />
      <Route path="/menu-items" component={MenuItems} isDashboard isPrivate />
      <Route path="/lps" component={LpItems} isDashboard isPrivate />
      <Route
        path="/questions"
        component={QuestionItems}
        isDashboard
        isPrivate
      />
      <Route path="/form" component={FormItems} isDashboard isPrivate />
      <Route
        path="/dynamic_pages"
        component={DynamicPage}
        isDashboard
        isPrivate
      />
      <Route component={() => <h1>401</h1>} />
    </Switch>
  </Router>
);

export default Routes;
