import { Flex, Button, useToast, Text, Box, Link } from '@chakra-ui/react';
import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link as ReachLink } from 'react-router-dom';
import Input from '../../../components/Form/Input';
import useAuth from '../../../hooks/useAuth';

type FormData = {
  email: string;
};

const formSchema = yup.object().shape({
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
});

const ForgotPassword: React.FC = () => {
  const toast = useToast();
  const { sendEmail } = useAuth();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { errors } = formState;

  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);
      await sendEmail(data);
      setEmailSent(true);
      toast({
        title: 'Feito!',
        description: 'E-mail enviado',
        status: 'success',
        position: 'top-right',
        duration: 5000,
      });
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Ocorreu um erro ao enviar o e-mail, tente novamente.',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex w="100vw" h="100vh">
      <Flex flex={1} h="100vh" justify="center" align="center" bg="gray.600" />
      <Flex
        as="form"
        h="100vh"
        w={500}
        justify="center"
        p={42}
        flexDir="column"
        onSubmit={handleSubmit(handleForm)}
      >
        {emailSent ? (
          <Flex
            flex={1}
            h="100vh"
            justify="center"
            align="center"
            direction="column"
          >
            <Text fontSize="xl" textAlign="center" mb={3} fontWeight="semibold">
              E-mail de verificação foi enviado, verifique sua caixa de entrada!
            </Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="#00B200"
              viewBox="0 0 24 24"
            >
              <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
            </svg>
          </Flex>
        ) : (
          <Box textAlign="center">
            <Text fontSize="xl" textAlign="center" mb={3} fontWeight="semibold">
              Insira o seu email e enviaremos um link para você redefinir sua
              senha
            </Text>
            <Input
              label="E-mail"
              mb={4}
              error={errors.email}
              {...register('email')}
            />
            <Button
              type="submit"
              w="100%"
              mt={6}
              mb={4}
              colorScheme="brand"
              justify="center"
              align="center"
              size="md"
              isLoading={loading}
            >
              Enviar
            </Button>
            <Link textAlign="center" as={ReachLink} to="/auth">
              Voltar
            </Link>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default ForgotPassword;
