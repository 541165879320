export const confImagesNamesTranslate = [
  { slug: 'image_login', translate: 'Logo do login' },
  { slug: 'image_menu', translate: 'Logo do Aplicativo' },
];

export const credentialsNamesTranslate = [
  { slug: 'vtex_accountName', translate: 'Nome Conta VTex' },
  { slug: 'vtex_appKey', translate: 'App Key VTex' },
  { slug: 'linx_secretkey', translate: 'Chave secreta Linx' },
  { slug: 'linx_apiKey', translate: 'Chave API Linx' },
  { slug: 'vtex_environment', translate: 'App Key VTex' },
  { slug: 'vtex_salesChannel', translate: 'App Key VTex' },
  { slug: 'vtex_appToken', translate: 'App Token VTex' },
];

export const featuresNamesTranslate = [
  { slug: 'search_by_text', translate: 'Busca de texto' },
  { slug: 'showcase_recomendations', translate: 'Mostragem de recomendação' },
  { slug: 'search_by_image', translate: 'Busca por Imagem' },
  { slug: 'search_autocomplete', translate: 'Autocomplete de busca' },
  { slug: 'showcase_similars', translate: 'Vitrine de similares' },
  {
    slug: 'showcase_search_accessories',
    translate: 'Pesquisa acessórios de vitrine',
  },
  { slug: 'showcase_bought_also_bought', translate: 'Vitrine também comprou' },
  { slug: 'search_filters', translate: 'Vitrine também viu' },
  { slug: 'showcase_saw_also_saw', translate: 'Busca por filtros' },
  { slug: 'showcase_suggestions', translate: 'Vitrine de sugestões' },
  { slug: 'showcase_saw_also_bought', translate: 'Vitrine também comprado' },
  {
    slug: 'showcase_search_show_together',
    translate: 'Vitrine pesquisa mostrar juntos',
  },
];
