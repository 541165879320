import React from 'react';
import { Flex, Box, Image, Text, HStack } from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const MediumBannerCarousel: React.FC<Props> = ({ data, phoneWidth }) => {
  return (
    <Box
      w={phoneWidth}
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      borderRadius="6px"
      transition="150ms ease"
      position="relative"
      p={4}
    >
      {data?.name ? (
        <>
          <Flex align="center">
            <Text fontWeight="bold" fontSize="lg" mr={2}>
              {data?.name}
            </Text>
            <Tooltips data={data} />
          </Flex>
          {!!data?.description && (
            <Text fontSize="xs" color="gray.400">
              {data.description}
            </Text>
          )}
        </>
      ) : (
        <Flex position="absolute" left={2} top={1}>
          <Tooltips data={data} />
        </Flex>
      )}
      <HStack spacing={4} overflow="auto" mt={4}>
        {data?.items?.map((banner: any) => (
          <Flex flexDir="column" align="center" flex="none" key={banner?.id}>
            <Image
              src={banner?.file?.url || '/placeholder_product.jpg'}
              h="100px"
              objectFit="contain"
              borderRadius="6px"
              mb={2}
            />
            <Text fontSize="xs" color="gray.500">
              {banner?.name}
            </Text>
          </Flex>
        ))}
        <Flex flexDir="column" align="center" flex="none">
          <Image
            w="150px"
            h="150px"
            borderRadius="6px"
            borderWidth="2px"
            borderStyle="dashed"
            mb={2}
          />
          <Text fontSize="xs" color="gray.500">
            + Adicionar
          </Text>
        </Flex>
      </HStack>
    </Box>
  );
};

export default MediumBannerCarousel;
