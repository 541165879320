import React, { useContext, useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import context from '../context';
import Form from './Form';
import Banners from './Banners';

const StopwatchBody: React.FC = () => {
  const { pageGroup } = useContext(context);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Tabs index={tabIndex} onChange={index => setTabIndex(index)}>
      <TabList>
        <Tab>Dados</Tab>
        <Tab isDisabled={!pageGroup}>Banner</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Form
            onCreated={() => {
              setTabIndex(1);
            }}
          />
        </TabPanel>
        <TabPanel>
          <Banners />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default StopwatchBody;
