import React from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/react';

interface Props {
  product: any;
  data: any;
}

const Product: React.FC<Props> = ({ product, data }) => {
  const installments = [...product?.sellers[0]?.commertialOffer?.Installments];

  const [higherInstallment] = installments.sort(
    (first: any, second: any) =>
      second.NumberOfInstallments - first.NumberOfInstallments,
  );
  const image = product?.images[0]?.imageUrl;
  const listPrice = product?.sellers[0]?.commertialOffer?.ListPrice;
  const price = product?.sellers[0]?.commertialOffer?.Price;
  const discount = Math.floor((1 - price / listPrice) * 100);
  return (
    <Box>
      <Flex
        p={5}
        mt={3}
        w="100%"
        key={data?.productId}
        borderColor="gray.300"
        borderWidth="1px"
        flexDir="column"
        borderRadius="6px"
        flex="none"
      >
        <Flex>
          <Flex bg="brand.500" p={1} borderRadius="6px" mb={4}>
            {discount && (
              <Text fontSize="xs" color="white">
                - {discount}% OFF
              </Text>
            )}
          </Flex>
        </Flex>
        {image.substring(image.lastIndexOf('.') + 1, image.length) !== 'mp4' ? (
          <Image src={image} w="80%" h="80%" alignSelf="center" mb={4} />
        ) : (
          <iframe title={product?.productName} src={image} allowFullScreen />
        )}
        <Text fontSize="sm" mb={6}>
          {product.nameComplete}
        </Text>
        <Box marginTop="auto">
          {listPrice && (
            <Text textDecor="line-through" fontSize="xs" color="gray.400">
              {Number(listPrice).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
          )}
          <Text fontSize="sm" fontWeight="bold">
            {Number(price).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
          <Text fontSize="xs">
            ou {higherInstallment?.NumberOfInstallments}x de{' '}
            {Number(higherInstallment?.Value).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Product;
