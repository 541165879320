import React, { useEffect, useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  VStack,
  Box,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../../components/Form/Input';
import cms from '../../../apis/cms';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const createFormData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});

const FormModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { formState, handleSubmit, register, control, setValue, reset } =
    useForm({
      resolver: yupResolver(createFormData),
    });

  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      const newForm = {
        name: data.name,
        description: data.descripion,
      };
      await cms.rest.post('/form', newForm);
      toast({
        title: 'Sucesso',
        description: 'Form criado com sucesso',
        status: 'success',
        position: 'top-right',
      });
      onClose();
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };
  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      isOpen={isOpen}
      scrollBehavior="inside"
      isCentered
      size="x1."
    >
      <ModalOverlay color="#FFFFFF" />
      <ModalContent h="auto" bg="#FBFBFB" width="45rem">
        <ModalHeader fontFamily="Poppins" color="#469BFF">
          Novo Formulário
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8}>
            <Box>
              <Input
                isRequired
                bgColor="gray.200"
                placeholder="Nome"
                marginLeft="-20px"
                {...register('name')}
                backgroundColor="#FFFFFF"
                _placeholder={{ color: '#000000' }}
              />
              <Input
                isRequired
                bgColor="gray.200"
                placeholder="Descrição"
                marginLeft="-20px"
                {...register('description')}
                backgroundColor="#FFFFFF"
                marginTop="6px"
                _placeholder={{ color: '#000000' }}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="brand"
              fontFamily="Poppins"
              lineHeight="21px"
              fontSize="14px"
              font-weight="300"
              onClick={handleSubmit(handleForm)}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default FormModal;
