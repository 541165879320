/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

interface Props {
  isOpen: boolean;
  page: any;
  pageGroup: any;
  display: any;
  element: any;
  onClose: () => void;
  onPageGroupCreated?: (data: any) => void;
  newPageGroupDTO?: {
    position: number;
    page_id: string | number;
  };
}

const context = React.createContext<Props>({} as Props);

export default context;
