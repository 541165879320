import React from 'react';

import {
  Flex,
  IconButton,
  Button,
  HStack,
  Text,
  FlexProps,
} from '@chakra-ui/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

interface Props extends FlexProps {
  total: number;
  current: number;
  perPage: number;
  separator?: number;
  disabled?: boolean;
  onPageChange?: (page: number) => void;
}

const Pagination: React.FC<Props> = ({
  total,
  current,
  onPageChange,
  perPage,
  separator = 5,
  disabled = false,
  ...rest
}) => {
  const section = Math.floor((current - 1) / separator);
  const pages = Math.ceil(total / perPage);
  const finalSection = Math.ceil(pages / separator) - 1;

  const handleChange = (page: number) => {
    if (current === page) return;
    if (onPageChange) {
      onPageChange(page);
    }
  };

  return (
    <Flex alignItems="center" {...rest}>
      <IconButton
        disabled={current === 1 || disabled}
        aria-label="previows"
        borderRadius={4}
        alignItems="center"
        justifyContent="center"
        marginRight="6px"
        bg="#FFFFFF"
        color="#469BFF"
        icon={<AiOutlineLeft />}
        onClick={() => handleChange(current - 1)}
      />
      <HStack spacing={1}>
        {finalSection > 0 && section === finalSection ? (
          <>
            <Button
              disabled={disabled}
              onClick={() => {
                handleChange(1);
              }}
            >
              1
            </Button>
            <Text>...</Text>
            {Array(separator)
              .fill(0)
              .map((_, index) => {
                const value = pages - (separator - (index + 1));

                return (
                  <Button
                    key={value}
                    disabled={disabled}
                    bg="#FFFFFF"
                    textColor={current === value ? 'gray' : 'black'}
                    colorScheme={current === value ? 'brand' : 'gray'}
                    onClick={() => {
                      handleChange(value);
                    }}
                  >
                    {value}
                  </Button>
                );
              })}
          </>
        ) : (
          <>
            {Array(pages > separator ? separator : pages)
              .fill(0)
              .map((_, index) => {
                const value = section * separator + (index + 1);

                return (
                  <Button
                    bg="#FFFFFF"
                    textColor={current === value ? 'gray' : 'black'}
                    key={value}
                    disabled={disabled}
                    colorScheme={current === value ? 'brand' : 'gray'}
                    onClick={() => {
                      handleChange(value);
                    }}
                  >
                    {value}
                  </Button>
                );
              })}
            {finalSection > 0 && <Text>...</Text>}
            {finalSection > 0 && (
              <Button
                disabled={disabled}
                onClick={() => {
                  handleChange(pages);
                }}
              >
                {pages}
              </Button>
            )}
          </>
        )}
      </HStack>
      <IconButton
        disabled={current === pages || disabled}
        aria-label="next"
        borderRadius={4}
        alignItems="center"
        justifyContent="center"
        marginLeft="6px"
        bg="#FFFFFF"
        color="#469BFF"
        icon={<AiOutlineRight />}
        onClick={() => handleChange(current + 1)}
      />
    </Flex>
  );
};

export default Pagination;
