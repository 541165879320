import cms from '../apis/cms';
import auth from '../apis/auth';

export default async function refreshToken(): Promise<void> {
  try {
    const storageRefreshToken = localStorage.getItem('@Internal:refresh_token');
    const storageAcessToken = localStorage.getItem('@Internal:token');
    const storageUser: any = localStorage.getItem('@Internal:user');

    if (!storageRefreshToken) {
      throw Error('Refresh token não encontrado');
    }

    if (!storageAcessToken) {
      throw Error('Token não encontrado');
    }

    if (!storageAcessToken) {
      throw Error('Usuário não encontrado');
    }

    const { data }: any = await auth.post('auth/refresh-token', {
      refresh_token: storageRefreshToken,
      userId: JSON.parse(storageUser)?.id,
      access_token: storageAcessToken,
    });

    localStorage.setItem('@Internal:refresh_token', data?.refresh_token);
    localStorage.setItem('@Internal:token', data?.access_token);

    auth.defaults.headers.common.authorization = `Bearer ${data?.access_token}`;
    cms.rest.defaults.headers.common.authorization = `Bearer ${data?.access_token}`;

    return data?.access_token;
  } catch (err) {
    localStorage.clear();
    delete auth.defaults.headers.common.authorization;
    delete cms.rest.defaults.headers.common.authorization;
    window.location.href = '/auth';
    throw err;
  }
}
