import React from 'react';
import { Flex, Box, Image, Text, HStack } from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const ProductsCarousel: React.FC<Props> = ({ data, phoneWidth }) => {
  return (
    <Box
      w={phoneWidth}
      _hover={{ bg: 'gray.200' }}
      borderRadius="6px"
      transition="150ms ease"
      position="relative"
      p={4}
    >
      {data?.name ? (
        <>
          <Flex align="center">
            <Text fontWeight="bold" fontSize="lg" mr={2}>
              {data?.name}
            </Text>
            <Tooltips data={data} />
          </Flex>
          {!!data?.description && (
            <Text fontSize="xs" color="gray.400">
              {data.description}
            </Text>
          )}
        </>
      ) : (
        <Flex position="absolute" left={2} top={1}>
          <Tooltips data={data} />
        </Flex>
      )}

      <HStack spacing={4} overflow="auto" mt={4}>
        {data?.items?.map((item: any) => {
          return (
            <Flex
              p={1}
              mr={2}
              w={150}
              borderColor="gray.300"
              borderWidth="1px"
              flexDir="column"
              borderRadius="6px"
              flex="none"
            >
              <Flex>
                <Flex ml="auto" bg="brand.500" p={1} borderRadius="6px" mb={4}>
                  <Text fontSize="xs" color="white">
                    - x% OFF
                  </Text>
                </Flex>
              </Flex>

              <Image
                src="/placeholder_product.jpg"
                w="70px"
                h="70px"
                alignSelf="center"
                mb={4}
              />

              <Text fontSize="sm" mb={6}>
                Título do produto
              </Text>
              <Box marginTop="auto">
                <Text textDecor="line-through" fontSize="xs" color="gray.400">
                  {Number('0.00').toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  {Number('0.00').toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
                <Text fontSize="xs">
                  ou Xx de{' '}
                  {Number('0.00').toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
              </Box>
            </Flex>
          );
        })}
      </HStack>
    </Box>
  );
};

export default ProductsCarousel;
