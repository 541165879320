import React from 'react';
import {
  Box,
  useTheme,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Skeleton,
  Flex,
} from '@chakra-ui/react';
import DisplayCard from './DisplayCard';

interface Props {
  elements: any[];
  loading?: boolean;
}

const Sidebar: React.FC<Props> = ({ elements, loading }) => {
  const theme = useTheme();

  return (
    <Box
      bg="#F9F9F9"
      w="44rem"
      h="100%"
      overflow="auto"
      p={4}
      boxShadow="xl"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      {loading ? (
        <VStack spacing={4}>
          <Skeleton h="25px" w="100%" borderRadius="4px" />
          <Skeleton h="25px" w="100%" borderRadius="4px" />
          <Skeleton h="25px" w="100%" borderRadius="4px" />
          <Skeleton h="25px" w="100%" borderRadius="4px" />
          <Skeleton h="25px" w="100%" borderRadius="4px" />
        </VStack>
      ) : (
        <Accordion allowToggle w="40rem" bg="#FFFFFF">
          {elements.map((element: any) => (
            <AccordionItem key={element.id}>
              <AccordionButton>
                <Heading flex={1} textAlign="left" fontSize="xl">
                  {element.name}
                </Heading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Flex flexWrap="wrap">
                  {element?.displays?.map((display: any) => (
                    <DisplayCard display={display} key={display.id} />
                  ))}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </Box>
  );
};

export default Sidebar;
