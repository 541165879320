import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  Checkbox as ChakraCheckbox,
  FormLabel,
  Box,
  CheckboxProps,
} from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';

interface IProps extends CheckboxProps {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  control?: Control<FieldValues, object>;
  error?: FieldError;
  placeholder?: string;
  isRequired?: boolean;
  colorScheme?: string;
}

const Checkbox: React.FC<IProps> = ({
  label,
  name,
  control,
  error = null,
  isRequired,
  placeholder,
  colorScheme,
  ...rest
}) => {
  return (
    <Box>
      <FormControl isInvalid={!!error} isRequired={isRequired} display="flex">
        {!!label && (
          <FormLabel htmlFor={name} margin="0 7px 0 0" fontSize="16px">
            {label}
          </FormLabel>
        )}
        <InputGroup justifyContent="center">
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
              <ChakraCheckbox
                size="lg"
                {...rest}
                value={value}
                isChecked={value}
                onChange={e => onChange(e.target.checked)}
                id={name}
                placeholder={placeholder}
                colorScheme={colorScheme}
              />
            )}
          />
        </InputGroup>
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};

export default Checkbox;
