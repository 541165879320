import axios from 'axios';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ENV } from '../constants';

const rest = axios.create({
  baseURL: ENV.REACT_APP_CMS_HOST || 'https://hmlcmsshoulder.corebiz.ag', // 'http://23.22.89.145:87',
});

rest.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      delete rest.defaults.headers.common.authorization;
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

const graphql = new ApolloClient({
  uri:
    ENV.REACT_APP_CMS_GRAPHQL_HOST ||
    'https://hmlcmsshoulder.corebiz.ag/graphql', // 'http://23.22.89.145:87',
  cache: new InMemoryCache(),
});

export default { rest, graphql };
