import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import {
  HStack,
  useToast,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Checkbox,
  Switch,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import context from '../context';
import Input from '../../Form/Input';
import Datepicker from '../../Form/Datepicker';
import {
  CREATE_GROUP_ELEMENT,
  REMOVE_GROUP_ELEMENT,
  UPDATE_GROUP_ELEMENT,
} from '../../../graphQL/mutations';
import { GET_PAGE_DATA } from '../../../graphQL/queries';

interface FormData {
  name: string;
  reference_id: string;
  countdown: boolean;
  schedule: boolean;
  schedule_start?: Date;
  schedule_end?: Date;
}

const formData = yup.object().shape({
  // name: yup.string().required('Nome é obrigatório'),
  // reference_id: yup.string().required('Sku é obrigatório'),
  schedule: yup.boolean(),
  countdown: yup.boolean(),
  schedule_start: yup.date().when('schedule', {
    is: true,
    then: yup.date().required('Data de início é obrigatória'),
  }),
  schedule_end: yup
    .date()
    .when(['schedule', 'schedule_start'], (schedule, schedule_start) => {
      if (!schedule) {
        return yup.date();
      }
      if (schedule_start) {
        return yup
          .date()
          .min(schedule_start, 'Data final deve ser superior a data inicial')
          .required('Data Final é obrigatória');
      }
      return yup.date().required('Data Final é obrigatória');
    }),
});

interface Props {
  onClose: () => void;
  onCreated?: () => void;
  onUpdated?: () => void;
  onDeleted?: () => void;
  isOpen: boolean;
  product?: any;
}

const ProductForm: React.FC<Props> = ({
  onClose,
  isOpen,
  onCreated,
  product,
  onUpdated,
  onDeleted,
}) => {
  const toast = useToast();

  const { pageGroup, element } = useContext(context);

  const { control, register, handleSubmit, formState, reset, setValue } =
    useForm({
      resolver: yupResolver(formData),
    });

  const [createGroupElement] = useMutation(CREATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const [updateGroupElement] = useMutation(UPDATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const [removeGroupElement, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_GROUP_ELEMENT, {
      refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
    });

  const { errors } = formState;

  const [schedule, setSchedule] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!product) return;
    setValue('reference_id', product?.reference_id);
    setValue('name', product?.name);
    setValue('countdown', product?.countdown);

    if (product?.schedule_end) {
      setSchedule(true);
      setValue('schedule', true);
      setValue('schedule_start', new Date(product?.schedule_start));
      setValue('schedule_end', new Date(product?.schedule_end));
    }
  }, [product, setValue]);

  useEffect(() => {
    if (removeError) {
      toast({
        title: 'Opss!!',
        description:
          removeError?.message || 'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, [removeError, toast]);

  const handleDelete = async () => {
    try {
      const confirmed = window.confirm(
        'Tem certeza que deseja excluir o banner?',
      );
      if (confirmed) {
        await removeGroupElement({
          variables: {
            id: product?.id,
          },
        });
        if (onDeleted) onDeleted();
        reset();
        setSchedule(false);
        toast({
          title: 'Sucess!!',
          description: 'Banner deletado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch {
      // error
    }
  };

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);
      const { schedule: scheduleData, ...rest } = data;

      if (product) {
        const variables: any = {
          updateGroupElementInput: {
            ...rest,
            id: product?.id,
            reference_id: data.reference_id,
            schedule_end: scheduleData ? rest.schedule_end : null,
            countdown: scheduleData ? rest.countdown : false,
          },
        };
        await updateGroupElement({
          variables,
        });
        if (onUpdated) onUpdated();
        reset();
        setSchedule(false);
        toast({
          title: 'Sucesso!',
          description: 'Banner editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else {
        await createGroupElement({
          variables: {
            createGroupElementInput: {
              ...rest,
              page_group_id: pageGroup?.id,
              element_id: element?.id,
            },
          },
        });
        if (onCreated) onCreated();
        reset();
        setSchedule(false);
        toast({
          title: 'Sucesso!',
          description: 'Banner criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      onClose={() => {
        reset();
        setSchedule(false);
        onClose();
      }}
      isOpen={isOpen}
      size="lg"
    >
      <DrawerOverlay zIndex={1_400} />
      <DrawerContent>
        <DrawerHeader>Novo Produto</DrawerHeader>
        <DrawerBody>
          <VStack as="form" spacing="8">
            lucas
            <Input label="Nome" error={errors.name} {...register('name')} />
            <Input
              label="SKU"
              error={errors.reference_id}
              {...register('reference_id')}
            />
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="schedule" mb="0">
                Agendamento
              </FormLabel>
              <Switch
                id="schedule"
                size="lg"
                colorScheme="brand"
                isChecked={schedule}
                {...register('schedule')}
                onChange={() => setSchedule(state => !state)}
              />
            </FormControl>
            {schedule && (
              <>
                <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
                  <Datepicker
                    isRequired
                    control={control}
                    name="schedule_start"
                    label="Início"
                    showTimeSelect
                    error={errors.schedule_start}
                  />
                  <Datepicker
                    isRequired
                    control={control}
                    name="schedule_end"
                    label="Final"
                    showTimeSelect
                    error={errors.schedule_end}
                  />
                </SimpleGrid>
                <Checkbox
                  alignSelf="flex-start"
                  colorScheme="brand"
                  {...register('countdown')}
                >
                  Contagem Regressiva
                </Checkbox>
              </>
            )}
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={4}>
            {!!product && (
              <Button
                isLoading={removeLoading}
                colorScheme="red"
                onClick={handleDelete}
              >
                Excluir
              </Button>
            )}
            <Button
              isLoading={loading}
              colorScheme="brand"
              onClick={handleSubmit(handleForm)}
            >
              Salvar
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ProductForm;
