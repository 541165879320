import React, { useState, useEffect } from 'react';
import {
  useToast,
  Flex,
  Input,
  FormControl,
  Text,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import auth from '../../../apis/auth';
import { AffiliateType, CredentialType } from '../types';
// import { credentialsNamesTranslate } from '../../../utils/translates';
import { CREATE_INTEGRATION } from '../../../graphQL/mutations';

interface FormData {
  credential: string;
}

interface Props {
  credential: CredentialType;
  affiliate: AffiliateType;
  className: string;
  style: Record<string, any>;
}

const CredentialText: React.FC<Props> = ({
  credential,
  className,
  style = {},
}) => {
  const toast = useToast();
  const { register, handleSubmit, reset, setValue } = useForm({});

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [credentialValue, setCredentialValue] = useState('');

  const [upsertIntegration, { loading: upsertLoading, error: upsertError }] =
    useMutation(CREATE_INTEGRATION, {});

  const deleteCredential = async () => {
    try {
      if (window.confirm('Deseja excluir essa crendecial?')) {
        await auth.patch<CredentialType>('credentials', {
          slug: credential.slug,
          credential: null,
        });

        await upsertIntegration({
          variables: {
            createIntegrationInput: {
              name: credential.slug,
              credentials: '',
            },
          },
        });

        setCredentialValue('');
        toast({
          title: 'Sucesso!',
          description: 'Credencial excluída com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    setValue('credential', credential.credential);
    setCredentialValue(credential.credential);

    if (upsertError) {
      toast({
        title: 'Opss!!',
        description:
          upsertError?.message || 'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, [credential.credential, setValue, toast, upsertError]);

  const handleForm: SubmitHandler<FormData> = async valueInput => {
    try {
      setLoading(true);

      const { data } = await auth.patch<CredentialType>('credentials', {
        slug: credential.slug,
        credential: valueInput.credential !== '' ? valueInput.credential : null,
      });

      await upsertIntegration({
        variables: {
          createIntegrationInput: {
            name: credential.slug,
            credentials:
              valueInput.credential !== '' ? valueInput.credential : '',
          },
        },
      });

      setCredentialValue(data?.credential);

      toast({
        title: 'Sucesso!',
        description: 'Credencial salva com sucesso',
        status: 'success',
        position: 'top-right',
      });
      setEdit(false);
      reset();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Flex
      flexDir="column"
      w="fit-content"
      margin="0 20px"
      overflow="auto"
      style={style}
      className={className}
    >
      <FormControl variant="floating">
        <Flex textAlign="center">
          {edit ? (
            <Input
              width="100px"
              h="30px"
              position="relative"
              right="-40%"
              marginTop="10px"
              {...register('credential')}
            />
          ) : (
            <Text>{null}</Text>
          )}
          {edit ? (
            <Flex width="80%">
              <Tooltip>
                <Button
                  rounded="md"
                  margin="50px 1px 0 -50px"
                  aria-label="save-credential"
                  size="sm"
                  colorScheme="green"
                  isLoading={loading || upsertLoading}
                  onClick={handleSubmit(handleForm)}
                >
                  Salvar
                </Button>
              </Tooltip>
              <Tooltip>
                <Button
                  margin="50px 0 0 0"
                  aria-label="cancel-credential"
                  size="sm"
                  colorScheme="red"
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
              </Tooltip>
            </Flex>
          ) : (
            <Tooltip label="Editar credencial">
              <Button
                m={1}
                aria-label="edit-credential"
                size="sm"
                bg="#469BFF"
                textColor="white"
                isLoading={loading || upsertLoading}
                onClick={() => {
                  setEdit(true);
                  setValue('credential', '');
                }}
              >
                Editar
              </Button>
            </Tooltip>
          )}
          {!edit && credentialValue ? (
            <Tooltip label="Excluir Credencial">
              <Button
                m={1}
                aria-label="delete-credential"
                size="sm"
                bg="#FFEBF0"
                textColor="red"
                onClick={deleteCredential}
              >
                Excluir
              </Button>
            </Tooltip>
          ) : (
            ''
          )}
        </Flex>
      </FormControl>
    </Flex>
  );
};

export default CredentialText;
