import { useState, useCallback, useContext, FC, createContext } from 'react';

import auth from '../apis/auth';
import cms from '../apis/cms';

type ResetPasswordType = {
  password: string;
  passwordConfirmation: string;
  token: string;
  email: string;
};

interface IAuthContext {
  permissions: string[];
  user: any | null;
  signIn(data: { email: string; password: string }): Promise<void>;
  signOut(): void;
  sendEmail(data: { email: string }): Promise<boolean>;
  resetPassword(data: ResetPasswordType): Promise<any>;
  getToken(): string | null;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const [permissions, setPermissions] = useState<string[]>(() => {
    const storagePermissions = localStorage.getItem('@Internal:permissions');
    if (!storagePermissions) return [];
    return JSON.parse(storagePermissions);
  });
  const [user, setUser] = useState<any | null>(() => {
    const storageUser = localStorage.getItem('@Internal:user');
    if (!storageUser) return null;
    return JSON.parse(storageUser);
  });

  const storageToken = localStorage.getItem('@Internal:token');

  if (storageToken && !auth.defaults.headers.common.authorization) {
    auth.defaults.headers.common.authorization = `Bearer ${storageToken}`;
  }

  if (storageToken && !cms.rest.defaults.headers.common.authorization) {
    cms.rest.defaults.headers.common.authorization = `Bearer ${storageToken}`;
  }

  const sendEmail = useCallback(async (data: { email: string }) => {
    try {
      await auth.post<any>('auth/forgot-password', data);
      return true;
    } catch (err: any) {
      throw Error(
        err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro ao enviar o e-mail',
      );
    }
  }, []);

  const resetPassword = useCallback(async (data: ResetPasswordType) => {
    try {
      await auth.post<any>('auth/reset-forgoted-password', data);
    } catch (err: any) {
      throw Error(
        err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro ao alterar sua senha',
      );
    }
  }, []);

  const signIn = useCallback(
    async (data: { email: string; password: string }) => {
      const { data: authData } = await auth.post<any>('auth/login', data);
      const { access_token, refresh_token } = authData;

      auth.defaults.headers.common.authorization = `Bearer ${access_token}`;
      cms.rest.defaults.headers.common.authorization = `Bearer ${access_token}`;

      const { data: apiUser } = await auth.get<any>('users/me');

      if (apiUser?.permissions.length <= 0) {
        throw Error('Usuário sem permissão');
      }
      setPermissions(apiUser?.permissions || []);
      setUser(apiUser);
      localStorage.setItem('@Internal:token', access_token);
      localStorage.setItem('@Internal:refresh_token', refresh_token);
      localStorage.setItem('@Internal:user', JSON.stringify(apiUser));
      localStorage.setItem(
        '@Internal:permissions',
        JSON.stringify(apiUser?.permissions || []),
      );
    },
    [],
  );

  const signOut = useCallback(async () => {
    await auth.post('auth/logout');
    localStorage.clear();
    delete auth.defaults.headers.common.authorization;
    delete cms.rest.defaults.headers.common.authorization;
    setUser(null);
    setPermissions([]);
  }, []);

  const getToken = useCallback(() => {
    return localStorage.getItem('@Internal:token');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        resetPassword,
        sendEmail,
        signOut,
        getToken,
        permissions,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth(): IAuthContext {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth tem que ser usado junto do AuthProvider');
  }
  return context;
}
