import React, { useEffect, useContext, useState } from 'react';
import {
  IconButton,
  Flex,
  Table,
  Heading,
  Thead,
  Tr,
  Th,
  Tbody,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import Item from './Item';
import ItemForm from './ItemForm';
import context from '../context';

const List: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [items, setItems] = useState<any[]>([]);
  const [itemToEdit, setItemToEdit] = useState<any | null>(null);
  const { pageGroup, page } = useContext(context);

  useEffect(() => {
    setItems(pageGroup?.items);
  }, [pageGroup?.items]);

  return (
    <Flex flexDir="column" w="100%" p={8} overflow="auto">
      <ItemForm
        isOpen={isOpen}
        Item={itemToEdit}
        onClose={() => {
          onClose();
          setItemToEdit(null);
        }}
        onCreated={(item: any) => {
          setItems(state => [...state, item]);
        }}
        onEdited={(item: any) => {
          setItems((state: any) => {
            return state.map((stateItem: any) => {
              if (stateItem.id !== item.id) {
                return stateItem;
              }
              return item;
            });
          });
        }}
      />
      <Heading mb="48px">
        Links
        <IconButton
          ml="4"
          aria-label="create"
          rounded="md"
          icon={<FaPlus />}
          colorScheme="brand"
          onClick={() => onOpen()}
        />
      </Heading>
      <Flex
        position="relative"
        flexDir="column"
        bg="gray.100"
        p={8}
        borderRadius={6}
        boxShadow="lg"
      >
        <Table variant="simple" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th>Título</Th>
              <Th>Link</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items ? (
              items.map((item: any) => (
                <Item
                  key={item?.id}
                  item={item}
                  onDelete={id => {
                    setItems(state =>
                      state.filter((oldItem: any) => oldItem?.id !== id),
                    );
                  }}
                  onEditClick={(data: any) => {
                    setItemToEdit(data);
                    onOpen();
                  }}
                />
              ))
            ) : (
              <Text color="red">Não foi encontrado nenhuma página</Text>
            )}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
};

export default List;
