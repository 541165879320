import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Icon,
  Text,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface Props {
  title: string;
  icon: IconType;
  items: {
    title: string;
    to: string;
  }[];
}

const NavGroup: React.FC<Props> = ({ title, icon, items }) => {
  const groupActive = window.location.pathname.includes('screens');

  return (
    <Accordion allowToggle w="100%" defaultIndex={groupActive ? 0 : -1}>
      <AccordionItem border="none" bg="#FFFFFF">
        <AccordionButton>
          <Flex flex="1" textAlign="left">
            <Icon
              as={icon}
              w="20px"
              h="20px"
              mr="8px"
              color={groupActive ? 'brand.500' : 'gray.800'}
              transition="opacity .3s cubic-bezier(.645,.045,.355,1),margin .3s,color .3s"
              _groupHover={{ color: 'brand.500' }}
            />
            <Text
              fontSize="sm"
              color={groupActive ? 'brand.500' : 'gray.800'}
              transition="opacity .3s cubic-bezier(.645,.045,.355,1),margin .3s,color .3s"
              _groupHover={{ color: 'brand.500' }}
            >
              {title}
            </Text>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <VStack spacing={4} align="flex-start" textAlign="left" px={8}>
            {items
              ? items.map(item => {
                  const itemActive = window.location.pathname === item.to;

                  return (
                    <Link
                      as={RouterLink}
                      to={item.to}
                      key={item.to}
                      color={itemActive ? 'brand.500' : 'gray.800'}
                      transition="opacity .3s cubic-bezier(.645,.045,.355,1),margin .3s,color .3s"
                    >
                      {item.title}
                    </Link>
                  );
                })
              : ''}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default NavGroup;
