import React, { useState, useEffect } from 'react';
import {
  useToast,
  Input,
  Skeleton,
  FormControl,
  Box,
  Flex,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useDebounce from '../../../hooks/useDebounce';
import cms from '../../../apis/cms';

interface FormData {
  free_shipping_value?: number;
  discount_coupon?: string;
}

interface Props {
  label: string;
  name: string;
  type: string;
}

const GeneralConfigValue: React.FC<Props> = ({ label, name, type }) => {
  const toast = useToast();
  const { handleSubmit, reset, register, setValue } = useForm({});

  const [loading, setLoading] = useState(false);
  const [generalConfigValue, setGeneralConfigValue] = useState<any>({});

  const { debounce } = useDebounce();

  const getGeneralConfigValue = async () => {
    try {
      const { data } = await cms.rest.get<any>('/general-config');
      if (data) {
        setGeneralConfigValue(data);
        setValue('field_value', data?.[name]);
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };
  useEffect(() => {
    if (!Object.keys(generalConfigValue).length) {
      getGeneralConfigValue();
    }
  }, []);

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);

      // Caso não tenha valor enviando sempre null, para limpar a variavel no BD
      let valueApi: any = '';
      if (type === 'number' && data) {
        valueApi = Number(data);
      } else {
        valueApi = data === '' ? null : data;
      }
      const { data: dataReturn } = await cms.rest.post<any>(`general-config/`, {
        [name]: valueApi ?? null,
      });
      reset();
      setGeneralConfigValue(dataReturn);
      setValue('field_value', dataReturn?.[name]);

      toast({
        title: 'Sucesso!',
        description: 'Regra de frete atualizada com sucesso',
        status: 'success',
        position: 'top-right',
      });
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          err?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <Skeleton height="20px" />
      ) : (
        <>
          <Flex flexDir="column" overflow="auto" w="100%">
            <FormControl variant="floating" className="teste" w="100%">
              <Box textAlign="center">
                <Input
                  bg="gray.200"
                  placeholder={label}
                  type={type}
                  {...register('field_value')}
                  onChange={(value: any) => {
                    debounce(() => {
                      handleSubmit(handleForm(value.target.value));
                    }, 500);
                  }}
                />
              </Box>
            </FormControl>
          </Flex>
        </>
      )}
    </>
  );
};

export default GeneralConfigValue;
