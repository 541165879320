/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import {
  Flex,
  Heading,
  useToast,
  Accordion,
  useDisclosure,
  VStack,
  ExpandedIndex,
  Button,
  Text,
  AccordionItem,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import Select from '../../../components/Form/Select';
import redirectTypes from '../../../utils/redirectTypes';
import cms from '../../../apis/cms';
import UpdateEditableButtonModal from './components/updateModal';

interface EditableButtontItemTypes {
  name: string;
  id: number;
  slug?: string;
  active: boolean;
  redirect_type: string;
  redirect_value: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const EditableButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [activeAccordion, setActiveAccordion] = useState<ExpandedIndex>(-1);
  const [typeRedirect, setTypeRedirect] = useState('');
  const [editableButtonToEdit, setEditableButtonToEdit] =
    useState<EditableButtontItemTypes>();
  const [editableButtonItems, setEditableButtonItems] = useState<
    EditableButtontItemTypes[]
  >([]);

  const createFormData = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    // redirect_type: yup.string().required('redirect_type é obrigatório'),
    redirect_value: yup.string().required('redirect_value é obrigatório'),
  });

  const toast = useToast();
  const { control, handleSubmit, register } = useForm({
    resolver: yupResolver(createFormData),
  });

  async function getEditableButton() {
    const { data } = await cms.rest.get('/editable-button');
    setEditableButtonItems(data as EditableButtontItemTypes[]);
  }

  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      const newEditableButton: Omit<EditableButtontItemTypes, 'id'> = {
        name: data.name,
        slug: data.slug,
        active: false,
        redirect_type: typeRedirect,
        redirect_value: data.redirect_value,
      };

      const editableButton = await cms.rest.post(
        '/editable-button',
        newEditableButton,
      );
      toast({
        title: 'Sucesso',
        description: 'Botão criado com sucesso',
        status: 'success',
        position: 'top-right',
      });
      setEditableButtonItems([
        ...editableButtonItems,
        editableButton.data as EditableButtontItemTypes,
      ]);
      onClose();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };

  async function handleDelete(id: number) {
    await cms.rest.delete(`/editable-button/${id}`);
    const updatedList = editableButtonItems.filter(item => item.id !== id);
    setEditableButtonItems(updatedList);
  }

  async function handleUpdateActive(editableButton: EditableButtontItemTypes) {
    cms.rest.patch(`/editable-button/${editableButton.id}`, {
      active: !editableButton.active,
    });

    const updatedEditableButton = editableButtonItems.map(item => {
      if (item.id === editableButton.id) {
        item.active = !item.active;
      }

      return item;
    });

    setEditableButtonItems(updatedEditableButton);
  }

  useEffect(() => {
    getEditableButton();
  }, []);
  return (
    <Flex
      flexDir="column"
      w="100%"
      p={8}
      overflow="auto"
      bg="#F9F9F9"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      <Heading mb="48px" color="#469BFF" fontFamily="Poppins">
        Botões editáveis
      </Heading>
      {/* Removi o onDragEnd pois não é possível alterar a ordem dos itens e refletir no app ainda */}
      <DragDropContext onDragEnd={() => console.log('end')}>
        <Flex
          position="relative"
          flexDir="column"
          p={8}
          borderRadius={6}
          width="48rem"
          bg="#FFFFFF"
          boxShadow="lg"
        >
          <Droppable droppableId="root1" type="root1">
            {provided => (
              <Accordion
                allowToggle
                index={activeAccordion}
                onChange={setActiveAccordion}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {editableButtonItems.map(
                  (menuItem: EditableButtontItemTypes) => (
                    <AccordionItem
                      p="7px"
                      borderColor="gray.300"
                      borderRadius="9px"
                      border="2px solid #F9F9F9"
                      ref={provided.innerRef}
                      //   {...provided.draggableProps}
                      //   {...provided.dragHandleProps}
                    >
                      <Flex>
                        <Text fontSize="30px" color="#469BFF" fontFamily="body">
                          Campo:
                        </Text>
                        <Text
                          fontSize="20px"
                          fontFamily="Poppins"
                          mt="7px"
                          ml="5px"
                        >
                          {menuItem.name}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text fontSize="25px" color="#469BFF" fontFamily="body">
                          Texto:
                        </Text>
                        <Text
                          fontSize="20px"
                          fontFamily="Poppins"
                          mt="7px"
                          ml="5px"
                        >
                          {menuItem.slug}
                        </Text>
                      </Flex>
                      {menuItem.active ? (
                        <Button
                          ml="50px"
                          colorScheme="green"
                          onClick={() => handleUpdateActive(menuItem)}
                        >
                          Ativo
                        </Button>
                      ) : (
                        <Button
                          ml="50px"
                          colorScheme="red"
                          onClick={() => handleUpdateActive(menuItem)}
                        >
                          Inativo
                        </Button>
                      )}
                      <Button
                        ml="4"
                        aria-label="create_menu_item"
                        rounded="md"
                        textColor="#FFFFFF"
                        fontFamily="Poppins"
                        marginTop="4px"
                        bg="#469BFF"
                        width="78px"
                        borderRadius="0.375rem"
                        onClick={async () => {
                          setEditableButtonToEdit(menuItem);
                          onOpen2();
                        }}
                      >
                        Atualizar
                      </Button>
                      <Button
                        margin="0 5px"
                        aria-label="delete-menu-item"
                        rounded="md"
                        size="md"
                        bg="#FFEBF0"
                        textColor="red"
                        onClick={async () => {
                          await handleDelete(menuItem.id);
                        }}
                      >
                        Excluir
                      </Button>
                      <UpdateEditableButtonModal
                        isOpen={isOpen2}
                        onClose={onClose2}
                        menuItem={
                          editableButtonToEdit as EditableButtontItemTypes
                        }
                        getEditableButton={getEditableButton}
                      />
                    </AccordionItem>
                  ),
                )}
              </Accordion>
            )}
          </Droppable>
        </Flex>
      </DragDropContext>
      <Button
        ml="4"
        aria-label="create_menu_item"
        rounded="md"
        textColor="#FFFFFF"
        fontFamily="Poppins"
        marginTop="4px"
        bg="#469BFF"
        width="178px"
        borderRadius="0.375rem"
        onClick={onOpen}
      >
        Novo Texto Editável
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
        size="x1."
      >
        <ModalOverlay />
        <ModalContent h="40rem" bg="#FBFBFB" width="45rem">
          <ModalHeader fontFamily="Poppins" color="#469BFF">
            Novo Texto Editável
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={8}>
              <Box>
                <Input
                  isRequired
                  bgColor="gray.200"
                  placeholder="Campo Editável"
                  marginLeft="-20px"
                  {...register('name')}
                  backgroundColor="#FFFFFF"
                  _placeholder={{ color: '#000000' }}
                />
                <Input
                  isRequired
                  bgColor="gray.200"
                  placeholder="Texto"
                  marginLeft="-20px"
                  {...register('slug')}
                  backgroundColor="#FFFFFF"
                  _placeholder={{ color: '#000000' }}
                />
              </Box>
              <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
                <Select
                  name="redirect_type"
                  control={control}
                  label="Redirecionamento"
                  options={redirectTypes}
                  onChange={(e: any) => {
                    setTypeRedirect(e.value);
                  }}
                />
                <Box>
                  <Text> Valor de redirecionamento</Text>
                  <Input
                    label="Valor do Redirecionamento"
                    // error={errors.redirect_value}
                    {...register('redirect_value')}
                  />
                </Box>
              </SimpleGrid>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <VStack spacing={4}>
              <Button
                colorScheme="brand"
                fontFamily="Poppins"
                lineHeight="21px"
                fontSize="14px"
                fontWeight="300"
                onClick={handleSubmit(handleForm)}
              >
                Salvar
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default EditableButton;
