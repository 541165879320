import React from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/react';

interface Props {
  data: any;
  phoneWidth: number;
}

const Focus: React.FC<Props> = ({ data }) => {
  return (
    <Box
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      transition="150ms ease"
      position="relative"
      p={1}
    >
      <>
        <Flex align="center">
          <Text fontWeight="bold" fontSize="lg">
            {data?.name}
          </Text>
        </Flex>
        {!!data?.description && (
          <Text fontSize="xs" color="gray.500">
            {data.description}
          </Text>
        )}
      </>
      {data.items.length ? (
        <Flex
          justifyContent="center"
          alignContent="center"
          padding="20px 0 20px 0"
        >
          <Image src="/default-thumbnail.png" maxW={100} />
        </Flex>
      ) : (
        <Text p={4} fontSize="xs" color="gray.400" mt={3}>
          Sem video cadastrado ainda
        </Text>
      )}
    </Box>
  );
};

export default Focus;
