/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-undef */
import React from 'react';
import {
  Flex,
  useToast,
  VStack,
  Button,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Input,
  HStack,
} from '@chakra-ui/react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import cms from '../../../../apis/cms';

interface StopwatchItemTypes {
  name: string;
  id: number;
  slug?: string;
  active: boolean;
  end_date: Date;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  menuItem: StopwatchItemTypes;
  getStopwatch: () => Promise<void>;
}

const UpdateStopwatchModal: React.FC<Props> = ({
  isOpen,
  onClose,
  menuItem,
  getStopwatch,
}) => {
  const createFormData2 = yup.object().shape({});

  const toast = useToast();
  const { handleSubmit, register } = useForm({
    resolver: yupResolver(createFormData2),
  });

  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      if (data.end_date2) {
        const updatedStopwatch = {
          slug: data.slug2 ?? menuItem.slug,
          end_date: data.end_date2,
        };

        await cms.rest.put(`/stopwatch/${menuItem.id}`, updatedStopwatch);

        onClose();
        await getStopwatch();
      } else {
        const updatedStopwatch = {
          slug: data.slug2 ?? menuItem.slug,
        };

        await cms.rest.put(`/stopwatch/${menuItem.id}`, updatedStopwatch);
        onClose();
        await getStopwatch();
      }

      toast({
        title: 'Sucesso',
        description: 'Cronômetro criado com sucesso',
        status: 'success',
        position: 'top-right',
      });
      onClose();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  };

  return (
    <Flex
      flexDir="column"
      w="100%"
      p={8}
      overflow="auto"
      bg="#F9F9F9"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
        size="x1."
      >
        <ModalOverlay />
        <ModalContent h="auto" bg="#FBFBFB" width="45rem">
          <ModalHeader fontFamily="Poppins" color="#469BFF">
            Atualizar Cronômetro
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={8}>
              <Box>
                <Input
                  isRequired
                  bgColor="gray.200"
                  placeholder="Evento"
                  marginLeft="-20px"
                  {...register('slug2')}
                  backgroundColor="#FFFFFF"
                  _placeholder={{ color: '#000000' }}
                />
                <Input
                  isRequired
                  bgColor="gray.200"
                  placeholder="Descrição"
                  type="datetime-local"
                  marginLeft="-20px"
                  {...register('end_date2')}
                  backgroundColor="#FFFFFF"
                  marginTop="6px"
                  _placeholder={{ color: '#000000' }}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <VStack spacing={4}>
              <Button
                colorScheme="brand"
                fontFamily="Poppins"
                lineHeight="21px"
                fontSize="14px"
                font-weight="300"
                onClick={handleSubmit(handleForm)}
              >
                Salvar
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default UpdateStopwatchModal;
