import React, { useEffect, useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  SimpleGrid,
  VStack,
  Flex,
  Box,
  Checkbox,
  Switch,
  Text,
} from '@chakra-ui/react';
import { AiFillCamera } from 'react-icons/ai';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from '../../../components/Form/Select';
import Upload from '../../../components/Form/Upload';
import Input from '../../../components/Form/Input';
import cms from '../../../apis/cms';
import { MenuItemType } from '../types';
import redirectTypes from '../../../utils/redirectTypes';
import { GET_PAGES } from '../../../graphQL/queries';

interface Props {
  isOpen: boolean;
  location: string;
  onClose: () => void;
  subMenuItemEdit?: string;
  menuItemToEdit?: MenuItemType;
  onCreated?: (menuItem: any) => void;
  onEdited?: (menuItem: any) => void;
}
interface FormData {
  right_file?: FileList;
  banner_file?: FileList;
  left_file?: FileList;
  active?: boolean;
  menu_item_id?: string;
  reference_value?: string;
  collapsible?: boolean;
  highlighted_color?: string;
  always_open?: boolean;
  redirect_type?: string;
  banner_redirect_type?: string;
  facets?: string;
  redirect_value?:
    | string
    | {
        label: string | number;
        value: string | number;
      };
  banner_redirect_value?:
    | string
    | {
        label: string | number;
        value: string | number;
      };
  name: string;
}
const createFormData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});
const MenuItemModal: React.FC<Props> = ({
  isOpen,
  location,
  onClose,
  subMenuItemEdit,
  menuItemToEdit,
  onCreated,
  onEdited,
}) => {
  const toast = useToast();
  const { formState, handleSubmit, register, control, setValue, reset } =
    useForm({
      resolver: yupResolver(createFormData),
    });
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [typeRedirect, setTypeRedirect] = useState('');
  const [typeRedirectBanner, setTypeRedirectBanner] = useState('');
  const [imageOrVideo, setImageOrVideo] = useState(false);
  const pages = cms.graphql.readQuery({
    query: GET_PAGES,
  });
  useEffect(() => {
    if (!menuItemToEdit) return;
    setValue('active', menuItemToEdit.active);
    setValue('highlighted_color', menuItemToEdit.highlighted_color);
    setValue('always_open', menuItemToEdit.always_open);
    setValue('collapsible', menuItemToEdit.collapsible);
    setValue('name', menuItemToEdit.name);
    setValue('banner_redirect_type', menuItemToEdit.banner_redirect_type);
    setValue('facets', menuItemToEdit.facets);
    setValue('reference_value', menuItemToEdit?.reference_value);
    if (menuItemToEdit?.reference_value) {
      setImageOrVideo(true);
    } else {
      setImageOrVideo(false);
    }
    if (menuItemToEdit.redirect_type === 'app_screen') {
      setTypeRedirect('app_screen');
      const selectedValueSelect = pages.pages.find(
        (page: any) => page.slug === menuItemToEdit.redirect_value,
      );
      setValue('redirect_value', {
        label: selectedValueSelect?.name,
        value: selectedValueSelect?.slug,
      });
    } else if (menuItemToEdit.redirect_type === 'query') {
      setTypeRedirect(menuItemToEdit.redirect_type);
      setValue('redirect_value', menuItemToEdit?.redirect_value);
    } else {
      setTypeRedirect('');
      setValue('redirect_value', menuItemToEdit?.redirect_value);
    }
    setValue(
      'redirect_type',
      redirectTypes.find(
        type => type.value === menuItemToEdit?.redirect_type,
      ) ?? null,
    );
    setValue('banner_redirect_value', menuItemToEdit?.banner_redirect_value);
  }, [menuItemToEdit, setValue]);
  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      setLoading(true);
      let leftFileReturn;
      if (data?.left_file[0]) {
        const multipart = new FormData();
        multipart.append('file', data.left_file[0]);
        const { data: leftFileApi } = await cms.rest.post<any>(
          '/upload',
          multipart,
        );
        leftFileReturn = leftFileApi;
      }
      let rightFileReturn;
      if (data?.right_file[0]) {
        const multipart = new FormData();
        multipart.append('file', data?.right_file[0]);
        const { data: rightFileApi } = await cms.rest.post<any>(
          '/upload',
          multipart,
        );
        rightFileReturn = rightFileApi;
      }
      let bannerFileReturn;
      if (data?.banner_file[0]) {
        const multipart = new FormData();
        multipart.append('file', data?.banner_file[0]);
        const { data: bannerFileApi } = await cms.rest.post<any>(
          '/upload',
          multipart,
        );
        bannerFileReturn = bannerFileApi;
      }
      let menuItemReturn;
      if (!menuItemToEdit) {
        const body: any = {};
        body.left_file_id = leftFileReturn ? leftFileReturn?.id : null;
        body.right_file_id = rightFileReturn ? rightFileReturn?.id : null;
        body.banner_file_id = bannerFileReturn ? bannerFileReturn?.id : null;
        body.active = data.active;
        body.highlighted_color =
          data.highlighted_color !== '#000000' ? data.highlighted_color : null;
        body.always_open = data.always_open;
        body.name = data.name;
        if (data.redirect_value) {
          body.redirect_value =
            typeof data?.redirect_value === 'object'
              ? data?.redirect_value.value
              : data?.redirect_value;
        }
        if (subMenuItemEdit) {
          body.menu_item_id = subMenuItemEdit;
        }
        body.redirect_type = data?.redirect_type?.value ?? null;
        body.banner_redirect_type = data?.redirect_type_banner?.value ?? null;
        body.facets = data?.facets;
        body.banner_redirect_value = data?.banner_redirect_value;
        body.reference_value = data?.reference_value;
        body.location = location ?? 'menu';
        const { data: menuItemApi } = await cms.rest.post<any>(
          '/menu-items',
          body,
        );
        menuItemReturn = menuItemApi;
        toast({
          title: 'Sucesso',
          description: 'Item do menu criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
        if (onCreated) {
          onCreated(menuItemReturn);
        }
      } else {
        const body: any = {};
        if (leftFileReturn) {
          body.left_file_id = leftFileReturn?.id;
        }
        if (rightFileReturn) {
          body.right_file_id = rightFileReturn?.id;
        }
        if (bannerFileReturn) {
          body.banner_file_id = bannerFileReturn?.id;
        }
        body.active = data.active;
        body.highlighted_color =
          data.highlighted_color !== '#000000' ? data.highlighted_color : null;
        body.always_open = data.always_open;
        body.name = data.name;
        body.facets = data.facets;
        body.banner_redirect_value = data?.banner_redirect_value;
        body.reference_value = data?.reference_value;
        if (bannerFileReturn?.id) {
          body.reference_value = null;
        }
        if (body?.reference_value) {
          body.banner_file_id = null;
          if (bannerFileReturn?.id) {
            await cms.rest.delete<any>(`/upload/${bannerFileReturn.id}`);
          }
        }
        if (data?.redirect_value) {
          body.redirect_value =
            typeof data?.redirect_value === 'object'
              ? data?.redirect_value.value
              : data?.redirect_value;
        }
        body.redirect_type = data?.redirect_type?.value;
        body.banner_redirect_type = data?.redirect_type_banner?.value ?? null;
        body.location = location ?? 'menu';
        console.log({ body });
        if (body) {
          const { data: menuItemApi } = await cms.rest.patch<any>(
            `/menu-items/${menuItemToEdit?.id}`,
            body,
          );
          menuItemReturn = menuItemApi;
        }
        if (onEdited) {
          onEdited(menuItemReturn);
        }
        toast({
          title: 'Sucesso',
          description: 'Item do menu editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
      onClose();
      reset();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      onClose={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      scrollBehavior="inside"
      isCentered
      size="x1."
    >
      <ModalOverlay color="#FFFFFF" />
      <ModalContent h="auto" bg="#FBFBFB" width="45rem">
        <ModalHeader fontFamily="Poppins" color="#469BFF">
          Novo Item de Menu
        </ModalHeader>
        <ModalCloseButton />
        <Text fontFamily="Poppins" color="#469BFF" marginLeft="25px">
          Configurações
        </Text>
        <ModalBody>
          <VStack spacing={8}>
            <Flex>
              <Input
                isRequired
                bgColor="gray.200"
                placeholder="Nome"
                marginLeft="-20px"
                error={errors.name}
                {...register('name')}
                backgroundColor="#FFFFFF"
                _placeholder={{ color: '#000000' }}
              />
              <Select
                name="redirect_type"
                control={control}
                placeholder="Redirecionamento"
                error={errors.redirect_type}
                options={redirectTypes}
                onChange={(e: any) => {
                  setValue('redirect_value', '');
                  setTypeRedirect(e.value);
                }}
              />
            </Flex>
            <Flex>
              <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
                <Box marginLeft="-40px" width="230px">
                  {typeRedirect !== 'app_screen' ? (
                    <Input
                      placeholder="Valor de Redirecionamento"
                      marginRight="18px"
                      width="16rem"
                      bgColor="gray.200"
                      backgroundColor="#FFFFFF"
                      _placeholder={{ color: '#000000' }}
                      error={errors.redirect_value}
                      {...register('redirect_value')}
                    />
                  ) : (
                    <Select
                      name="redirect_value"
                      control={control}
                      label="Valor do Redirecionamento"
                      error={errors.redirect_value}
                      options={pages.pages.map((page: any) => {
                        return {
                          label: page?.name,
                          value: page?.slug,
                        };
                      })}
                    />
                  )}
                </Box>
              </SimpleGrid>
              {typeRedirect === 'query' && (
                <Input
                  placeholder="Facets"
                  width="100%"
                  bgColor="gray.200"
                  backgroundColor="#FFFFFF"
                  _placeholder={{ color: '#000000' }}
                  error={errors.facets}
                  {...register('facets')}
                />
              )}
            </Flex>
            <Text
              fontFamily="Poppins"
              color="#469BFF"
              position="relative"
              left="-250px"
            >
              Ilustração dos itens
            </Text>
            <Flex mb={6}>
              <Upload
                bg="#FFFFFF"
                label="Imagem Esquerda (opcional)"
                acceptedFileTypes="image/*"
                alignSelf="center"
                icon={AiFillCamera}
                colorIcon="#469BFF"
                {...register('left_file')}
                url={
                  menuItemToEdit?.file_left?.id
                    ? menuItemToEdit?.file_left?.url
                    : undefined
                }
                idFileApi={menuItemToEdit?.file_left?.id}
              />
              <Upload
                bg="#FFFFFF"
                label="Imagem Direita (opcional)"
                acceptedFileTypes="image/*"
                alignSelf="center"
                {...register('right_file')}
                colorIcon="#469BFF"
                url={
                  menuItemToEdit?.file_right?.id
                    ? menuItemToEdit?.file_right?.url
                    : undefined
                }
                idFileApi={menuItemToEdit?.file_right?.id}
              />
              <Box mt={5} textAlign="center" marginTop="1.5rem">
                <Flex>
                  <Box style={{ display: imageOrVideo ? 'inherit' : 'none' }}>
                    <Input
                      width="163px"
                      label="Url do vídeo"
                      margin="0 0 39.5px 0"
                      error={errors.reference_value}
                      {...register('reference_value')}
                    />
                  </Box>
                  <Box style={{ display: imageOrVideo ? 'none' : 'inherit' }}>
                    <Upload
                      bg="#FFFFFF"
                      label="Banner (opcional)"
                      acceptedFileTypes="image/*"
                      alignSelf="center"
                      colorIcon="#469BFF"
                      {...register('banner_file')}
                      url={
                        menuItemToEdit?.banner_file?.id
                          ? menuItemToEdit?.banner_file?.url
                          : undefined
                      }
                      idFileApi={menuItemToEdit?.banner_file?.id}
                    />
                  </Box>
                </Flex>
                <Text fontWeight="600" mb={1} marginTop="50px">
                  Desejo cadastrar vídeo
                </Text>
                <Switch
                  id="video_or_image"
                  size="lg"
                  colorScheme="brand"
                  isChecked={imageOrVideo}
                  onChange={() => setImageOrVideo(!imageOrVideo)}
                />
              </Box>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Input
                placeholder="Valor do Redirecionamento do Banner"
                marginBottom="10px"
                error={errors.banner_redirect_value}
                {...register('banner_redirect_value')}
              />
              <Select
                name="redirect_type_banner"
                control={control}
                placeholder="Redirecionamento"
                error={errors.redirect_type}
                options={redirectTypes}
                onChange={(e: any) => {
                  setValue('redirect_type_banner', '');
                  setTypeRedirectBanner(e.value);
                }}
              />
              <Box>
                <Input
                  {...register('highlighted_color')}
                  type="color"
                  width="110px"
                  label="Cor destaque"
                />
              </Box>
            </Flex>
            <Flex>
              <Checkbox
                colorScheme="green"
                checked
                {...register('active')}
                mr={3}
              >
                Ativo
              </Checkbox>
              <Checkbox
                colorScheme="green"
                checked
                {...register('always_open')}
              >
                Sempre aberto
              </Checkbox>
            </Flex>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="brand"
              isLoading={loading}
              fontFamily="Poppins"
              lineHeight="21px"
              fontSize="14px"
              fontWeight="300"
              onClick={handleSubmit(handleForm)}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default MenuItemModal;
