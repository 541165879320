import React from 'react';
import MosaicBannerCarousel from '../MobileComponents/MosaicBannerCarousel';
import BannerCarousel from '../MobileComponents/BannerCarousel';
import IconCarousel from '../MobileComponents/IconCarousel';
import MediumBannerCarousel from '../MobileComponents/MediumBannerCarousel';
import SingleBanner from '../MobileComponents/SingleBanner';
import SmallBannerCarousel from '../MobileComponents/SmallBannerCarousel';
import Description from '../MobileComponents/Description';
import Focus from '../MobileComponents/Focus';
import Colapse from '../MobileComponents/Colapse';
import RedirectMenu from '../MobileComponents/RedirectMenu';
import MediaYoutube from '../MobileComponents/MediaYoutube';
import Topic from '../MobileComponents/Topic';
import Card from '../MobileComponents/Card';
import ProductTable from '../MobileComponents/ProductTable';
import InfiniteScroll from '../MobileComponents/InfiniteScroll';
import UniqueProduct from '../MobileComponents/UniqueProduct';
import ProductCarouselQuery from '../MobileComponents/ProductCarouselQuery';
import VtexCollectionPhone from '../MobileComponents/VtexCollection';
import ProductsCarousel from '../MobileComponents/ProductsCarousel';
import MosaicBannerCarouselVertical from '../MobileComponents/MosaicBannerCarouselVertical';
import CheckeredBannerCarousel from '../MobileComponents/CheckeredBannerCarousel';

const components: { [key: string]: React.FC<any> } = {
  'carrossel-de-icones': IconCarousel,
  'banner-unico': SingleBanner,
  'banner-unico-countdown': SingleBanner,
  'banner-cronometro': SingleBanner,
  'banner-localizacao': SingleBanner,
  'carrossel-de-banner-pequeno': SmallBannerCarousel,
  'carrossel-de-banner-medio': MediumBannerCarousel,
  'carrossel-de-banner': BannerCarousel,
  'carrossel-de-produtos': ProductsCarousel,
  'carrossel-de-produtos-query': ProductCarouselQuery,
  'carrossel-de-banner-mosaico': MosaicBannerCarousel,
  'carrossel-de-banner-quadriculado': CheckeredBannerCarousel,
  'carrossel-de-banner-mosaico-vertical': MosaicBannerCarouselVertical,
  description: Description,
  focus: Focus,
  colapse: Colapse,
  'redirect-menu': RedirectMenu,
  'colecao-vtex': VtexCollectionPhone,
  'media-youtube': MediaYoutube,
  topic: Topic,
  card: Card,
  'table-products': ProductTable,
  'infinite-scroll': InfiniteScroll,
  'unique-product': UniqueProduct,
};

export default components;
