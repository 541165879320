import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';
import {
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  FormErrorMessage,
  Box,
  FormControlProps,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-br', ptBr);

interface Props extends FormControlProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FieldValues, object>;
  name: string;
  showTimeSelect?: boolean;
  label?: string;
  error?: FieldError;
  isRequired?: boolean;
  dateFormat?: string;
}

const Container: React.FC = ({ children }) => (
  <Box position="absolute" zIndex={100}>
    {children}
  </Box>
);

const Datepicker: React.FC<Props> = ({
  control,
  name,
  showTimeSelect,
  label,
  error,
  isRequired = false,
  dateFormat = 'MMMM d, yyyy h:mm aa',
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!error} isRequired={isRequired} {...rest}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        <Controller
          name={name}
          control={control}
          render={({ field: { onBlur, onChange, value } }) => (
            <DatePicker
              customInput={
                <Input
                  focusBorderColor="brand.500"
                  bgColor="gray.100"
                  variant="filled"
                  _hover={{ borderColor: 'brand.500' }}
                  size="md"
                />
              }
              locale="pt-br"
              selected={value}
              onChange={onChange}
              onBlur={onBlur}
              showTimeSelect={showTimeSelect}
              dateFormat={dateFormat}
              showPopperArrow
              popperContainer={Container}
            />
          )}
        />
      </InputGroup>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default Datepicker;
