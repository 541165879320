import React, { useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  VStack,
  Flex,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../../components/Form/Input';
import cms from '../../../apis/cms';
import { MenuItemType } from '../types';
import Item from '../../Users/pages/List/Item';

interface Props {
  isOpen: boolean;
  location: string;
  onClose: () => void;
  menuItemToEdit?: MenuItemType;
  onCreated?: (menuItem: any) => void;
  onEdited?: (menuItem: any) => void;
}
interface FormData {
  right_file?: FileList;
  banner_file?: FileList;
  left_file?: FileList;
  active?: boolean;
  menu_item_id?: string;
  reference_value?: string;
  collapsible?: boolean;
  highlighted_color?: string;
  always_open?: boolean;
  redirect_type?: string;
  banner_redirect_type?: string;
  facets?: string;
  redirect_value?:
    | string
    | {
        label: string | number;
        value: string | number;
      };
  banner_redirect_value?:
    | string
    | {
        label: string | number;
        value: string | number;
      };
  name: string;
}
const createFormData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});
const HotsiteModal: React.FC<Props> = ({ isOpen, onClose, menuItemToEdit }) => {
  const toast = useToast();
  const { formState, handleSubmit, register, reset } = useForm({
    resolver: yupResolver(createFormData),
  });
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const handleForm: SubmitHandler<FormData> = async (data: any) => {
    try {
      setLoading(true);
      if (menuItemToEdit) {
        cms.rest.put(`/hotsites/${menuItemToEdit.id}`, { name: data.name });
        toast({
          title: 'Sucesso',
          description: 'Item editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
        onClose();
        reset();
        return;
      }
      cms.rest.post('/hotsites', { name: data.name });
      console.log({ data });

      toast({
        title: 'Sucesso',
        description: 'Item do menu criado com sucesso',
        status: 'success',
        position: 'top-right',
      });
      onClose();
      reset();
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      onClose={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      scrollBehavior="inside"
      isCentered
      size="x1."
    >
      <ModalOverlay color="#FFFFFF" />
      <ModalContent h="auto" bg="#FBFBFB" width="45rem">
        <ModalHeader fontFamily="Poppins" color="#469BFF">
          Novo Hotsite
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8}>
            <Flex>
              <Input
                isRequired
                border="1px solid #FBFBFB"
                bgColor="gray.200"
                placeholder="Nome"
                marginLeft="-20px"
                error={errors.name}
                {...register('name')}
                backgroundColor="#FFFFFF"
                _placeholder={{ color: '#000000' }}
              />
            </Flex>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="brand"
              isLoading={loading}
              fontFamily="Poppins"
              lineHeight="21px"
              fontSize="14px"
              fontWeight="300"
              onClick={handleSubmit(handleForm)}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default HotsiteModal;
