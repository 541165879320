import React, { useEffect, useState } from 'react';
import {
  Flex,
  Table,
  Heading,
  Thead,
  Tr,
  Th,
  Tbody,
  Spinner,
  useDisclosure,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { GET_PAGES } from '../../../../graphQL/queries';
import Item from './Item';
import DynamicPageModal from '../../components/DynamicPageModal';

const List: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pages, setPages] = useState<any[]>([]);
  const [pageToEdit, setPageToEdit] = useState<any | null>(null);
  const { data: pagesQuery, loading: loadingPages } = useQuery(GET_PAGES);

  useEffect(() => {
    setPages(pagesQuery?.pages);
  }, [pagesQuery?.pages]);
  return (
    <Flex
      flexDir="column"
      w="100%"
      p={8}
      overflow="auto"
      bg="#F9F9F9"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#469BFF',
          borderRadius: '24px',
        },
      }}
    >
      <DynamicPageModal
        isOpen={isOpen}
        PageToEdit={pageToEdit}
        onClose={() => {
          onClose();
          setPageToEdit(null);
        }}
        onCreated={(page: any) => {
          setPages(state => [...state, page]);
        }}
        onEdited={(page: any) => {
          setPages((state: any) => {
            return state.map((statePage: any) => {
              if (statePage.id !== page.id) return statePage;
              return page;
            });
          });
        }}
      />
      <Heading mb="48px" color="#469BFF" fontFamily="Poppins">
        Páginas Dinâmicas
      </Heading>
      <Flex
        position="relative"
        flexDir="column"
        bg="#F9F9F9"
        p={8}
        borderRadius={6}
        width="52rem"
      >
        <Table variant="simple" colorScheme="blackAlpha">
          <Box
            border="1px solid #068FDD"
            borderRadius="14px"
            width="90%"
            height="40px"
            bg="#F9FDFF"
          >
            <Thead borderRadius="14px" overflow="hidden" width="305%">
              <Tr>
                <Th
                  textColor="#469BFF"
                  position="relative"
                  top="9%"
                  right="-210%"
                  fontFamily="Poppins"
                >
                  AÇÕES
                </Th>
                <Th
                  textColor="#469BFF"
                  position="relative"
                  top="9%"
                  right="-65%"
                  fontFamily="Poppins"
                >
                  Slug
                </Th>
                <Th
                  textColor="#469BFF"
                  position="relative"
                  top="9%"
                  right="60%"
                  fontFamily="Poppins"
                >
                  Página
                </Th>
              </Tr>
            </Thead>
          </Box>
          <Tbody>
            {pages ? (
              pages.map((page: any) => (
                <Item
                  key={page?.id}
                  page={page}
                  onDelete={id => {
                    setPages(state =>
                      state.filter((oldPage: any) => oldPage?.id !== id),
                    );
                  }}
                  onEditClick={(data: any) => {
                    setPageToEdit(data);
                    onOpen();
                  }}
                />
              ))
            ) : (
              <Text color="red">Não foi encontrado nenhuma página</Text>
            )}
          </Tbody>
        </Table>
        {loadingPages && (
          <Flex
            position="absolute"
            align="center"
            justify="center"
            top="50%"
            left="50%"
          >
            <Spinner color="brand.500" size="xl" />
          </Flex>
        )}
      </Flex>
      <Button
        textAlign="center"
        ml="4"
        fontWeight="300"
        fontFamily="Poppins"
        position="relative"
        padding="10px 0 10px 0"
        width="200px"
        border="0px"
        aria-label="create_page"
        rounded="md"
        colorScheme="brand"
        onClick={() => onOpen()}
      >
        Novo item de menu
      </Button>
    </Flex>
  );
};

export default List;
