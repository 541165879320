import React from 'react';
import {
  Flex,
  Box,
  Image,
  Text,
  HStack,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import Tooltips from './Tooltips';

interface Props {
  data: any;
  phoneWidth: number;
}

const MosaicBannerCarouselVertical: React.FC<Props> = ({ data }) => {
  const grid = [
    {
      id: 0,
      colStart: 1,
      rowStart: 1,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 1,
      colStart: 1,
      rowStart: 2,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 2,
      colStart: 2,
      rowStart: 1,
      colSpan: 2,
      rowSpan: 2,
    },
    {
      id: 3,
      colStart: 1,
      rowStart: 3,
      colSpan: 2,
      rowSpan: 2,
    },
    {
      id: 4,
      colStart: 2,
      rowStart: 3,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 5,
      colStart: 2,
      rowStart: 4,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 6,
      colStart: 1,
      rowStart: 5,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 7,
      colStart: 1,
      rowStart: 6,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 8,
      colStart: 2,
      rowStart: 5,
      colSpan: 1,
      rowSpan: 2,
    },
    {
      id: 9,
      colStart: 1,
      rowStart: 7,
      colSpan: 1,
      rowSpan: 2,
    },
    {
      id: 10,
      colStart: 2,
      rowStart: 7,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 11,
      colStart: 2,
      rowStart: 8,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 12,
      colStart: 1,
      rowStart: 9,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 13,
      colStart: 1,
      rowStart: 10,
      colSpan: 1,
      rowSpan: 1,
    },
    {
      id: 14,
      colStart: 2,
      rowStart: 9,
      colSpan: 1,
      rowSpan: 2,
    },
  ];

  return (
    <Box
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      borderRadius="6px"
      transition="150ms ease"
      position="relative"
      p={4}
    >
      {data?.name ? (
        <>
          <Flex align="center">
            <Text fontWeight="bold" fontSize="lg" mr={2}>
              {data?.name}
            </Text>
            <Tooltips data={data} />
          </Flex>
          {!!data?.description && (
            <Text fontSize="xs" color="gray.400">
              {data.description}
            </Text>
          )}
        </>
      ) : (
        <Flex position="absolute" left={2} top={1}>
          <Tooltips data={data} />
        </Flex>
      )}

      <HStack spacing={0} mt={1}>
        <Grid
          w="100%"
          templateColumns="repeat(2, 150px)"
          justifyContent="center"
          mt={5}
          gap={1}
        >
          {grid.map((gridItem, index) => {
            return (
              <>
                {data?.items[index] && (
                  <GridItem
                    key={gridItem.id}
                    colStart={gridItem.colStart}
                    colSpan={gridItem.colSpan}
                    rowSpan={gridItem.rowSpan}
                    rowStart={gridItem.rowStart}
                  >
                    {data?.items[index]?.file?.url ||
                    data?.items[index]?.reference_value ? (
                      <Image
                        w="100%"
                        h="100%"
                        borderRadius="6px"
                        src={
                          data?.items[index]?.file?.url ||
                          '/placeholder_product.jpg'
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </GridItem>
                )}
              </>
            );
          })}
        </Grid>
      </HStack>
      {data?.items?.length < 15 && (
        <Text w="100%" textAlign="center" fontSize="30px">
          ...
        </Text>
      )}
    </Box>
  );
};

export default MosaicBannerCarouselVertical;
