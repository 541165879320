import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, useToast, useDisclosure } from '@chakra-ui/react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PAGE_DATA } from '../../../../graphQL/queries';
import Sidebar from './Sidebar';
import Phone from '../../../../components/Phone';
import { UPDATE_PAGE_GROUP_POSITION } from '../../../../graphQL/mutations';
import cms from '../../../../apis/cms';
import DisplayModal from '../../../../components/DisplayModal';

const Main: React.FC = () => {
  const { page_slug } = useParams() as any;
  const [updatePageGroupPosition] = useMutation(UPDATE_PAGE_GROUP_POSITION);
  const {
    data: state,
    loading,
    error,
  } = useQuery(GET_PAGE_DATA, {
    variables: { slug: page_slug },
  });
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [newPageGroupDTO, setNewPageGroupDTO] = useState<any>(undefined);
  const [displayId, setDisplayId] = useState<string | undefined>(undefined);
  const [pageGroupId, setPageGroupId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (error) {
      toast({
        title: 'Opss!!',
        description:
          error?.message || 'Tivemos um erro, Tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    }
  }, [error, toast]);

  const handlePosition = async ({
    id,
    position,
    oldPosition,
  }: {
    id: string;
    position: number;
    oldPosition: number;
  }) => {
    try {
      cms.graphql.cache.modify({
        id: cms.graphql.cache.identify(state.page),
        fields: {
          groups(groups) {
            const newGroups = [...groups];
            const [element] = newGroups.splice(oldPosition, 1);
            newGroups.splice(position, 0, element);
            return newGroups;
          },
        },
      });
      await updatePageGroupPosition({
        variables: {
          updatePageGroupInput: {
            id,
            position,
          },
        },
      });
    } catch (err: any) {
      cms.graphql.cache.modify({
        id: cms.graphql.cache.identify(state.page),
        fields: {
          groups(groups) {
            const newGroups = [...groups];
            const [element] = newGroups.splice(position, 1);
            newGroups.splice(oldPosition, 0, element);
            return newGroups;
          },
        },
      });
      toast({
        title: 'Opss!!',
        description: err?.message || 'Tivemos um erro',
        status: 'error',
        position: 'top-right',
      });
    }
  };

  const handleInclusion = async ({
    id,
    position,
  }: {
    id: string;
    position: number;
  }) => {
    let display: any;
    setNewPageGroupDTO({
      position,
      page_id: state?.page?.id,
      element_display_id: display?.id,
    });
    setDisplayId(id);
    onOpen();
  };

  const onDragEnd = (result: any) => {
    if (!result?.destination || !result?.source) return;
    const { source, destination, draggableId } = result;
    if (source.droppableId === 'phone') {
      handlePosition({
        id: draggableId,
        position: destination.index,
        oldPosition: source.index,
      });
    } else {
      handleInclusion({ id: draggableId, position: destination?.index });
    }
  };

  return (
    <Flex w="100%" h="100%" bg="#F9F9F9">
      <DragDropContext onDragEnd={onDragEnd}>
        <Sidebar elements={state?.elements || []} loading={loading} />
        <Flex flex={1} align="center" justify="center">
          <Phone
            h="95%"
            w="60%"
            data={state?.page?.groups || []}
            loading={loading}
            onGroupClicked={group => {
              setPageGroupId(group?.id);
              setDisplayId(group?.element_display_id);
              onOpen();
            }}
          />
        </Flex>
      </DragDropContext>
      <DisplayModal
        isOpen={isOpen}
        displayId={displayId}
        pageGroupId={pageGroupId}
        pageSlug={page_slug}
        newPageGroupDTO={newPageGroupDTO}
        onPageGroupCreated={data => {
          setPageGroupId(data?.data?.createPageGroup?.id);
        }}
        onClose={() => {
          setNewPageGroupDTO(undefined);
          setDisplayId(undefined);
          setPageGroupId(undefined);
          onClose();
        }}
      />
    </Flex>
  );
};

export default Main;
