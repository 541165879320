import React, { useContext, useEffect, useState } from 'react';
import {
  ModalFooter,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import Input from '../../Form/Input';
import {
  CREATE_GROUP_ELEMENT,
  UPDATE_GROUP_ELEMENT,
} from '../../../graphQL/mutations';
import { GET_PAGE_DATA } from '../../../graphQL/queries';
import context from '../context';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: (item: any) => void;
  onEdited?: (item: any) => void;
  onDelete?: () => void;
  Item?: null | any;
}

interface FormData {
  name: string;
}

const createFormData = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});

const ItemForm: React.FC<Props> = ({
  onClose,
  isOpen,
  Item,
  onCreated,
  onEdited,
}) => {
  const { formState, handleSubmit, register, setValue, reset } = useForm({
    resolver: yupResolver(createFormData),
  });

  const [createGroupElement] = useMutation(CREATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const [updateGroupElement] = useMutation(UPDATE_GROUP_ELEMENT, {
    refetchQueries: [GET_PAGE_DATA, 'GetPageData'],
  });

  const { pageGroup, element } = useContext(context);

  const toast = useToast();
  const { errors } = formState;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!Item) return;
    setValue('name', Item.name);
  }, [Item, setValue]);

  const handleForm: SubmitHandler<FormData> = async data => {
    try {
      const { ...rest } = data;
      setLoading(true);
      if (!Item) {
        const groupNewElement = await createGroupElement({
          variables: {
            createGroupElementInput: {
              ...rest,
              name: data?.name,
              page_group_id: pageGroup?.id,
              element_id: element?.id,
            },
          },
        });

        if (onCreated) onCreated(groupNewElement?.data?.createGroupElement);
        toast({
          title: 'Sucesso',
          description: 'Tópico criado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      } else {
        const groupNewElement = await updateGroupElement({
          variables: {
            updateGroupElementInput: {
              ...rest,
              id: Item?.id,
              name: data?.name,
              page_group_id: pageGroup?.id,
              element_id: element?.id,
            },
          },
        });
        if (onEdited) onEdited(groupNewElement?.data?.updateGroupElement);
        toast({
          title: 'Sucesso',
          description: 'Tópico editado com sucesso',
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (err: any) {
      toast({
        title: 'Opss!!',
        description:
          err?.response?.data?.message ||
          'Tivemos um erro, tente novamente mais tarde',
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setLoading(false);
      reset();
      onClose();
    }
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        reset();
      }}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent maxH="90vh" as="form" onSubmit={handleSubmit(handleForm)}>
        <ModalHeader>Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8}>
            <SimpleGrid minChildWidth="200px" spacing="8" w="100%">
              <Input
                isRequired
                label="Texto"
                error={errors.name}
                {...register('name')}
              />
            </SimpleGrid>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button onClick={onClose}>Fechar</Button>
            <Button colorScheme="brand" isLoading={loading} type="submit">
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ItemForm;
