import React from 'react';
import {
  Box,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
} from '@chakra-ui/react';

interface Props {
  data: any;
  phoneWidth: number;
}

const Colapse: React.FC<Props> = ({ data }) => {
  return (
    <Box
      _hover={{ bg: 'gray.300' }}
      backgroundColor="gray.100"
      transition="150ms ease"
      position="relative"
      p={1}
      margin="5px 0 5px 0"
    >
      <Accordion textAlign="center" defaultIndex={[0]}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" fontSize="md" textAlign="left">
                {data.name}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} textAlign="left">
            {data.description}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default Colapse;
