import React, { useContext, useState } from 'react';
import { useDisclosure, Grid, GridItem } from '@chakra-ui/react';
import BannerForm from './BannerForm';
import Banner from './Banner';
import context from '../context';

const Banners: React.FC = () => {
  const { pageGroup } = useContext(context);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [clickedBanner, setClickedBanner] = useState<any | undefined>(
    undefined,
  );

  return (
    <>
      <Grid
        h="350px"
        templateRows="repeat(2, 100px)"
        templateColumns="repeat(9, 100px)"
        justifyContent="center"
        mt={5}
        gap={1}
      >
        <GridItem colStart={1}>
          <Banner
            banner={pageGroup?.items[0]}
            index={0}
            disabled={false}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={2} rowStart={1}>
          <Banner
            banner={pageGroup?.items[1]}
            index={1}
            disabled={pageGroup?.items?.length < 1}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={1} rowStart={2}>
          <Banner
            banner={pageGroup?.items[2]}
            index={2}
            disabled={pageGroup?.items?.length < 2}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
        <GridItem colStart={2} rowStart={2}>
          <Banner
            banner={pageGroup?.items[3]}
            index={3}
            disabled={pageGroup?.items?.length < 3}
            onClick={banner => {
              onOpen();
              setClickedBanner(banner);
            }}
          />
        </GridItem>
      </Grid>

      <BannerForm
        isOpen={isOpen}
        banner={clickedBanner}
        onCreated={onClose}
        onClose={() => {
          setClickedBanner(undefined);
          onClose();
        }}
        onDeleted={() => {
          setClickedBanner(undefined);
          onClose();
        }}
        onUpdated={() => {
          setClickedBanner(undefined);
          onClose();
        }}
      />
    </>
  );
};

export default Banners;
