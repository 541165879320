import { FC } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import Main from './pages/Main';

const Screen: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:page_slug`} component={Main} />
    </Switch>
  );
};

export default Screen;
